import classNames from 'classnames';

import { useAuthContext } from 'context/useAuthContext';

interface UserCircleProps {
  closedNavigationMenu: boolean;
}

function UserCircle({ closedNavigationMenu }: UserCircleProps): JSX.Element {
  const { user } = useAuthContext();
  return (
    <div className={classNames('user-container', { 'user-container-closed': closedNavigationMenu, v3: true })}>
      <div className="user-circle">{user && user.email?.substring(0, 2)}</div>
    </div>
  );
}

interface UserDropdownMenuProps {
  closedNavigationMenu: boolean;
}

export default function UserDropdownMenu({ closedNavigationMenu }: UserDropdownMenuProps): JSX.Element {
  return <UserCircle closedNavigationMenu={closedNavigationMenu} />;
}
