import React, { useMemo } from 'react';
import KSDropdown from '../KSDropdown/KSDropdown';
import { Option } from '../../../types';
import { formatRangeValues } from '../../../lib/reportUtils';
import { getCustomPeriodLabel } from '../../../lib/utils';
import KSButton from '../KSButton';
import { IconRange } from '../../../components/Icons';
import classNames from 'classnames';
import {
  ComparisonType,
  ComparisonTypes,
  ReportConfigurationData,
  ReportTimeframe,
  ReportTimeframes,
} from '@hone-automation/common';
import { useFormContext } from 'react-hook-form';
import KSInputNumberAutoincrease from '../KSInputNumberAutoincrease';
import { filterCompareOptions } from './utils';

export interface ReportPLDropdownReportsProps {
  optionsAvailable: Option[];
  currentTemplateType?: HoneReportType;
  handleComparisonChange: (comparisonValue: number | undefined) => void;
  reportConfigurationData?: ReportConfigurationData;
}

function ReportPlDropdownReports(props: ReportPLDropdownReportsProps) {
  const { optionsAvailable, currentTemplateType } = props;
  const { watch, reset } = useFormContext();

  const isCompareEnabled = watch('utility.isCompareEnabled');
  const selectedTimeFrame = watch('timeframe');

  const handlePeriodChange = (periodOption: Option) => {
    const startDateString = periodOption.value.split(' - ')[0];
    const endDateString = periodOption.value.split(' - ')[1];
    reset({
      ...watch(),
      startDate: startDateString,
      endDate: endDateString,
    });
  };

  const handleCompareEnabled = () => {
    reset({
      ...watch(),
      budgets: {
        include: false,
      },
      difference: false,
      breakdownPeriods: false,
      comparison: {
        comparisonType:
          currentTemplateType === 'Income Statement' || selectedTimeFrame === 'Year'
            ? 'Year Over Year'
            : 'Prior Period',
        comparisonPeriods: 1,
      },
      utility: {
        ...watch('utility'),
        isCompareEnabled: true,
      },
    });
  };

  const reportTimeframeOptionsComparePriorFiltered = useMemo(() => {
    const localTimeframe = watch('timeframe') as ReportTimeframe;
    const options = filterCompareOptions(localTimeframe);
    // Change the label of "Months" to "Periods" for period reporting locations
    return options && props.reportConfigurationData?.periodReporting
      ? options.map(ro => (ro.label === 'Months' ? { value: ro.value, label: 'Periods' } : ro))
      : options;
  }, [watch('timeframe')]);

  const reportTimeframeCompareValueSelected = useMemo(() => {
    const found = reportTimeframeOptionsComparePriorFiltered?.find(
      rp => rp.value === watch('comparison.comparisonType')
    );
    if (found) {
      return found;
    }
    const value = watch('comparison.comparisonType');
    const isPriorPeriod = value === ComparisonTypes.Prior_Period;
    const label = isPriorPeriod ? `${watch('timeframe')}s` : `${ReportTimeframes.Year}s`;
    return { value, label };
  }, [watch('comparison.comparisonType'), watch('timeframe')]);

  return (
    <div className="modal-time-frame-content">
      {!isCompareEnabled ? (
        <div className="modal-time-frame-content__compare">
          {optionsAvailable /*&& selectedTimeFrame !== 'Year to Date' */ && (
            <KSDropdown
              options={optionsAvailable as Option[]}
              value={{
                value: `${watch('startDate')} - ${watch('endDate')}`,
                label: `${formatRangeValues(`${watch('startDate')} - ${watch('endDate')}`, selectedTimeFrame)}`,
                customLabel: `${getCustomPeriodLabel(optionsAvailable as Option[], `${watch('startDate')} - ${watch('endDate')}`)}`,
              }}
              label="Range"
              period={selectedTimeFrame}
              onSelect={handlePeriodChange}
            />
          )}
          <div className="modal-time-frame-content__compare_btn">
            <KSButton
              label="Compare"
              color="secondary"
              size="medium"
              variant="contained"
              type="button"
              onClick={handleCompareEnabled}
            />
          </div>
        </div>
      ) : (
        <>
          {optionsAvailable && optionsAvailable.length && (
            <KSDropdown
              options={optionsAvailable as Option[]}
              value={{
                value: `${watch('startDate')} - ${watch('endDate')}`,
                label: `${formatRangeValues(`${watch('startDate')} - ${watch('endDate')}`, selectedTimeFrame)}`,
                customLabel: `${getCustomPeriodLabel(optionsAvailable as Option[], `${watch('startDate')} - ${watch('endDate')}`)}`,
              }}
              label="Range"
              period={selectedTimeFrame}
              onSelect={handlePeriodChange}
            />
          )}
          <div className="modal-time-frame-content-icon-container">
            <IconRange />
          </div>

          <KSInputNumberAutoincrease
            onChangeInput={() => {
              // TODO implement on change input
            }}
            value={watch('comparison.comparisonPeriods')}
            onDecrease={() => {
              const comparisonPeriods = watch('comparison.comparisonPeriods');
              props.handleComparisonChange(comparisonPeriods! - 1);
            }}
            onIncrease={() => {
              const comparisonPeriods = watch('comparison.comparisonPeriods') || 1;
              props.handleComparisonChange(comparisonPeriods + 1);
            }}
            disabledDecrease={watch('comparison.comparisonPeriods') === 0}
            disabledIncrease={watch('comparison.comparisonPeriods') === 13}
            label="Compare to Prior"
          />
          <div
            className={classNames('modal-time-frame-content__compare-dropdown', {
              'one-line': !reportTimeframeOptionsComparePriorFiltered,
            })}
          >
            {reportTimeframeOptionsComparePriorFiltered ? (
              <KSDropdown
                options={reportTimeframeOptionsComparePriorFiltered}
                value={reportTimeframeCompareValueSelected!}
                theme="grey"
                onSelect={option => {
                  reset({
                    ...watch(),
                    comparison: {
                      ...watch('comparison'),
                      comparisonType: option.value as ComparisonType,
                    },
                  });
                }}
                placeholder="Monthly"
              />
            ) : (
              <span className="ks-input-number-unit">YEARS</span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ReportPlDropdownReports;
