import classNames from 'classnames';
import './RBSearch.scss';
import { prefixClass } from 'lib/utils';
import { IconSearch } from 'components/Icons';
import { useRef } from 'react';

type RBSearchProps = {
  searchValue: string;
  onSearch: (value: string) => void;
  onClear: () => void;
};

const RBSearch = ({ searchValue, onSearch, onClear }: RBSearchProps) => {
  const prefix = prefixClass('rb-search');
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch?.(event.target.value);
  };
  const handleClear = () => {
    onClear?.();
    if (inputRef.current) inputRef.current.focus();
  };
  return (
    <div className={classNames(prefix(), { active: searchValue })}>
      <div className={prefix('icon')}>
        <IconSearch />
      </div>
      <input type="text" placeholder="Search" value={searchValue} onChange={handleSearch} autoFocus ref={inputRef} />
      {searchValue && (
        <div className={prefix('clear-button')} onClick={handleClear}>
          Clear
        </div>
      )}
    </div>
  );
};
export default RBSearch;
