import React, { useMemo } from 'react';
import KSCheckBox from '../../../KSCheckBox';
import { useFormContext } from 'react-hook-form';

interface ShowBreakdownRangeProps {
  isAggrSideBySide: boolean;
}

function ShowBreakdownRange({ isAggrSideBySide }: ShowBreakdownRangeProps) {
  const { watch, reset } = useFormContext();

  const timeframe = watch('timeframe');
  const isCustomDateRange = watch('utility.isCustomDateRange');
  const isCompareEnabled = watch('utility.isCompareEnabled');

  const shouldDisabledBreakdownPeriods = useMemo(() => {
    if (isAggrSideBySide) {
      return true;
    }
    const isMonthlyOrWeekly = timeframe === 'Month' || timeframe === 'Week';
    if (watch('difference')) {
      return true;
    }
    if (isCustomDateRange) {
      return false;
    }

    if (isCompareEnabled) {
      return true;
    }

    if (isMonthlyOrWeekly) {
      return true;
    }

    return false;
  }, [timeframe, isCustomDateRange, isCompareEnabled, watch('difference')]);

  return (
    <KSCheckBox
      disabled={shouldDisabledBreakdownPeriods}
      label={'Breakdown Range'}
      checked={Boolean(watch('breakdownPeriods'))}
      onChange={() => {
        reset({
          ...watch(),
          breakdownPeriods: !watch('breakdownPeriods'),
          reverse: false,
          total: false,
        });
      }}
    />
  );
}

export default ShowBreakdownRange;
