import { useRef, useState, useMemo, useEffect, useCallback, memo } from 'react';
import { useNTContext, filters } from './NTContext';
import { useNTUtilsContext, actions } from './NTUtilsContext';
import { useNTTableContext } from './NTTableContext';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useReportsStore } from 'hooks/useReportsStore';
import { shallow } from 'zustand/shallow';
import { useFlaggedTransactionsQuery } from 'hooks/flaggedTransactions';
import { NewTableProps } from './types';
import { useModalState } from './hooks/useModalState';
import { useColumnWidths } from './hooks/useColumnWidths';
import parseReportNT from '../KSExport/reportNTParser';
import { readURLParams, saveURLParams } from './utils/urlParams';
import { NTMainLayout } from './NTMainLayout';
import { useNTScroll } from './NTScrollContext';
import { useNTSelectionContext } from './NTSelectionContext';
import { getReportCapabilities } from '../KSExport/reportNTParser/capabilities';
import NTAttachmentManager from './NTAttachments';

const MemoizedNewTableContent = memo(
  ({
    report,
    reportId,
    fixFirstColumn = false,
    fixLastColumn = false,
    loading = false,
    redraw = false,
    reportType,
  }: NewTableProps) => {
    // Context hooks first
    const { filters, setFilters, budgetAvailable = false, setBudgetAvailable, setReportId } = useNTContext();
    const { actions, setActions, capabilities, setCapabilities } = useNTUtilsContext();
    const { transformedData, setTransformedData, cellStates, setCellStates } = useNTSelectionContext();
    const { setTableWidth } = useNTTableContext();

    // Calculate column widths with stable dependencies
    useColumnWidths(transformedData, filters, capabilities, budgetAvailable || false);

    // Refs
    const parentRef = useRef<HTMLDivElement>(null);
    const trace = false;

    // State hooks
    const [searchTerm, setSearchTerm] = useState<string>('');

    // Data fetching hooks
    const currentLocation = useLocationsStore(state => state.currentLocation);
    const reportsConfiguration = useReportsStore(state => state.reportsConfiguration, shallow);
    const { data: flaggedTransactions } = useFlaggedTransactionsQuery();

    // Memoized callbacks
    const handleSearch = useCallback((value: string) => {
      setSearchTerm(value);
    }, []);
    const handleClear = useCallback(() => setSearchTerm(''), []);

    // States
    const [envReady, setEnvReady] = useState(false);
    const [ready, setReady] = useState(false);

    const mustCleanData = useRef(true);

    useEffect(() => {
      setReady(false);
      setEnvReady(false);
      setTransformedData(null);
    }, []);

    // First Step - Fetching data
    useEffect(() => {
      if (loading) {
        if (ready) setReady(false);
        setEnvReady(false);
        setTransformedData(null);
      }
    }, [loading]);

    // Second Step - Parsing data
    useEffect(() => {
      if (!report) return;
      if (ready) setReady(false);
      if (envReady) setEnvReady(false);
    }, [report?.createdAt, report?.type, report?.dates.length, report?.dates, report?.title]);

    // Updating the reportId if we get it
    useEffect(() => {
      if (report && reportId) {
        setReportId(reportId);
      }
    }, [report, reportId]);

    // Instead of directly calling setTransformedData with useMemo
    const transformedDataValue = useMemo(() => {
      //console.log('transformedDataValue before parsing ->', flaggedTransactions || 'undefined');
      return report && envReady
        ? parseReportNT({
            report,
            reportId,
            useComputedColumns: true,
            periodReporting: reportsConfiguration?.data?.periodReporting,
            reportCFG: reportsConfiguration,
            flaggedTransactions,
            currentLocation,
            budget: reportType === 'budget',
            trace,
          })
        : null;
    }, [
      report,
      reportId,
      report?.type,
      report?.createdAt,
      reportsConfiguration?.data.periodReporting,
      flaggedTransactions,
      currentLocation,
      trace,
      envReady,
    ]);

    // Use useEffect to set the transformed data
    // Set Ready to true when the transformed data is set
    useEffect(() => {
      if (!transformedDataValue || !envReady) return;
      setTransformedData(transformedDataValue);
      setReady(true);
    }, [transformedDataValue, setTransformedData, envReady]);

    // Setting the default rowId (selected) if we don't get one
    useEffect(() => {
      if (!transformedData || !transformedData.columns) return;
      if (!cellStates.selected && transformedData.columns[0]?.data) {
        setCellStates({ ...cellStates, selected: transformedData.columns[0].data[1]?.id });
      }
    }, [transformedData, cellStates.selected]);

    // Read URL params as well as capabilities to set the environment
    useEffect(() => {
      const { filter, actions: urlActions, selection, urlBudgetInclude } = readURLParams();
      if (!report || !report.type) return;
      const initCapabilities = getReportCapabilities(reportType === 'budget' ? 'Budget' : report!.type);
      setCapabilities(initCapabilities);
      setBudgetAvailable(urlBudgetInclude);
      setFilters({ ...filters, ...filter });
      setActions({
        ...actions,
        total: urlActions.total,
        difference: urlActions.difference,
        reverseColumns: urlActions.reverseColumns,
        detailsPanel: urlActions.detailsPanel,
      });
      setCellStates({ ...cellStates, selected: selection.rowID });
      const mustOpenDetailsPanel = urlActions.detailsPanel && initCapabilities.charts;
      setTableWidth(mustOpenDetailsPanel ? '60%' : '100%');
      //console.log('Setting URL', urlActions, filters, actions, urlBudgetInclude);
      setEnvReady(true);
    }, [report, report?.type]);

    // Sync URL params when filters or actions change
    useEffect(() => {
      if (!envReady) return;
      saveURLParams({ filters, actions, cellState: cellStates });
    }, [envReady, filters, actions, cellStates]);

    // Memoize the NTMainLayout component. It will be drawn when is ready
    const MemoizedNTMainLayout = useMemo(() => {
      if (!envReady) return null;
      return (
        <>
          <NTMainLayout
            parentRef={parentRef}
            transformedData={transformedData}
            loading={!ready}
            searchTerm={searchTerm}
            fixFirstColumn={fixFirstColumn}
            onSearch={handleSearch}
            onClear={handleClear}
          />
          <NTAttachmentManager />
        </>
      );
    }, [ready, envReady, report?.type, searchTerm, transformedData.flaggedCells]);

    // Early return with stable condition
    if (!report || !transformedData || !transformedData.columns.length) {
      if (!report) {
        if (!mustCleanData.current) return;
        mustCleanData.current = false;
        return <div>Requesting Report ...</div>;
      }
    }

    // Then in the return statement:
    return <>{MemoizedNTMainLayout}</>;
  },
  (prevProps, nextProps) => {
    // Only re-render if these props change
    return (
      prevProps.report === nextProps.report &&
      prevProps.report?.createdAt === nextProps.report?.createdAt &&
      prevProps.loading === nextProps.loading &&
      prevProps.fixFirstColumn === nextProps.fixFirstColumn &&
      prevProps.fixLastColumn === nextProps.fixLastColumn &&
      prevProps.redraw === nextProps.redraw
    );
  }
);

export const NewTableContent: React.FC<NewTableProps> = props => {
  // Get scroll context here but don't pass it down
  const { scrollState, scrollChange } = useNTScroll();
  // TODO: Initialize the ScrollState here
  return <MemoizedNewTableContent {...props} />;
};
