import { useCallback } from 'react';
import find from 'lodash/find';
import { shallow } from 'zustand/shallow';

import { IconAddCircle, IconLocation } from 'components/Icons';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useNavigate } from 'react-router-dom';
import { useReportsStore } from 'hooks/useReportsStore';
import Autocomplete from 'presentation/components/Autocomplete/Autocomplete';
import { useJournalEntriesStore } from 'hooks/journal-entries/useJournalEntriesStore';

interface Props {
  canAddNewLocation?: boolean;
}

function LocationsAutocomplete({ canAddNewLocation = false }: Props) {
  const { allLocations, currentLocationId, status, setCurrentLocationId } = useLocationsStore(
    state => ({
      allLocations: state.allLocations,
      currentLocationId: state.currentLocationId,
      status: state.status,
      setCurrentLocationId: state.setCurrentLocationId,
    }),
    shallow
  );

  const loadCurrentLocationId = useCallback(
    (updateValue: any, setInitialValue: any) => {
      if (currentLocationId && allLocations) {
        find(allLocations, function (obj) {
          if (obj.id === currentLocationId) {
            setInitialValue(obj.name);
          }
        });
      } else {
        updateValue('');
      }
    },
    [currentLocationId, allLocations]
  );

  const navigateTo = useNavigate();

  const handleAddNewLocation = () => {
    setCurrentLocationId('');
  };

  const isLoading = status === 'loading';

  return (
    <Autocomplete
      options={allLocations}
      loading={isLoading}
      searchProp="name"
      iconElement={<IconLocation />}
      loadDefaultValue={loadCurrentLocationId}
      onItemClick={selectedElement => {
        setCurrentLocationId(selectedElement.id);
        useReportsStore.setState({ allReports: [] });
        useJournalEntriesStore.setState({
          journalEntriesEditable: undefined,
          journalEntriesPayload: undefined,
        });
        navigateTo(`/app/location/${selectedElement.id}`);
      }}
    />
  );
}

export default LocationsAutocomplete;
