import AvatarContainer from '../common/AvatarContainer';
import RIHeader from './RIHeader';
import RIInfo from './RIInfo';
import './RequestItem.scss';
import { prefixClass } from 'lib/utils';
import { parseAR } from './utils/parseAR';
import { AskRequest } from '@hone-automation/common';
import { RequestItemProps } from './type';
import classNames from 'classnames';
import { IconChevron } from 'components/Icons';
import { useQueueContext } from '../QueueContext';
import { useEffect, useState } from 'react';

export type RequestItemParams = {
  read: boolean;
  multiLocation: boolean;
  selected?: boolean;
  colorId?: string;
  request: AskRequest;
  me: boolean;
  onClick?: (id: string) => void;
};

const RequestItem = (props: RequestItemParams) => {
  const { read = false, multiLocation = false, colorId, selected = false, request, me = false } = props;
  // Exit before any hook and rendering if request is not defined
  if (!request) return null;

  const prefix = prefixClass('request-item');
  const requestItem: RequestItemProps = parseAR(request);
  const { id, userInfo, header, location, info, lastComment, warnning } = requestItem;
  const {
    state: { selectedItem },
  } = useQueueContext();
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    setIsSelected(selectedItem?.id === request.id);
  }, [selectedItem]);

  const StatusIndicator = () => {
    return (
      <div className={prefix('status-avatar-container-activity')}>
        {!read && (
          <div className={classNames(prefix('status-avatar-container-activity-status'), { warning: warnning })}></div>
        )}
      </div>
    );
  };

  const LastComment = () => {
    if (!lastComment) return null;
    return <div className={prefix('last-comment')}>{lastComment.comment}</div>;
  };

  const handleClick = () => {
    if (props.onClick) props.onClick(id);
  };

  if (!userInfo || !header) return null;
  return (
    <div className={classNames(prefix(), { 'request-selected': isSelected })} onClick={handleClick}>
      <div className={prefix('status-avatar-container')}>
        <StatusIndicator />
        <AvatarContainer userInfo={{ ...userInfo, colorId: colorId || userInfo.colorId }} tooltip={true} me={me} />
      </div>
      <div className={classNames(prefix('body-container'), { 'no-last-comment': !lastComment })}>
        <RIHeader
          userName={header.userName}
          date={header.date}
          locationName={location.name}
          locationId={location.id}
          isMultiLocation={multiLocation}
          requestType={header.requestType}
        />
        <RIInfo {...info} />
        <LastComment />
      </div>
      <div className={prefix('selected-arrow-container')}>
        <div className={prefix('selected-arrow-container-icon')}>
          <IconChevron />
        </div>
      </div>
    </div>
  );
};

export default RequestItem;
