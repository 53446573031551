import KSButton from 'presentation/components/KSButton';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

function ApplyButton() {
  const { watch } = useFormContext();

  const shouldDisabledApplyButton = useMemo(() => {
    const { startDate, endDate, timeframe } = watch();
    if (timeframe === 'Year to Date') {
      return false;
    }
    if (startDate && endDate) {
      return false;
    }
    return true;
  }, [watch('startDate'), watch('endDate')]);

  return (
    <KSButton
      label="Apply"
      color="primary"
      size="medium"
      variant="contained"
      type="submit"
      disabled={shouldDisabledApplyButton}
    />
  );
}

export default ApplyButton;
