import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import './Queue.scss';
import { QueueContextProvider } from './QueueContext';
import BrowserColumn from './BrowserColumn';
import QueueDetails from './QueueDetails/QueueDetails';
import { useAuthContext } from '../../../context/useAuthContext';
import { useAskRequestsQuery } from '../../../hooks/useAskRequestsQuery';

const Queue = () => {
  const { user } = useAuthContext();
  const meId = user?.uid;
  const { data: requests } = useAskRequestsQuery();
  const prefix = prefixClass('queue');

  if (!requests) {
    return null;
  }

  return (
    <QueueContextProvider meId={meId} requests={requests}>
      <div className={classNames(prefix())}>
        <div className={prefix('browser')}>
          <BrowserColumn />
        </div>
        <QueueDetails />
      </div>
    </QueueContextProvider>
  );
};

export default Queue;
