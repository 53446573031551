import React, { useEffect } from 'react';
import useAttachmentsModal from 'hooks/useAttachmentsModal';
import DataRowModal from 'components/Table/DataRowModal';
import AttachmentModal from 'components/Attachments/AttachmentModal';
import { IconForwardBtn } from 'components/Icons';
import { renderDate } from 'lib';
import { useActiveReportStore } from 'hooks/useActiveReportStore';
import { HoneReportTypes } from '../../../constants';
import { shallow } from 'zustand/shallow';
import { useModalState } from './hooks/useModalState';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useReportsStore } from 'hooks/useReportsStore';

const NTAttachmentManager = () => {
  const currentLocationId = useLocationsStore(state => state.currentLocationId);
  const selectedReport = useReportsStore(state => state.selectedReport);
  const { showAttachmentImage, attachmentsUrl, attachmentAmount, handleCloseAttachmentImage, getAttachmentsUrl } =
    useAttachmentsModal();

  const { modalState, handleClose, showModal } = useModalState();

  const { modalCellState } = useActiveReportStore(
    state => ({
      modalCellState: state.modalCellState,
    }),
    shallow
  );
  /* console.log('NTAttachmentManager', modalState, modalCellState); */
  return (
    <>
      {showModal && (
        <>
          <DataRowModal
            modalState={modalState}
            handleClose={handleClose}
            showAttachment={(attachments, amount) => getAttachmentsUrl(currentLocationId, attachments, amount)}
          />
          <AttachmentModal
            showAttachmentImage={showAttachmentImage}
            handleClose={handleCloseAttachmentImage}
            attachmentAmount={attachmentAmount}
            attachmentsUrl={attachmentsUrl}
            title={`${selectedReport?.title} | ${
              selectedReport &&
              `${renderDate(selectedReport?.startDateText)} - ${renderDate(selectedReport?.endDateText)}`
            }`}
            breadcrumb={
              <ol className="breadcrumb">
                {modalCellState.parent && (
                  <>
                    <li>{modalCellState.parent}</li>
                    <li className="breadcrumb-separator">
                      <IconForwardBtn />
                    </li>
                  </>
                )}
                <li>{modalCellState.title}</li>
                {/* YTD */}
                {modalCellState?.date && modalCellState?.date.end === '' ? (
                  <>
                    <li className="breadcrumb-separator">
                      <IconForwardBtn />
                    </li>
                    <li>
                      {modalCellState?.date.start}{' '}
                      {selectedReport &&
                        selectedReport.type !== HoneReportTypes.ApAging &&
                        renderDate(selectedReport?.startDateText)}
                    </li>
                  </>
                ) : (
                  <>
                    <li className="breadcrumb-separator">
                      <IconForwardBtn />
                    </li>
                    <li>{modalCellState?.date && renderDate(modalCellState?.date.end)}</li>
                  </>
                )}
              </ol>
            }
          />
        </>
      )}
    </>
  );
};

export default NTAttachmentManager;
