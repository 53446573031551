import React, { ChangeEvent, useEffect, useRef, useState, MouseEvent, KeyboardEvent } from 'react';
import './CMComposer.scss';
import { IconAttachment, IconDown, IconEmoji } from 'components/Icons';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import {
  useCreateFlagTransactionCommentMutation,
  useReopenFlaggedTransactionMutation,
} from '../../../../hooks/flaggedTransactions';
import { useParams } from 'react-router-dom';

import { AskRequest } from '@hone-automation/common';
import { useAuthContext } from '../../../../context/useAuthContext';
import { useQueueContext } from '../QueueContext';

interface MessageComposerProps {
  request: AskRequest;
}

const MessageComposer = ({ request }: MessageComposerProps) => {
  const { locationId } = useParams();

  const {
    dispatch,
    state: { selectedItem },
  } = useQueueContext();

  const { user } = useAuthContext();
  const createCommentMutation = useCreateFlagTransactionCommentMutation(request.status, locationId!, trx => {
    dispatch({ type: 'SET_SELECTED_ITEM', payload: trx as unknown as AskRequest });
  });

  const postReopenMutation = useReopenFlaggedTransactionMutation(request.status, locationId!, response => {
    //
  });

  const [toolbarOpen, setToolbar] = useState<boolean>(false);
  const [emojiPickerOpen, setEmojiPicker] = useState<boolean>(false);
  const [text, setText] = useState<string>('');

  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    if (!textAreaRef.current) return;
    const ref = textAreaRef.current;
    const start = text.substring(0, ref.selectionStart);
    const end = text.substring(ref.selectionEnd);

    // Insert the selected emoji at the current cursor position
    const updatedText = start + emojiData.emoji + end;
    setText(updatedText);
    // Calculate the new cursor position (after the inserted emoji)
    const newCursorPosition = start.length + emojiData.emoji.length;
    setCursorPosition(newCursorPosition);
  };

  useEffect(() => {
    if (cursorPosition !== null && textAreaRef.current) {
      textAreaRef.current.selectionStart = cursorPosition;
      textAreaRef.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition]);

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleTextAreaClick = (e: MouseEvent<HTMLTextAreaElement>) => {
    setCursorPosition((e.target as HTMLTextAreaElement).selectionStart);
  };

  const handleTextAreaKeyUp = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    setCursorPosition((e.target as HTMLTextAreaElement).selectionStart);
  };

  const toggleEmojiPick = () => {
    setEmojiPicker(prevState => {
      return !prevState;
    });
  };

  useClickAway(emojiPickerRef, () => {
    setEmojiPicker(false);
  });

  const handleSubmit = () => {
    if (text == '') return;
    if (selectedItem?.status === 'Closed' && selectedItem.canReopen) {
      postReopenMutation.mutate({
        trxId: selectedItem?.id,
        comment: text,
      });
    }
    createCommentMutation.mutate({ comment: text, userId: user?.uid, locationId, activeTransaction: request });
    setText('');
  };

  const handleTextAreaKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && text === undefined) {
      e.preventDefault();
      return;
    }
    if (e.key === 'Enter' && !e.shiftKey && text !== undefined) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const hideActions = true;

  return (
    <div className="message-composer">
      {toolbarOpen && (
        <div className="toolbar">
          <div className="styles">
            <button className="toolbar-button bold">B</button>
            <button className="toolbar-button italic">I</button>
            <button className="toolbar-button strike">S</button>
          </div>
          <button className="toolbar-button code">GL CODE</button>
        </div>
      )}
      <div className="message-row">
        <textarea
          className="message-input"
          ref={textAreaRef}
          value={text}
          autoFocus
          onChange={handleTextAreaChange}
          onClick={handleTextAreaClick}
          onKeyUp={handleTextAreaKeyUp}
          onKeyDown={handleTextAreaKeyDown}
          placeholder="Write your message ..."
        />
      </div>
      <div className="message-actions">
        <div className="left-actions">
          {!hideActions && (
            <>
              <button
                className={classNames('action-button', { active: toolbarOpen })}
                onClick={() => setToolbar(prevValue => !prevValue)}
              >
                A<span>a</span>
              </button>
              <button className="action-button">
                <IconAttachment />
              </button>
              <button onClick={toggleEmojiPick} className={classNames('action-button', { active: emojiPickerOpen })}>
                <IconEmoji />
              </button>
              <div ref={emojiPickerRef} className="emoji-wrapper">
                {/* @ts-ignore: Types from package are wrong */}
                <EmojiPicker open={emojiPickerOpen} onEmojiClick={handleEmojiClick} />
              </div>
            </>
          )}
        </div>
        <button className="send-button" onClick={handleSubmit}>
          SEND
          {/*<span className="separator" />*/}
          {/*<span className="arrow-down">*/}
          {/*  <IconDown />*/}
          {/*</span>*/}
        </button>
      </div>
    </div>
  );
};

export default MessageComposer;
