import { useParams } from 'react-router-dom';
import { makeRemoteTemplateRanges } from '../../main/factories/usecases/reports/remote-get-templates-ranges';

import { useQuery } from '@tanstack/react-query';

const templatesRangesService = makeRemoteTemplateRanges();

export const useTemplatesRangesQuery = () => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ['templatesRanges', locationId],
    queryFn: async () => {
      if (!locationId) return;
      return (await templatesRangesService.get({ locationId: locationId })) as TemplateRange[];
    },
  });
};
