import React, { useMemo } from 'react';
import KSCheckBox from '../../../KSCheckBox';
import { useFormContext } from 'react-hook-form';

interface ShowTotalProps {
  isAggrSideBySide: boolean;
}

function ShowTotal({ isAggrSideBySide }: ShowTotalProps) {
  const { watch, reset } = useFormContext();

  const shouldDisabledTotal = useMemo(() => {
    if (isAggrSideBySide) {
      return true;
    }
    if (watch('budgets.include') && watch('breakdownPeriods')) {
      return true;
    }
    if (watch('difference') || !watch('breakdownPeriods')) {
      return true;
    }

    if (watch('breakdownPeriods')) {
      return false;
    }
  }, [watch('difference'), watch('breakdownPeriods'), watch('budgets.include')]);

  return (
    <KSCheckBox
      disabled={shouldDisabledTotal}
      label={'Show Total'}
      checked={Boolean(watch('total'))}
      onChange={() => {
        reset({
          ...watch(),
          total: !watch('total'),
        });
      }}
    />
  );
}

export default ShowTotal;
