import { AskRequest } from '@hone-automation/common/lib/AskRequests';
import React, { createContext, useContext, useReducer } from 'react';

// Define the initial state as a function that accepts props and returns the proper type
const initialState = (props: { meId?: string; requests?: AskRequest[] }) => ({
  requests: props.requests || ([] as AskRequest[]),
  selectedItem: props.requests?.[0] as AskRequest | null,
  meId: props.meId || '',
});

// Define action types using a discriminated union
type SetRequestsAction = {
  type: 'SET_REQUESTS';
  payload: AskRequest[];
};

type SetSelectedItemAction = {
  type: 'SET_SELECTED_ITEM';
  payload: AskRequest | null;
};

type SetMeIdAction = {
  type: 'SET_ME_ID';
  payload: string;
};

type Action = SetRequestsAction | SetSelectedItemAction | SetMeIdAction;

// Reducer function to handle state transitions based on actions
const queueReducer = (state: ReturnType<typeof initialState>, action: Action): ReturnType<typeof initialState> => {
  switch (action.type) {
    case 'SET_REQUESTS':
      return { ...state, requests: action.payload };
    case 'SET_SELECTED_ITEM':
      return { ...state, selectedItem: action.payload };
    case 'SET_ME_ID':
      return { ...state, meId: action.payload };
    default:
      return { ...state };
  }
};

// Create the context
const QueueContext = createContext<{
  state: ReturnType<typeof initialState>;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState({}),
  dispatch: () => {},
});

// Custom hook to use the context
export const useQueueContext = () => useContext(QueueContext);

// Provider component that wraps children and provides the context, accepting props for default values
export const QueueContextProvider = ({
  meId,
  requests,
  children,
}: {
  meId?: string;
  requests?: AskRequest[];
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(queueReducer, initialState({ meId, requests }));

  return <QueueContext.Provider value={{ state, dispatch }}>{children}</QueueContext.Provider>;
};
