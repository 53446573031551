import React from 'react';
import { IconCalendar } from '../../../components/Icons';

interface ReportPLDatesButtonProps {
  toggleModal: () => void;
  periodTitle: React.ReactNode;
  dateRangeTitle: React.ReactNode;
}

function ReportPlDatesButton({ toggleModal, periodTitle, dateRangeTitle }: ReportPLDatesButtonProps) {
  return (
    <button className="report-dates-control" onClick={toggleModal}>
      <div className="report-dates-control-period">{periodTitle}</div>
      <div className="report-dates-control-range">
        <IconCalendar /> {dateRangeTitle}
      </div>
    </button>
  );
}

export default ReportPlDatesButton;
