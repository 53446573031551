import React, { useMemo } from 'react';
import KSCheckBox from '../../../KSCheckBox';
import { useFormContext } from 'react-hook-form';

interface ShowBudgetProps {
  isAggrSideBySide: boolean;
}

function ShowBudget({ isAggrSideBySide }: ShowBudgetProps) {
  const { watch, reset } = useFormContext();

  const selectedTimeframe = watch('timeframe');

  const shouldDisabledBudgetInclude = useMemo(() => {
    const isWeekly = selectedTimeframe === 'Week';
    if (isWeekly) {
      return true;
    }
    if (isAggrSideBySide) {
      return false;
    }
    return watch('total');
  }, [selectedTimeframe, watch('total')]);

  return (
    <KSCheckBox
      disabled={shouldDisabledBudgetInclude}
      label={'Show Budget'}
      checked={watch('budgets.include')}
      onChange={() => {
        reset({
          ...watch(),
          budgets: {
            include: !watch('budgets.include'),
          },
          difference: false,
        });
      }}
    />
  );
}

export default ShowBudget;
