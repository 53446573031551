import './BrowserColumn.scss';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import RequestsBrowser from '../RequestsBrowser';
import { useEffect, useState } from 'react';
import LabelSwitch from '../common/LabelSwitch';
import { AskRequest } from '@hone-automation/common/lib/AskRequests';
import { useQueueContext } from '../QueueContext';
import { IconAdd } from 'components/Icons';

const BrowserColumn = () => {
  const prefix = prefixClass('browser-column');
  const [statusFilter, setStatusFilter] = useState<'Open' | 'In Progress' | 'Closed'>('Open');
  const {
    state: { meId, requests },
    dispatch,
  } = useQueueContext();

  const handleRequestClick = (requestId: string) => {
    const request = requests.find(r => r.id === requestId);
    if (request) {
      dispatch({ type: 'SET_SELECTED_ITEM', payload: request });
    }
  };

  return (
    <div className={prefix()}>
      <div className={classNames(prefix('filters'))}>
        <div className={prefix('filters-switch')}>
          <LabelSwitch
            labels={['Open', 'In Progress', 'Closed']}
            selected={statusFilter}
            onSelect={value => setStatusFilter(value as 'Open' | 'In Progress' | 'Closed')}
          />
        </div>
        <div className={prefix('filters-add-button')}>
          <IconAdd />
        </div>
      </div>
      <div className={classNames(prefix('request-browser'))}>
        <RequestsBrowser
          requests={requests}
          filter={{
            status: statusFilter,
            search: undefined,
            sort: undefined,
            groupBy: undefined,
          }}
          meId={meId}
          onClick={handleRequestClick}
        />
      </div>
    </div>
  );
};
export default BrowserColumn;
