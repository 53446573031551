import { useEffect } from 'react';
import { useReportsStore } from 'hooks/useReportsStore';

import { useNavigate } from 'react-router-dom';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useReportsQuery } from 'hooks/reports/useReportsQuery';

export const ReportViewFactory = () => {
  const navigateTo = useNavigate();
  const currentLocationId = useLocationsStore(state => state.currentLocationId);

  const { data: allReports } = useReportsQuery(reports => reports);

  useEffect(() => {
    if (allReports && allReports.length) {
      const report = allReports[0];
      useReportsStore.setState({ selectedReport: report });
      navigateTo(`/app/location/${currentLocationId}/report/${report.id}`);
    }
  }, [allReports]);

  return null;
};
