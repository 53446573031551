import { AskRequest } from '@hone-automation/common/lib/AskRequests';
import './AssignmentGroup.scss';
import { prefixClass } from 'lib/utils';
import RequestItem from '../RequestItem';
import RequestGroup from '../RequestGroup';
import { getGroupedDates, GroupData } from '../utils/dateUtils';
import { useQueueContext } from '../QueueContext';

const HEADER_STRINGS = {
  me: 'requests Assigned to me',
  team: 'team requests',
};

type AssignmentGroupProps = {
  me: boolean;
  requests: AskRequest[];
  reversed?: boolean;
  onClick?: (id: string) => void;
};

const sortRequests = (requests: AskRequest[], reversed: boolean) =>
  requests.sort((a, b) => {
    if (!reversed) {
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    } else {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    }
  });

const AssignmentGroup = ({ me, requests, reversed = false, onClick }: AssignmentGroupProps) => {
  const {
    state: { selectedItem },
  } = useQueueContext();
  const prefix = prefixClass('assignment-group');
  const groupDates = me ? sortRequests(requests, reversed) : getGroupedDates(requests, reversed);

  const handleClick = (id: string) => {
    if (onClick) onClick(id);
  };

  if (requests.length === 0) return null;

  return (
    <div className={prefix()}>
      <div className={prefix('group-header')}>
        <div className={prefix('group-header-label')}>{HEADER_STRINGS[me ? 'me' : 'team']}</div>
      </div>
      <div className={prefix('group-list')}>
        {me ? (
          <div className={prefix('group-list-me')}>
            {requests.map(request => (
              <RequestItem
                key={request.id}
                request={request}
                selected={request.id === selectedItem?.id}
                me={me}
                read={true}
                multiLocation={false}
                onClick={handleClick}
              />
            ))}
          </div>
        ) : (
          Object.keys(groupDates as { [k: string]: GroupData }).map((date, index) => (
            <RequestGroup
              key={date}
              collapsed={index !== 0}
              title={(groupDates as { [k: string]: GroupData })[date].label}
              requests={(groupDates as { [k: string]: GroupData })[date].requests}
              multiLocation={false}
              onRequestClick={handleClick}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default AssignmentGroup;
