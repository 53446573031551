import { AskRequest } from '@hone-automation/common/lib/AskRequests';

const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

// compare two dates and return true if they are the same day. Doesn't take into account time
const checkDate = (date: Date, compareToDate: Date): boolean => {
  // Check if dates are valid
  if (!isValidDate(date) || !isValidDate(compareToDate)) {
    return false;
  }
  return (
    date.getDate() === compareToDate.getDate() &&
    date.getMonth() === compareToDate.getMonth() &&
    date.getFullYear() === compareToDate.getFullYear()
  );
};

export const getDaysAgo = (date: string): string | null => {
  // Convert the date string to a Date object
  if (!date) return null;
  const dateObj = new Date(date);
  if (!isValidDate(dateObj)) return null;

  const today = new Date();
  const diff = today.getTime() - dateObj.getTime();
  const daysAgo = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (daysAgo === 0) {
    const hoursAgo = Math.floor(diff / (1000 * 60 * 60));
    if (hoursAgo < 1) {
      const minutesAgo = Math.floor(diff / (1000 * 60));
      return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
    }
    return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
  }
  return daysAgo + ' day' + (daysAgo > 1 ? 's' : '') + ' ago';
};

// Coverts the date to a string in the format "Month Day, Year" or shortcuts like Today and Yesterday
export const evalDate = (date: string): string | null => {
  // Convert the date string to a Date object
  if (!date) return null;
  const dateObj = new Date(date);
  if (!isValidDate(dateObj)) return null;

  // Check if date is Today's date
  const currentDate = new Date();
  const dateIsToday = checkDate(dateObj, currentDate);
  if (dateIsToday) {
    return `Today, ${dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }

  // Check if the date was yesterday
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  const dateWasYesterday = checkDate(dateObj, yesterday);
  if (dateWasYesterday) {
    return `Yesterday, ${dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }

  return dateObj.toLocaleDateString([], {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

// If the date exists, it should return the date in the format 12/31/2023
// If the date does not exist, it should return null
export const shortDate = (date?: string): string | null => {
  if (!date) return null;
  const dateObj = new Date(date);
  if (!isValidDate(dateObj)) return null;
  return dateObj.toLocaleDateString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export type GroupData = {
  label: string;
  date: Date;
  requests: AskRequest[];
};

// Group requests by date
export const getGroupedDates = (requests: AskRequest[], reversed: boolean = false) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const lastWeekStart = new Date(today);
  lastWeekStart.setDate(today.getDate() - 7);
  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  const groups: { [key: string]: GroupData } = {
    today: {
      label: 'Today',
      date: today,
      requests: [],
    },
    yesterday: {
      label: 'Yesterday',
      date: yesterday,
      requests: [],
    },
    lastWeek: {
      label: 'Last Week',
      date: lastWeekStart,
      requests: [],
    },
    thisMonth: {
      label: 'This Month',
      date: thisMonthStart,
      requests: [],
    },
    lastMonth: {
      label: 'Last Month',
      date: lastMonthStart,
      requests: [],
    },
  };

  requests.forEach(request => {
    const latestComment = request.comments?.length
      ? request.comments.reduce((latest, comment) => (latest.createdAt > comment.createdAt ? latest : comment))
      : null;

    const date = latestComment ? new Date(latestComment.createdAt) : new Date(request.createdAt);

    if (date.toDateString() === today.toDateString()) {
      groups.today.requests.push(request);
    } else if (date.toDateString() === yesterday.toDateString()) {
      groups.yesterday.requests.push(request);
    } else if (date >= lastWeekStart) {
      groups.lastWeek.requests.push(request);
    } else if (
      date >= thisMonthStart &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      groups.thisMonth.requests.push(request);
    } else if (date >= lastMonthStart && date < thisMonthStart) {
      groups.lastMonth.requests.push(request);
    } else if (date.getFullYear() === today.getFullYear()) {
      // Current year months (excluding last month)
      const groupKey = `${today.getFullYear()}-${date.getMonth()}`;
      if (!groups[groupKey]) {
        groups[groupKey] = {
          label: `${date.toLocaleString('default', { month: 'long' })} ${today.getFullYear().toString().slice(-2)}`,
          date: new Date(today.getFullYear(), date.getMonth(), 1),
          requests: [],
        };
      }
      groups[groupKey].requests.push(request);
    } else {
      // Previous years
      const yearKey = `year-${date.getFullYear()}`;
      if (!groups[yearKey]) {
        groups[yearKey] = {
          label: date.getFullYear().toString(),
          date: date, // Use the actual date instead of year end
          requests: [],
        };
      }
      groups[yearKey].requests.push(request);
    }
  });

  // Rest of the filtering and sorting logic remains the same
  const filteredGroups = Object.fromEntries(Object.entries(groups).filter(([_, group]) => group.requests.length > 0));

  const sortedGroups = Object.fromEntries(
    Object.entries(filteredGroups).sort(([_, a], [__, b]) =>
      reversed ? b.date.getTime() - a.date.getTime() : a.date.getTime() - b.date.getTime()
    )
  );

  Object.values(sortedGroups).forEach(group => {
    group.requests.sort((a, b) => {
      const dateA = a.comments?.length
        ? new Date(
            a.comments.reduce((latest, comment) => (latest.createdAt > comment.createdAt ? latest : comment)).createdAt
          )
        : new Date(a.createdAt);
      const dateB = b.comments?.length
        ? new Date(
            b.comments.reduce((latest, comment) => (latest.createdAt > comment.createdAt ? latest : comment)).createdAt
          )
        : new Date(b.createdAt);

      return reversed ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
    });
  });

  return sortedGroups;
};
