import React from 'react';
import { prefixClass } from 'lib/utils';
import './TransactionHeader.scss';
import { IconBrokenLink, IconChevron, IconLink } from 'components/Icons';
import { Link, useParams } from 'react-router-dom';
import useFetchLinks from 'hooks/useFetchLinks';
import { AskRequest, EntityLink, ResolvedLink } from '@hone-automation/common';
import { startOfWeek, endOfWeek, format, getWeek } from 'date-fns';

interface TransactionHeaderProps {
  request: AskRequest;
}

function TransactionHeader({ request }: TransactionHeaderProps) {
  const { locationId } = useParams();
  const { data: links } = useFetchLinks(request, locationId);

  const linksEnabled = request.type !== 'Customer Request';

  if (!linksEnabled || !links) {
    return null;
  }

  const firstLink = (links as ResolvedLink<EntityLink>[])[0];
  const prefix = prefixClass('transaction-header');

  if (!firstLink || !firstLink.valid) {
    return null;
  }

  const startDate = new Date(firstLink.dataDateRange.start);

  const weekNumber = getWeek(startDate, { weekStartsOn: 1 });
  const weekStartDate = startOfWeek(startDate, { weekStartsOn: 1 });
  const weekEndDate = endOfWeek(startDate, { weekStartsOn: 1 });

  return (
    <div className={prefix()}>
      <div className={prefix('week')}>
        <span>W{weekNumber}</span>
        {`${format(weekStartDate, 'MMM. d')} - ${format(weekEndDate, 'MMM. d, yyyy')}`}
      </div>
      <div className={`${prefix('link-container')} ${prefix('breadcrumb')}`}>
        <i className={prefix('icon-link')}>
          <IconLink />
        </i>
        <Link
          target="_blank"
          title="Go to report"
          className={prefix('breadcrumb-link')}
          to={`/app/location/${locationId}/report/${firstLink.link?.toId}?flaggedTransaction=${firstLink.link?.fromId}&fromType=${firstLink.link?.fromType}`}
        >
          <span>{firstLink.reportTitle}</span>
          <IconChevron />
        </Link>
        <span className={prefix('separator')}>...</span>
        <IconChevron />
        <Link
          target="_blank"
          title="Go to report"
          className={prefix('breadcrumb-link')}
          to={`/app/location/${locationId}/report/${firstLink.link?.toId}?flaggedTransaction=${firstLink.link?.fromId}&fromType=${firstLink.link?.fromType}`}
        >
          <span>{firstLink.sectionTitle}</span>
        </Link>
      </div>
    </div>
  );
}

export default TransactionHeader;
