import { useGetSlackChannels, useLinkSlackChannel } from 'hooks';
import SlackItem from 'presentation/components/Slack/SlackItem';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { find, findIndex, isEmpty } from 'lodash';
import Loading from 'components/Loading';
import { SlackChannel, SlackChannelDetail } from '@hone-automation/common';
import { IconRefreshDouble } from 'components/Icons';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { useRefreshSlackChannels } from 'hooks/slack/useRefreshSlackChannels';

export default function BookkeeperSlackChannels() {
  const { data: slackChannels, isLoading, refetch, isSuccess: isSuccessGetChannels } = useGetSlackChannels();
  const {
    mutate: refreshSlackChannels,
    isPending: refreshPending,
    isSuccess: refreshSuccess,
    isError: refreshError,
  } = useRefreshSlackChannels();
  const { currentLocation, currentLocationId, fetchLocationById } = useLocationsStore();
  const slackChannelsStored = currentLocation?.slackChannels;

  const {
    mutate: linkChannel,
    isPending: linkChannelPending,
    isSuccess: linkChannelSuccess,
    isError: linkChannelError,
  } = useLinkSlackChannel();

  const methods = useForm<{ selected: SlackChannelDetail[] }>({
    defaultValues: {
      selected: [],
    },
  });

  useEffect(() => {
    return () => {
      if (currentLocationId) {
        fetchLocationById(currentLocationId);
      }
      methods.reset();
    };
  }, []);

  const [search, setSearch] = useState<string>('');

  const _search = search.toLowerCase();

  const filteredData =
    slackChannels && (slackChannels as SlackChannelDetail[]).filter(item => item.name.toLowerCase().includes(_search));

  const { remove, append, fields } = useFieldArray({
    control: methods.control,
    name: 'selected',
  });

  useEffect(() => {
    if (slackChannelsStored && isSuccessGetChannels && fields.length === 0) {
      slackChannelsStored.forEach((item: SlackChannel) => {
        const channel = find(slackChannels as SlackChannelDetail[], { id: item.channelId });
        if (channel) append({ ...channel, idChannel: channel?.id });
      });
    }
  }, [isSuccessGetChannels, slackChannelsStored]);

  useEffect(() => {
    if (refreshSuccess || refreshError || linkChannelSuccess || linkChannelError) {
      setSearch('');
    }
  }, [refreshSuccess, refreshError, linkChannelSuccess, linkChannelError]);

  const handleItemClick = (item: SlackChannelDetail) => {
    const exists = find(fields, { idChannel: item.id });
    if (!exists) append({ ...item, idChannel: item?.id });
  };

  const onSubmit = () => {
    const selectedChannels: string[] | undefined =
      methods.watch('selected') && methods.watch('selected').map((item: SlackChannelDetail) => String(item.idChannel));
    if (selectedChannels) {
      linkChannel(selectedChannels);
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const selectedByDefault =
      slackChannelsStored &&
      slackChannelsStored.map((item: SlackChannel) => {
        const channel = find(slackChannels as SlackChannelDetail[], { id: item.channelId });
        return {
          ...channel,
          idChannel: channel?.id,
          id: String(channel?.id),
          name: String(channel?.name),
          isPrivate: !!channel?.isPrivate,
          numMembers: Number(channel?.numMembers),
        };
      });

    selectedByDefault && methods.setValue('selected', selectedByDefault);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const fieldsSelected = methods.watch('selected').map((item: SlackChannelDetail) => ({
    id: item.id,
    name: item.name,
    isPrivate: item.isPrivate,
    numMembers: item.numMembers,
  }));

  const refetchData = () => {
    refreshSlackChannels();
    refetch();
    currentLocationId && fetchLocationById(currentLocationId);
  };

  const handleItemClickRemove = (item: SlackChannelDetail) => {
    const index = findIndex(fields, item);
    if (index > -1) {
      remove(index);
    }
  };

  if (isLoading || linkChannelPending || refreshPending) {
    return (
      <div className="bookkeeper-slack-loading h-100 center ">
        <Loading />
      </div>
    );
  }

  const prefix = prefixClass('bookkeeper-slack-container');

  return (
    <div className="bookkeeper-slack-container">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={classNames(prefix('header'))}>
            <div className={classNames(prefix('header-search'))}>
              <input
                type="text"
                onChange={handleSearch}
                placeholder="Search...."
                className={classNames(prefix('header-input'))}
              />
              <button className={classNames(prefix('header-button'))} onClick={refetchData} type="button">
                <IconRefreshDouble />
              </button>
            </div>
            <div className={classNames(prefix('header-actions'))}>
              <button className={classNames(prefix('header-actions-btn'))} onClick={handleCancel}>
                Cancel
              </button>

              <button
                className={classNames(prefix('header-actions-btn'))}
                disabled={linkChannelPending}
                type={'submit'}
              >
                Save
              </button>
            </div>
          </div>

          <div className={classNames(prefix('content'))}>
            <div className={classNames(prefix('channel-list-container'))}>
              <div className={classNames(prefix('channel-list'))}>
                {filteredData &&
                  !isEmpty(filteredData) &&
                  filteredData.map((item: SlackChannelDetail, index: number) => {
                    const isSelected = findIndex(fieldsSelected, item) > -1;
                    return (
                      <SlackItem
                        item={item}
                        key={index}
                        isSelected={isSelected}
                        isInSecondColumn={false}
                        onClick={handleItemClick}
                      />
                    );
                  })}
              </div>
              <div className={classNames(prefix('channel-list-footer'))}>
                <span>
                  All Channels | {slackChannels && (slackChannels as SlackChannelDetail[])?.length} channel(s)
                </span>
              </div>
            </div>
            <div className={classNames(prefix('channel-list-container'))}>
              <div className={classNames(prefix('channel-list'))}>
                {fields.map((item, index) => (
                  <SlackItem
                    item={item}
                    key={index}
                    isSelected={false}
                    isInSecondColumn={true}
                    onClickRemove={(e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      handleItemClickRemove(item);
                    }}
                  />
                ))}
              </div>
              <div className={classNames(prefix('channel-list-footer'))}>
                <span>Linked Channels | {fields?.length} channel(s)</span>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
