import { prefixClass } from 'lib/utils';
import './LabelSwitchItem.scss';
import classNames from 'classnames';

type LabelSwitchButtonProps = {
  label: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const LabelSwitchItem = (props: LabelSwitchButtonProps) => {
  const { label, selected = false, disabled = false, onClick } = props;
  const prefix = prefixClass('label-switch-item');

  const handleClick = () => {
    if (disabled) return;
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={classNames(prefix())} onClick={handleClick}>
      <div className={classNames(prefix('label'), { 'lsi-selected': selected, 'lsi-disabled': disabled })}>{label}</div>
    </div>
  );
};

export default LabelSwitchItem;
