import { useEffect, useState, FormEvent, SyntheticEvent } from 'react';
import Loading from 'components/Loading';
import './BookkeeperForm.css';
import { IconCopy } from '../../../../components/Icons';
import { isProdHost, reportingOrigin, TEN_SECONDS } from 'lib/utils';
import Modal from 'react-modal';
import GenerateReportsForm from 'components/GenerateReportsForm';

import {
  showToast,
  dismissToast,
  TOAST_DEFAULT,
  TOAST_SUCCESS,
  TOAST_ERROR,
  ONE_MINUTE,
  HALF_MINUTE,
  FIVE_SECONDS,
} from 'lib/utils';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHoneReportTemplates } from 'components/HoneReportTemplates';
import { useLocationsStore, getLocationClassList, syncToken } from 'hooks/useLocationsStore';
import { useHoneLocations } from 'components/HoneLocations';

import { useAuthContext } from 'context/useAuthContext';
import { useRecentLocations } from '../../../../hooks/useRecentLocations';
import { Location as KsLocation, LocationClass, QBOEnvironment } from '@hone-automation/common';

const TOAST_GENERATE_TEMPLATES_TEXT = 'Generating templates, will take a moment to complete';
const TOAST_GENERATE_DASHBOARD_TEXT = 'Generating dashboard, will take a moment to complete';

function BookkeeperLocationForm(): JSX.Element {
  const { recentLocations, setRecentLocations } = useRecentLocations();

  const {
    status: locationsStatus,
    currentLocationId,
    setCurrentLocationId,
    currentLocation,
    fetchAllLocations,
  } = useLocationsStore();
  const { saveLocation } = useHoneLocations();

  const { userId } = useAuthContext();

  const { generateTemplate, generateDashboard } = useHoneReportTemplates();

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [org, setOrg] = useState('');
  const [weekStart, setWeekStart] = useState('');
  const [askCode, setAskCode] = useState('');
  const [qboEnv, setQboEnv] = useState('');
  const [qboId, setQboId] = useState('');
  const [tokenStatus, setTokenStatus] = useState('');
  const [classStr, setClassStr] = useState('');
  const [dept, setDept] = useState('');
  const [link, setLink] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [v2Link, setV2Link] = useState('');
  const [payrollLink, setPayrollLink] = useState('');
  const [tasksLink, setTasksLink] = useState('');
  const [teamsLink, setTeamsLink] = useState('');
  const [classList, setClassList] = useState<LocationClass[]>([]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOrgChange = (e: FormEvent<HTMLInputElement>) => {
    setOrg(e.currentTarget.value);
  };

  const handleNameChange = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleWeekStartChange = (value: string) => {
    setWeekStart(value);
  };

  const handleAskCodeChange = (e: FormEvent<HTMLInputElement>) => {
    setAskCode(e.currentTarget.value);
  };

  const handleQboEnvChange = (value: string) => {
    setQboEnv(value);
  };

  const handleQboIdChange = (e: FormEvent<HTMLInputElement>) => {
    setQboId(e.currentTarget.value);
  };

  const handleClassChange = (value: string) => {
    setClassStr(value);
  };

  const handleGetClasses = () => {
    if (!currentLocationId) {
      return;
    }
    getLocationClassList(currentLocationId)
      .then(result => {
        if (result && result.data) {
          setClassList(result.data);
          showToast(
            `Successfully refreshed class list: ${result.data.length} classes found in QBO`,
            TOAST_SUCCESS,
            FIVE_SECONDS
          );
        }
      })
      .catch(function (error: any) {
        showToast('Error getting class list for current location', TOAST_ERROR, HALF_MINUTE);
      });
  };

  const handleDeptChange = (e: FormEvent<HTMLInputElement>) => {
    setDept(e.currentTarget.value);
  };

  const handleLinkChange = (e: FormEvent<HTMLInputElement>) => {
    setLink(e.currentTarget.value);
  };

  const handlePaymentLinkChange = (e: FormEvent<HTMLInputElement>) => {
    setPaymentLink(e.currentTarget.value);
  };

  const handleV2LinkChange = (e: FormEvent<HTMLInputElement>) => {
    setV2Link(e.currentTarget.value);
  };

  const handlePayrollLink = (e: FormEvent<HTMLInputElement>) => {
    setPayrollLink(e.currentTarget.value);
  };

  const handleTasksLinkChange = (e: FormEvent<HTMLInputElement>) => {
    setTasksLink(e.currentTarget.value);
  };

  const handleTeamsLinkChange = (e: FormEvent<HTMLInputElement>) => {
    setTeamsLink(e.currentTarget.value);
  };

  const handleCopyId = () => {
    navigator.clipboard.writeText(id);
    showToast('Copied Location id to clipboard', TOAST_DEFAULT, FIVE_SECONDS);
  };

  const handleRefreshToken = () => {
    const qboEnv = currentLocation && currentLocation.qboEnv === 'Sandbox' ? '?qboEnv=Sandbox' : '';
    window.open(`${reportingOrigin()}/authUri${qboEnv}`, '_blank');
  };

  const handleSyncToken = () => {
    if (!currentLocationId) {
      showToast('Invalid location', TOAST_ERROR, TEN_SECONDS);
      return;
    }
    const toastId = showToast('Syncing Token', TOAST_DEFAULT, TEN_SECONDS);
    syncToken(currentLocationId)
      .then((result: any) => {
        dismissToast(toastId);
        if (result && result.data === 'ok') {
          showToast('Successfully synced token, good for 1 hour', TOAST_SUCCESS, FIVE_SECONDS);
        } else {
          showToast('Unable to sync token', TOAST_ERROR, TEN_SECONDS);
        }
      })
      .catch(function (error: any) {
        dismissToast(toastId);
        showToast('Error syncing token', TOAST_ERROR, TEN_SECONDS);
      });
  };

  const getTokenLabelType = () => {
    if (tokenStatus === 'Connected') {
      return 'connected';
    }
    if (tokenStatus === 'Token Expired') {
      return 'expired';
    }
    return 'default';
  };

  const handleSaveLocation = async (e: SyntheticEvent) => {
    const location: KsLocation = {
      id: currentLocationId!,
      name,
      org,
      status: 'Active',
      weekStart: weekStart as WeekDay,
      askCode,
      qboEnv: qboEnv as QBOEnvironment,
      qboId,
      class: classStr,
      dept: dept,
      tokenStatus,
      link,
      config:
        currentLocation && currentLocation.config && Object.keys(currentLocation.config).length > 0
          ? {
              ...currentLocation.config,
              links: {
                ...(currentLocation.config.links || {}),
                paymentLink,
                v2: v2Link,
                payroll: payrollLink,
                tasks: tasksLink,
                teams: teamsLink,
              },
            }
          : {
              links: {
                paymentLink,
                v2: v2Link,
                payroll: payrollLink,
                tasks: tasksLink,
                teams: teamsLink,
              },
            },
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    const toastId = showToast('Saving location, please wait a bit.', TOAST_DEFAULT, ONE_MINUTE);

    if (!userId) {
      dismissToast(toastId);
      showToast('No userId defined', TOAST_ERROR, FIVE_SECONDS);
      return;
    }

    saveLocation(location, userId)
      .then(async (result: any) => {
        if (result && result.data) {
          if (result.data.error !== undefined) {
            dismissToast(toastId);
            showToast('Error saving location.', TOAST_ERROR, FIVE_SECONDS);
          } else {
            await fetchAllLocations();
            setCurrentLocationId(result.data.id);
            dismissToast(toastId);
            showToast('Successfuly saved location.', TOAST_SUCCESS, FIVE_SECONDS);

            // check recent locations
            const found = recentLocations.findIndex(loc => loc.locationId === location.id);
            if (found > -1) {
              const copiedLocations = [...recentLocations];
              copiedLocations[found] = {
                ...location,
                locationId: location.id!,
                locationName: location.name,
              } as any;
              setRecentLocations(copiedLocations);
            }
          }
        }
      })
      .catch(e => {
        console.error(e);
      });
  };

  const handleGenerateTemplates = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3 className="alert">WARNING: Regenerating templates will override existing templates!!!</h3>
            <h4 className="text-center">Are you sure you want to continue?</h4>
            <div className="button-wrapper">
              <button className="button BKForm-btn-secondary mr-2" onClick={onClose}>
                Cancel
              </button>
              <button
                className="button BKForm-btn"
                onClick={() => {
                  onClose();
                  showToast(TOAST_GENERATE_TEMPLATES_TEXT, TOAST_DEFAULT, FIVE_SECONDS);
                  if (currentLocationId) {
                    generateTemplate(currentLocationId);
                  }
                }}
              >
                Generate Templates
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleGenerateDashboard = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui generate-dashboard-modal">
            <h3 className="alert">WARNING: Regenerating dashboard will overwrite existing data</h3>
            <h4 className="text-center">Are you sure you want to continue?</h4>
            <div className="button-wrapper">
              <button className="button BKForm-btn-secondary mr-2" onClick={onClose}>
                Cancel
              </button>
              <button
                className="button BKForm-btn center"
                onClick={() => {
                  onClose();
                  showToast(TOAST_GENERATE_DASHBOARD_TEXT, TOAST_DEFAULT, FIVE_SECONDS);
                  if (currentLocationId) {
                    generateDashboard(currentLocationId);
                  }
                }}
              >
                Generate Dashboard
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleOpenGenerateReportsModal = () => {
    setModalOpen(true);
  };

  const handleCloseGenerateReportsModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setId('');
    setOrg('');
    setName('');
    setWeekStart('Su');
    setAskCode('');
    setQboEnv('');
    setQboId('');
    setTokenStatus('');
    setClassStr('');
    setClassList([]);
    setDept('');
    setLink('');

    if (currentLocation) {
      if (currentLocation.id) {
        setId(currentLocation.id);
      }
      if (currentLocation.org) {
        setOrg(currentLocation.org);
      }
      if (currentLocation.name) {
        setName(currentLocation.name);
      }
      if (currentLocation.weekStart) {
        setWeekStart(currentLocation.weekStart);
      }
      if (currentLocation.askCode) {
        setAskCode(currentLocation.askCode);
      }
      if (currentLocation.qboEnv) {
        setQboEnv(currentLocation.qboEnv);
      }
      if (currentLocation.qboId) {
        setQboId(currentLocation.qboId);
      }
      if (currentLocation.tokenStatus) {
        setTokenStatus(currentLocation.tokenStatus);
      }
      if (currentLocation.class) {
        setClassStr(currentLocation.class);
      }
      if (currentLocation.classList) {
        setClassList(currentLocation.classList);
      }
      if (currentLocation.dept) {
        setDept(currentLocation.dept);
      }
      if (currentLocation.link) {
        setLink(currentLocation.link);
      }
      if (currentLocation.config?.links?.paymentLink) {
        setPaymentLink(currentLocation.config.links.paymentLink);
      }
      if (currentLocation.config?.links?.v2) {
        setV2Link(currentLocation.config.links.v2);
      }
      if (currentLocation?.config?.links?.payroll) {
        setPayrollLink(currentLocation?.config?.links?.payroll);
      }
      if (currentLocation.config?.links?.tasks) {
        setTasksLink(currentLocation.config.links.tasks);
      }
      if (currentLocation.config?.links?.teams) {
        setTeamsLink(currentLocation.config.links.teams);
      }
    }
  }, [currentLocation]);

  if (locationsStatus === 'loading') {
    return (
      <div className="d-flex justify-content-center mt-8 mb-4">
        <Loading unmount={false} />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-center mt-8 mb-4">
        <Modal id="goal-modal" isOpen={modalOpen} onRequestClose={handleCloseGenerateReportsModal}>
          <div className="d-flex flex-column">
            <GenerateReportsForm currentLocation={currentLocation} onSuccess={handleCloseGenerateReportsModal} />
            <button className="button button_link" onClick={handleCloseGenerateReportsModal} type="button">
              Cancel
            </button>
          </div>
        </Modal>

        {(currentLocation?.id || currentLocationId === '') && (
          <div className="mb-4" id="tab-settings" style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
            <div />
            <form className="">
              <table className="BKForm-table">
                <tr>
                  <td>
                    <label className="mr-3">id: </label>
                  </td>
                  <td>
                    <label className="label-light">{id}</label>
                  </td>
                  <td>
                    <button
                      className="button button_icon button_icon_light mb-1"
                      aria-label="Copy id"
                      type="button"
                      onClick={() => handleCopyId()}
                    >
                      <IconCopy />
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Name: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      placeholder="Name of Location"
                      className="mr-3"
                      onInput={handleNameChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Organization: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="org"
                      name="org"
                      value={org}
                      placeholder="Organization Name"
                      className="mr-3"
                      onInput={handleOrgChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Week Start: </label>
                  </td>
                  <td>
                    <select
                      id="weekStart"
                      name="weekStart"
                      defaultValue={currentLocation?.weekStart || ''}
                      onChange={e => handleWeekStartChange(e.currentTarget.value)}
                    >
                      <option value="Su">Su</option>
                      <option value="Mo">Mo</option>
                      <option value="Tu">Tu</option>
                      <option value="We">We</option>
                      <option value="Th">Th</option>
                      <option value="Fr">Fr</option>
                      <option value="Sa">Sa</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Ask Customer Code: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="askCode"
                      name="askCode"
                      value={askCode}
                      placeholder="Ask GL Code"
                      className="mr-3 width-150"
                      onInput={handleAskCodeChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">QBO Env: </label>
                  </td>
                  <td>
                    <select
                      id="qboEnv"
                      name="qboEnv"
                      defaultValue={currentLocation?.qboEnv}
                      onChange={e => handleQboEnvChange(e.currentTarget.value)}
                    >
                      <option value="Prod">Prod</option>
                      <option value="Sandbox">Sandbox</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">QBO Id: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="qboId"
                      name="qboId"
                      value={qboId}
                      placeholder="QBO id"
                      className="mr-3 width-250"
                      onInput={handleQboIdChange}
                    />
                    <label className={`token-label-${getTokenLabelType()}`}>{tokenStatus}</label>
                  </td>
                  <td>
                    {isProdHost() && (
                      <button
                        className="button button_outline mb-1"
                        aria-label="Refresh Token"
                        type="button"
                        onClick={() => handleRefreshToken()}
                      >
                        Refresh Token
                      </button>
                    )}
                    {!isProdHost() && (
                      <button
                        className="button button_outline mb-1"
                        aria-label="Sync Token"
                        type="button"
                        onClick={() => handleSyncToken()}
                      >
                        Sync Token
                      </button>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Class: </label>
                  </td>
                  <td>
                    <select
                      id="class"
                      name="class"
                      value={classStr}
                      onChange={e => handleClassChange(e.currentTarget.value)}
                    >
                      <option value=""></option>
                      {classList.map(locClass => (
                        <option value={locClass.id} key={locClass.id}>
                          {locClass.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      className="button button_outline mb-1"
                      aria-label="Copy id"
                      type="button"
                      onClick={() => handleGetClasses()}
                    >
                      Get Classes
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Department: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="dept"
                      name="dept"
                      value={dept}
                      placeholder="Department id"
                      className="mr-3"
                      onInput={handleDeptChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Demo Link: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="link"
                      name="link"
                      value={link}
                      placeholder="Another Location's id"
                      className="mr-3"
                      onInput={handleLinkChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <label className="mr-3">Pay Bills Link: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="paymentLink"
                      name="paymentLink"
                      value={paymentLink}
                      placeholder="Payment provider's link"
                      className="mr-3"
                      onInput={handlePaymentLinkChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="mr-3">V2 Link: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="v2Link"
                      name="v2Link"
                      value={v2Link}
                      placeholder="V2 Link"
                      className="mr-3"
                      onInput={handleV2LinkChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="mr-3">Payroll link: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="payrollLink"
                      name="payrollLink"
                      value={payrollLink}
                      placeholder="Payroll link"
                      className="mr-3"
                      onInput={handlePayrollLink}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="mr-3">Tasks Link: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="tasksLink"
                      name="tasksLink"
                      value={tasksLink}
                      placeholder="Tasks Link"
                      className="mr-3"
                      onInput={handleTasksLinkChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="mr-3">Teams Link: </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="teamsLink"
                      name="teamsLink"
                      value={teamsLink}
                      placeholder="Teams Link"
                      className="mr-3"
                      onInput={handleTeamsLinkChange}
                    />
                  </td>
                </tr>
              </table>
            </form>
            <div>
              <button className="button BKForm-btn-alert" type="button" onClick={() => handleGenerateTemplates()}>
                <span>Generate Templates</span>
              </button>
              <button className="button BKForm-btn" type="button" onClick={() => handleGenerateDashboard()}>
                <span>Generate Dashboard</span>
              </button>
              <button className="button BKForm-btn" type="button" onClick={() => handleOpenGenerateReportsModal()}>
                <span>Generate Reports</span>
              </button>
              <button className="button BKForm-btn-save" type="button" onClick={e => handleSaveLocation(e)}>
                <span>Save</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BookkeeperLocationForm;
