import './QueueDetails.scss';
import { prefixClass } from 'lib/utils';

import { useQueueContext } from '../QueueContext';
import TransactionDetails from '../TransactionDetails/TransactionDetails';
import CMComposer from '../CMComposer/CMComposer';
import Chat from '../Chat/Chat';
import TransactionHeader from '../TransactionHeader/TransactionHeader';

const QueueDetails = () => {
  const prefix = prefixClass('queue-details');

  const { state } = useQueueContext();
  const { selectedItem } = state;

  if (!selectedItem) return null;

  const showMessageComposer =
    selectedItem.status !== 'Closed' || (selectedItem.status === 'Closed' && selectedItem.canReopen);

  return (
    <div className={prefix()}>
      <div className={prefix('header')}>
        <TransactionHeader request={selectedItem} />
      </div>
      <div className={prefix('info')}>{selectedItem && <TransactionDetails request={selectedItem} />}</div>
      <div className={prefix('messages')}>{selectedItem?.comments && <Chat comments={selectedItem.comments} />}</div>
      {showMessageComposer && (
        <div className={prefix('composer')}>
          <CMComposer request={selectedItem} />
        </div>
      )}
    </div>
  );
};

export default QueueDetails;
