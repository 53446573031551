import { AskRequest } from '@hone-automation/common';
import { IconCheck, IconChevron } from 'components/Icons';
import React, { useState } from 'react';

import './TransactionDetails.scss';
import { prefixClass } from '../../../../lib/utils';
import { StatusItem } from '../common/StatusItem';
import { RequestStatus } from '../RequestItem/type';
import classNames from 'classnames';
import { format } from 'date-fns';

interface TransactionDetailsProps {
  request: AskRequest;
}

const STATUS_MAP: { [key: string]: RequestStatus } = {
  Open: 'created',
  'In Progress': 'inProgress',
  Closed: 'resolved',
};

const TransactionDetails: React.FC<TransactionDetailsProps> = ({ request }) => {
  const prefix = prefixClass('transaction-details');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const trxDate = request.transaction?.createdAt ? format(new Date(request.transaction.createdAt), 'MM/dd/yyyy') : '-';
  const trxName = request.transaction?.name || 'No vendor name';
  const description = request.comments?.[0]?.comment;
  const amount =
    request.transaction &&
    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(request.transaction?.amount);

  return (
    <div className={prefix('container')}>
      <button
        className={classNames(prefix('collapse-btn'), { active: !isCollapsed })}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <IconChevron />
      </button>
      {!isCollapsed ? (
        <div className={prefix('props')}>
          <div className={prefix('prop')}>
            <div className={prefix('prop-label')} />
            <div className={prefix('prop-amount title')}>Context</div>
          </div>
          <div className={prefix('prop')}>
            <div className={prefix('prop-label')}>TRX Date</div>
            <div className={prefix('prop-amount')}>{trxDate}</div>
          </div>
          <div className={prefix('prop')}>
            <div className={prefix('prop-label')}>Vendor</div>
            <div className={prefix('prop-amount')}>{trxName}</div>
          </div>
          <div className={prefix('prop')}>
            <div className={prefix('prop-label')}>Type</div>
            <div className={prefix('prop-amount')}>GL Account</div>
          </div>
          <div className={prefix('prop')}>
            <div className={prefix('prop-label')}>Amount</div>
            <div className={prefix('prop-amount')}>{amount || '-'}</div>
          </div>

          <div className={prefix('prop')}>
            <div className={prefix('prop-label')}>Description</div>
            <div className={prefix('prop-amount')}>{description}</div>
          </div>
        </div>
      ) : (
        <>
          <div className={prefix('collapsed-description')}>{description}</div>
          <StatusItem
            userName={''}
            status={STATUS_MAP[request.status]}
            date={new Date(request.updatedAt).toDateString()}
          />
        </>
      )}

      {/*<div className="border-l-2 border-gray-200 pl-8 ml-4">*/}
      {/*  {flowSteps.map((step, index) => (*/}
      {/*    <div key={index} className="relative mb-6">*/}
      {/*      <div className="absolute -left-9 top-2 w-4 h-4 bg-white border-2 border-emerald-500 rounded-full"></div>*/}
      {/*      <div className="flex items-center gap-4">*/}
      {/*        <span*/}
      {/*          className={`px-3 py-1 rounded text-sm font-medium ${*/}
      {/*            step.type === 'ME' ? 'bg-gray-200 text-gray-700' : 'bg-emerald-100 text-emerald-600'*/}
      {/*          }`}*/}
      {/*        >*/}
      {/*          {step.type}*/}
      {/*        </span>*/}
      {/*        <span className="text-gray-600">{step.date}</span>*/}
      {/*        {step.completed && <IconCheck size={16} className="text-emerald-500" />}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  );
};

export default TransactionDetails;
