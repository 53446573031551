import { Table, Column, HeaderCell, RowDataType } from 'rsuite-table';
import { showToast, TOAST_ERROR, FIVE_SECONDS, BALANCE_SHEET_PRESETS } from 'lib/utils';

import 'jspdf-autotable';
import React, { createRef, MutableRefObject, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import classNames from 'classnames';

import 'rsuite-table/dist/css/rsuite-table.min.css';

import DataRowModal from 'components/Table/DataRowModal';

import { HoneReportTimeframes, HoneReportTypes } from '../../../constants';

import DataCell from './DataCell';

import {
  auditReport as performAudit,
  flattenTree,
  getExpandedRows,
  getNodesWithChildren,
  renameSectionsToChildren,
  traverseSections,
  getTableHeight,
  renderDate,
  getPrintingInformation,
} from 'lib/honeTableUtils';

import { filter, isEmpty, random } from 'lodash';
import usePrevious from 'hooks/usePrevious';
import jsPDF from 'jspdf';

import { useLocationsStore } from 'hooks/useLocationsStore';
import { useReportsStore } from 'hooks/useReportsStore';
import { useQueryState } from 'hooks/useQueryState';
import AuditSummary from '../audit/AuditSummary';
import GraphCell from 'presentation/components/ReportTable/GraphCell';
import TitleCell from 'presentation/components/ReportTable/TitleCell';
import { getHeaderFormattedText, getTimeframePeriod } from 'presentation/utils';
import { initialDataCellState, useActiveReportStore } from 'hooks/useActiveReportStore';
import useActiveActions from 'hooks/useActiveActions';
import { useReportFlaggedTransactionQuery } from 'hooks/flaggedTransactions/useReportFlaggedTransactionQuery';
import { useFlaggedTransactions } from 'hooks/flaggedTransactions';
import { useParams } from 'react-router-dom';
import { IconForwardBtn } from 'components/Icons';
import useAttachmentsModal from 'hooks/useAttachmentsModal';
import AttachmentModal from 'components/Attachments/AttachmentModal';
import PlotGraphCell from 'presentation/components/ReportTable/PlotGraphCell';
import { useHoneLocationUsers } from '../../../components/HoneLocationUsers';
import { PeriodStartEndDates, ReportColumnTypes } from '@hone-automation/common';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import { shallow } from 'zustand/shallow';

import { DataCellState } from 'domain/models';
import { getPrintedHeaderDate } from 'lib/reportUtils';
import { ExpandCollapse } from 'components/ExpandCollapse';
import SearchAndFilter from './SearchAndFilter';

export interface HoneTableProps {
  report: any;
  headers: string[];
  smoothingEnabled: boolean;
  hasCharts: boolean;
  allRowsExpanded?: boolean;
  selectedReportType: HoneReportType;
}

const ReportHoneTable = ({
  report,
  smoothingEnabled,
  allRowsExpanded = false,
  selectedReportType,
}: HoneTableProps): JSX.Element => {
  const { hasAdminRole } = useHoneLocationUsers();

  const currentLocation = useLocationsStore(state => state.currentLocation);
  const selectedReport = useReportsStore(state => state.selectedReport);
  const {
    yearPlotted,
    refreshingReport,
    setRefreshingReport,
    enableMultiLocation,
    modalCellState,
    setDataCellState,
    currentReport,
    setExpandedRows,
  } = useActiveReportStore(
    state => ({
      yearPlotted: state.yearPlotted,
      refreshingReport: state.refreshingReport,
      setRefreshingReport: state.setRefreshingReport,
      enableMultiLocation: state.enableMultiLocation,
      modalCellState: state.modalCellState,
      setDataCellState: state.setDataCellState,
      currentReport: state.currentReport,
      setExpandedRows: state.setExpandedRows,
    }),
    shallow
  );

  const { reportId } = useParams<{ reportId: string }>();

  const isNewBalanceSheetEnabled = Object.keys(BALANCE_SHEET_PRESETS).includes(reportId!);

  const currentLocationId = useLocationsStore(state => state.currentLocationId);
  const auditReport = useReportsStore(state => state.auditReport);
  const isApAgingReport = selectedReportType === HoneReportTypes.ApAging;

  const [localExpandedRows, setLocalExpandedRows] = useState<string[]>([]);

  // const { reportViewState, setReportViewState } = useContext(ReportViewContext);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { showAttachmentImage, attachmentsUrl, attachmentAmount, handleCloseAttachmentImage, getAttachmentsUrl } =
    useAttachmentsModal();

  const [collapseTable, setCollapseTable] = useState<boolean>(true);

  const isCFReport = report && report?.type === 'Cash Flow' && report?.sections[0]?.level === 1;
  const reportSections = traverseSections(report?.sections, isCFReport);
  let data = renameSectionsToChildren(reportSections);

  const shown = useRef<boolean>();

  const { data: reportFlaggedTransaction, status } = useReportFlaggedTransactionQuery();

  const [auditMode] = useQueryState('audit');

  const dateRange = urlParams.get('dateRange');
  const total = urlParams.get('total');
  const difference = urlParams.get('difference');
  const compareLocations = urlParams.get('compareLocations');
  const consolidated = urlParams.get('consolidated');
  const isAggrSideBySide =
    compareLocations && (!consolidated || consolidated === 'false') ? compareLocations?.split(',').length > 1 : false;

  if (dateRange && (!total || !difference)) {
    data = data.map((row: any) => {
      const newRow = { ...row };
      const newData = [...newRow.data];
      newData.pop();
      return { ...newRow, data: newData };
    });
  }

  if (auditReport) {
    data = performAudit(data, auditReport);
  }

  const rowsWithChildren = getNodesWithChildren(data);
  const prevData = usePrevious({ data });
  const tableRef = useRef() as MutableRefObject<HTMLDivElement>;
  const tableEle = createRef() as any;
  const { percentages: showPercentageValues, amounts: showMoneyValues } = useActiveActions();

  const [chartCollapsed] = useQueryState('chartCollapsed', 'true');
  const [budgetCollapsed, setBudgetCollapsed] = useQueryState('budgetCollapsed', 'false');

  useEffect(() => {
    if (selectedReport?.type === HoneReportTypes.IncomeStatement) {
      setCollapseTable(false);
    }
  }, [selectedReport?.type]);

  useEffect(() => {
    if (prevData?.data.length !== data.length && !isEmpty(data)) {
      setLocalExpandedRows(rowsWithChildren);
      setSearchTerm('');
      setFilteredData(filterData(data, searchTerm));
    }
  }, [data]);

  useEffect(() => {
    const initialExpandedRows = !collapseTable ? [] : rowsWithChildren;
    setLocalExpandedRows(initialExpandedRows);
  }, [collapseTable]);

  const handleClose = () => {
    setDataCellState({ ...initialDataCellState, dataRows: modalCellState.dataRows });
  };

  const onHandleCollapseExpand = () => {
    setCollapseTable(!collapseTable);
  };

  const setDataModal = (payload: DataCellState) => {
    setDataCellState(payload);
  };

  const handleExpandRow = (expanded: boolean, rowData: any) => {
    const getExpandedRowsState = getExpandedRows(localExpandedRows, expanded, rowData);
    setLocalExpandedRows(getExpandedRowsState);
  };

  const reportDates = useMemo(() => {
    if (!dateRange) {
      return report ? report.dates : [];
    }
    if (total || difference) {
      return report ? report.dates : [];
    }
    const tempReportDates = [...report.dates];
    tempReportDates.pop();
    return tempReportDates;
  }, [new Date()]);

  const reportDatesHeaders: string[] = useMemo(() => {
    const reportDatesHeaderFormatted = reportDates.map((date: any) => {
      const hasSubheader =
        (date.type && date.type === ReportColumnTypes.Data) || date.type === ReportColumnTypes.Budget;

      const subheaderLabel = hasSubheader && date.type === ReportColumnTypes.Data ? 'ACTUAL' : 'BUDGET';

      const isAggrSideBySideAndEnabled = isAggrSideBySide && enableMultiLocation;

      const headerFormatted = getHeaderFormattedText(
        date,
        report,
        yearPlotted,
        isAggrSideBySide && isAggrSideBySideAndEnabled
      );

      const getPrintedDate = getPrintedHeaderDate(headerFormatted, null, false);

      return (getPrintedDate as string).concat(hasSubheader ? ` ${subheaderLabel}` : '');
    });
    return reportDatesHeaderFormatted;
  }, [reportDates]);

  const expandedRows = allRowsExpanded ? getNodesWithChildren(data) : localExpandedRows;

  useEffect(() => {
    setExpandedRows(expandedRows);
  }, [expandedRows]);

  const generatePdf = () => {
    try {
      const doc = new jsPDF({ orientation: 'l' });

      const rows = getPrintingInformation(currentReport?.sections, expandedRows, showMoneyValues, showPercentageValues);
      const columns: string[] = ['Accounts', ...reportDatesHeaders];

      (doc as any).autoTable({
        html: '#my-table',
        styles: {
          fontSize: 50,
          cellWidth: 'wrap',
        },
        columnStyles: {
          1: { columnWidth: 'auto' },
        },
      });

      (doc as any).autoTable(columns, rows);
      const reportName = `${selectedReport?.startDateText} - ${selectedReport?.endDateText}`;
      const filename = `KitchenSync Report: ${currentLocation?.name} ${selectedReport?.title} - ${reportName}.pdf`;
      doc.save(filename);
    } catch (error) {
      showToast('An error has occurred, please contact admin', TOAST_ERROR, FIVE_SECONDS);
    }
  };

  const reportType = report?.type;

  const smallReport =
    ['Cash Flow', !isNewBalanceSheetEnabled ? 'Balance Sheet' : null].includes(reportType) || report?.dates.length < 4;
  const isYTD = report?.timeframe.includes('YTD');
  const isWeekly = report?.timeframe.includes('Weekly');
  const largeReport = isYTD || isNewBalanceSheetEnabled;
  const shouldHaveBigRow = !smallReport && showMoneyValues && showPercentageValues;
  const rowHeight = shouldHaveBigRow ? 38 : 38;
  const columnsWidth = report?.dates.length > 5 ? 200 : 215;
  const isAdminUser = hasAdminRole;

  useEffect(() => {
    if (status === 'success' && !reportFlaggedTransaction?.valid) {
      showToast('Flagged transaction not available for this report', TOAST_ERROR, FIVE_SECONDS);
      shown.current = true;
    } else {
      if (status === 'success') {
        const sections = flattenTree(report.sections);
        const rowDataIndex = sections.findIndex((section: any) => {
          return section.title === reportFlaggedTransaction?.sectionTitle;
        });

        tableEle.current.scrollTop(rowDataIndex * rowHeight);
        const rowData = sections[rowDataIndex];

        // traverse up the tree until finds parent that contains the flagged transaction row
        for (let i = rowDataIndex; i > -1; i--) {
          const section = sections[i];

          if (section.hasOwnProperty('sections')) {
            const found = section.sections.find((ele: any) => ele.title === rowData.title);

            if (found) {
              setLocalExpandedRows(expandedRows => [
                ...expandedRows,
                `${section.title.replace(' ', '-')?.toLowerCase()}.${section?.id}`,
              ]);
              break;
            }
          }
        }

        const parent = sections[rowDataIndex - 1] && sections[rowDataIndex - 1].title;

        if (typeof reportFlaggedTransaction?.dataIndex !== 'undefined') {
          const { dataIndex } = reportFlaggedTransaction;

          if (rowData) {
            setDataModal({
              date: report.dates[dataIndex],
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dataRows:
                reportType === 'Income Statement'
                  ? reportFlaggedTransaction.dataRows
                  : rowData.data[dataIndex].dataRows,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              title: rowData.title,
              parent: parent,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              total: rowData.data[dataIndex].amount,
              modalOpen: true,
              audit: undefined,
              loading: false,
            });
          }

          const transaction = reportFlaggedTransaction?.resolvedEntity?.transaction;
          if (transaction) {
            const transactionBeingFlagged = {
              txnDate: new Date(transaction.date),
              type: transaction.externalTransactionType,
              eventId: transaction.externalId,
              num: transaction.externalNum,
              name: transaction.name,
              description: transaction.memo,
              amount: transaction.amount,
              flagged: reportFlaggedTransaction?.resolvedEntity,
            };
            useFlaggedTransactions.setState({
              transactionBeingFlagged,
            });
            if (reportFlaggedTransaction?.dataRowIndex) {
              tableEle.current.scrollTop(rowDataIndex * rowHeight);
            }
          }
        }
      }
    }
  }, [reportFlaggedTransaction, status]);

  const currentPeriod = report?.dates.find((date: any) => {
    if (date.end && date.end.startsWith('2')) {
      return new Date(date.end).getTime() > new Date().getTime() ? date : false;
    }
    return false;
  });

  const hasDelta = report?.timeframe === HoneReportTimeframes.Monthly;

  const shouldShowPlotColumn =
    selectedReport &&
    ![HoneReportTypes.BalanceSheet, HoneReportTypes.CashFlow, HoneReportTypes.ApAging].includes(selectedReport.type);

  const tableHeight = useMemo(() => {
    const showViewToggles = [
      HoneReportTypes.CashFlow,
      HoneReportTypes.BalanceSheet,
      HoneReportTypes.ApAging,
      '',
    ].includes(selectedReportType);

    return getTableHeight(chartCollapsed === 'true', isYTD, showViewToggles, auditMode === 'true');
  }, [chartCollapsed, isYTD, selectedReportType, auditMode]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const filterData = (items: any[], term: string | null): Report[] => {
    return items.filter(item => {
      if (!term) {
        return true;
      }

      const titleMatch = item.title.toLowerCase().includes(term.toLowerCase());
      const amountMatch =
        false && item.data?.some((d: any) => term && d.amount?.toString().includes(term.toLowerCase()));

      if (titleMatch || amountMatch) {
        return true;
      }

      if (item.children) {
        item.children = filterData(item.children, term);
        return item.children.length > 0;
      }

      return false;
    });
  };

  const handleClear = () => {
    setSearchTerm('');
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    setFilteredData(filterData(data, searchTerm));
  }, [searchTerm]);

  return (
    <div
      key={reportId}
      className={classNames(`hone-report-table`, {
        'hone-report-table-admin': isAdminUser,
        smallReport,
        ytd: largeReport,
        smallRows: !shouldHaveBigRow,
        audit: auditMode === 'true',
      })}
      ref={tableRef}
    >
      <AuditSummary />
      <SearchAndFilter
        liveReport={currentPeriod ? report?.timeframe : null}
        selectedReportType={selectedReportType}
        searchValue={searchTerm}
        onSearch={handleSearch}
        onClear={handleClear}
      />
      <div className="just-table" style={{ height: tableHeight - 120 }}>
        <Table
          className={smallReport ? 'table-pointer small-reports' : 'table-pointer'}
          key={selectedReport?.startDate}
          loading={refreshingReport}
          ref={tableEle}
          height={tableHeight}
          isTree
          defaultExpandAllRows={false}
          expandedRowKeys={expandedRows}
          onExpandChange={handleExpandRow}
          rowKey={'rowKey'}
          rowHeight={rowHeight}
          rowClassName={(rowData: RowDataType) => rowData?.className}
          data={filteredData as RowDataType[]}
          headerHeight={50}
          shouldUpdateScroll={false}
          rowExpandedHeight={80}
          hover
          disabledScroll={false}
          affixHorizontalScrollbar={true}
          virtualized
        >
          <Column
            minWidth={smallReport ? undefined : reportType === HoneReportTypes.ApAging ? 200 : 470}
            fixed
            flexGrow={1}
          >
            <HeaderCell className="rs-content-header">
              <div className="cell_header first-cell small-row-flex">
                {!allRowsExpanded && !isApAgingReport && (
                  <ExpandCollapse expand={collapseTable} header={false} handleExpand={onHandleCollapseExpand} />
                )}
                <div className="small-row-flex-label">Accounts</div>
              </div>
            </HeaderCell>
            <TitleCell
              expandedRows={expandedRows}
              onRowClick={handleExpandRow}
              dataKey="title"
              showAllData={showPercentageValues && showMoneyValues}
            />
          </Column>
          {isNewBalanceSheetEnabled && (
            <Column width={70} fixed>
              <HeaderCell>{''}</HeaderCell>
              <GraphCell />
            </Column>
          )}
          {shouldShowPlotColumn && (
            <Column width={70} fixed>
              <HeaderCell className={'chart-cell'}>{''}</HeaderCell>
              <PlotGraphCell reportDatesHeaders={reportDatesHeaders} />
            </Column>
          )}

          {reportDates &&
            reportDates.map((date: PeriodStartEndDates, index: number) => {
              const isColumnActive = index % 2 === 0;
              const hasSubheader =
                (date.type && date.type === ReportColumnTypes.Data) || date.type === ReportColumnTypes.Budget;

              const subheaderLabel = hasSubheader && date.type === ReportColumnTypes.Data ? 'ACTUAL' : 'BUDGET';

              const isAggrSideBySideAndEnabled = isAggrSideBySide && enableMultiLocation;

              const headerFormatted = getHeaderFormattedText(
                date,
                report,
                yearPlotted,
                isAggrSideBySide && isAggrSideBySideAndEnabled
              );

              const getPrintedDate = getPrintedHeaderDate(headerFormatted, compareLocations, false);

              const shouldDisplayColumn =
                subheaderLabel === 'BUDGET' ? (budgetCollapsed === 'false' ? true : false) : true;

              if (!shouldDisplayColumn) return null;
              return (
                <Column
                  key={`column-${date.start}-${date.end}-${index}`}
                  width={showMoneyValues && showPercentageValues ? columnsWidth : 150}
                >
                  <HeaderCell align="center" className="rs-content-header">
                    <div className={classNames('cell_header', { cell_header_active: isColumnActive })}>
                      <div className="cell_header_text">{getPrintedDate}</div>
                      {hasSubheader && (
                        <div className="cell_subheader">
                          <div>
                            {date.type === ReportColumnTypes.Data && (
                              <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={<Tooltip>Transactions</Tooltip>}
                                delayOpen={400}
                              >
                                <div>●</div>
                              </Whisper>
                            )}
                          </div>
                          {showMoneyValues && <div>{subheaderLabel}</div>}
                          {showPercentageValues && <div>%</div>}
                        </div>
                      )}
                    </div>
                  </HeaderCell>
                  <DataCell
                    smoothingEnabled={smoothingEnabled}
                    dataKey="data"
                    columnIndex={index}
                    isColumnActive={isColumnActive}
                    date={date}
                    smallReport={['Cash Flow', 'Balance Sheet', 'AP Aging'].includes(reportType)}
                    setDataModal={setDataModal}
                    showMoney={showMoneyValues}
                    showPercentage={showPercentageValues}
                  />
                </Column>
              );
            })}
        </Table>
      </div>
      <DataRowModal
        modalState={modalCellState}
        handleClose={handleClose}
        showAttachment={(attachments, amount) => getAttachmentsUrl(currentLocationId, attachments, amount)}
      />
      <AttachmentModal
        showAttachmentImage={showAttachmentImage}
        handleClose={handleCloseAttachmentImage}
        attachmentAmount={attachmentAmount}
        attachmentsUrl={attachmentsUrl}
        title={`${selectedReport?.title} | ${
          selectedReport && `${renderDate(selectedReport?.startDateText)} - ${renderDate(selectedReport?.endDateText)}`
        }`}
        breadcrumb={
          <ol className="breadcrumb">
            {modalCellState.parent && (
              <>
                <li>{modalCellState.parent}</li>
                <li className="breadcrumb-separator">
                  <IconForwardBtn />
                </li>
              </>
            )}
            <li>{modalCellState.title}</li>
            {/* YTD */}
            {modalCellState?.date && modalCellState?.date.end === '' ? (
              <>
                <li className="breadcrumb-separator">
                  <IconForwardBtn />
                </li>
                <li>
                  {modalCellState?.date.start}{' '}
                  {selectedReport &&
                    selectedReport.type !== HoneReportTypes.ApAging &&
                    renderDate(selectedReport?.startDateText)}
                </li>
              </>
            ) : (
              <>
                <li className="breadcrumb-separator">
                  <IconForwardBtn />
                </li>
                <li>{modalCellState?.date && renderDate(modalCellState?.date.end)}</li>
              </>
            )}
          </ol>
        }
      />
    </div>
  );
};

export default ReportHoneTable;
