import KSLocationGroup from '../KSLocationGroup';
import { useUserLocationsQuery } from 'hooks/useUserLocationsQuery';
import KSClock from '../KSClock/KSClock';
import UserDropdownMenu from '../../../components/UserDropdown';
import './KSHeader.scss';

function KSHeader() {
  const { data: userLocations } = useUserLocationsQuery();

  if (!userLocations) {
    return null;
  }

  return (
    <div className="ks-header">
      <KSLocationGroup locations={userLocations as HoneLocationUser[]} />
      <div className="ks-header-right">
        <KSClock />
        <UserDropdownMenu closedNavigationMenu={false} />
      </div>
    </div>
  );
}

export default KSHeader;
