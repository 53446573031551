import { useMutation } from '@tanstack/react-query';
import { FIVE_SECONDS, TOAST_ERROR, TOAST_SUCCESS, showToast } from 'lib/utils';

import { makeRefreshSlackChannels } from 'main/factories/usecases/slack/remote-refresh-slack-channels';

export const useRefreshSlackChannels = () => {
  return useMutation({
    mutationFn: () => {
      const service = makeRefreshSlackChannels();
      return service.refreshSlackChannels();
    },
    onError: () => {
      showToast('Error while updating request', TOAST_ERROR, FIVE_SECONDS);
    },
    onSuccess: () => {
      showToast('Slack channels updated successfully', TOAST_SUCCESS, FIVE_SECONDS);
    },
  });
};
