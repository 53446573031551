import React, { useState } from 'react';
import Avatar from '../common/AvatarContainer';
import { IconMore } from '../../../../components/Icons';
import './ChatMessage.scss';
import { format } from 'date-fns';
import { AskRequestComment, UserLocation } from '@hone-automation/common';
import MessageAttachments from '../common/MessageAttachments/MessageAttachments';
import { useQueueContext } from '../QueueContext';

interface ChatMessageProps {
  user: UserLocation;
  editable: boolean;
  date?: Date;
  showDate: boolean;
  content: string;
  glCode?: boolean;
  createdAt: Date;
}

function ChatMessage({ user, showDate, date, content }: ChatMessageProps) {
  const {
    state: { meId },
  } = useQueueContext();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleEdit = () => {
    setShowDropdown(false);
    // Handle edit action
  };

  const handleDelete = () => {
    setShowDropdown(false);
    // Handle delete action
  };

  const userInfo = {
    userName: user?.email,
    userId: user?.userId,
    title: '',
    email: user?.email,
    colorId: '1',
  };

  const time = date && format(date, 'hh:MM a');
  const chatDate = date && format(date, 'LLL do, yyyy');

  return (
    <>
      {showDate && date && <div className="chat-date">{chatDate}</div>}
      <div className="message">
        <Avatar me={user?.userId === meId} userInfo={userInfo} />
        <div className="message__content">
          <div className="message__header">
            <div className="message__sender-info">
              <span className="message__sender">{userInfo.userName}</span>
              <span className="message__time">{time}</span>
            </div>
            {/*<button className="message__more-button" onClick={() => setShowDropdown(!showDropdown)}>*/}
            {/*  <IconMore />*/}
            {/*</button>*/}
            {/*{showDropdown && (*/}
            {/*  <div className="message__dropdown">*/}
            {/*    <button className="message__dropdown-item" onClick={handleEdit}>*/}
            {/*      Edit*/}
            {/*    </button>*/}
            {/*    <button className="message__dropdown-item message__dropdown-item--delete" onClick={handleDelete}>*/}
            {/*      Delete*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
          <p className="message__text">{content}</p>
          {/* TODO */}
          {/*{message.comment && <MessageAttachments attachments={} />}*/}
        </div>
      </div>
    </>
  );
}

export default ChatMessage;
