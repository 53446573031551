import React, { useMemo } from 'react';
import KSCheckBox from '../../../KSCheckBox';
import { useFormContext } from 'react-hook-form';

interface ShowReverseColumnProps {
  isAggrSideBySide: boolean;
  consolidated: string;
}

function ShowReverseColumn({ isAggrSideBySide, consolidated }: ShowReverseColumnProps) {
  const { watch, reset } = useFormContext();

  const shouldDisabledReverse = useMemo(() => {
    if (isAggrSideBySide || consolidated === 'true') {
      return true;
    }
    if (consolidated === 'true') {
      return false;
    }
    if (watch('comparison.comparisonPeriods')! === 1 && watch('utility.isCompareEnabled')) {
      return false;
    }
    if (watch('utility.isCompareEnabled') || watch('utility.isCompareYTDEnabled')) {
      return false;
    }

    return !watch('breakdownPeriods');
  }, [
    watch('breakdownPeriods'),
    watch('utility.isCompareEnabled'),
    watch('utility.isCompareYTDEnabled'),
    watch('comparison.comparisonPeriods'),
  ]);

  return (
    <KSCheckBox
      disabled={shouldDisabledReverse}
      label={'Reverse Column Order'}
      checked={Boolean(watch('reverse'))}
      onChange={() => {
        reset({
          ...watch(),
          reverse: !watch('reverse'),
        });
      }}
    />
  );
}

export default ShowReverseColumn;
