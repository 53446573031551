import React, { useMemo, useRef, useEffect } from 'react';
import { parseISO } from 'date-fns';
import ChatMessage from '../ChatMessage/ChatMessage';
import { groupMessagesByDate } from '../../../utils';
import { AskRequestComment, UserLocation } from '@hone-automation/common';
import { useHoneLocationUsers } from '../../../../components/HoneLocationUsers';

interface ChatProps {
  comments?: AskRequestComment[];
}

type CommentOrientation = 'left' | 'right';

type Comment = {
  user: UserLocation;
  orientation: CommentOrientation;
  showDate: boolean;
  glCode?: boolean;
  date?: Date;
  content: string;
  editable: boolean;
  createdAt: Date;
};

function Chat({ comments }: ChatProps) {
  const { isBookkeeperAdmin } = useHoneLocationUsers();
  const groupedMessages = useMemo(() => groupMessagesByDate(comments ?? []), [comments]);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Scroll to the bottom when new comments are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [comments]);

  return (
    <div ref={chatContainerRef} style={{ height: '100%', overflowY: 'auto' }}>
      {Object.entries(groupedMessages).map(([date, messages]) =>
        messages.map((message, idx) => {
          const showDate = idx === 0;

          let orientation: CommentOrientation = 'left';
          const { isHoneInternalUser } = message.userLocation ?? {};

          if ((isBookkeeperAdmin && isHoneInternalUser) || (!isBookkeeperAdmin && !isHoneInternalUser)) {
            orientation = 'right';
          }

          const comment: Comment = {
            user: message.userLocation,
            orientation: orientation,
            editable: false,
            date: parseISO(date),
            showDate,
            content: message.comment,
            createdAt: message.createdAt,
          };

          return <ChatMessage key={`chat-${idx}`} {...comment} />;
        })
      )}
    </div>
  );
}

export default Chat;
