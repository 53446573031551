import React, { useMemo } from 'react';
import KSCheckBox from '../../../KSCheckBox';
import { useFormContext } from 'react-hook-form';

interface ShowDifferenceProps {
  isAggrSideBySide: boolean;
}

function ShowDifference({ isAggrSideBySide }: ShowDifferenceProps) {
  const { watch, reset } = useFormContext();

  const shouldDisabledDifference = useMemo(() => {
    if (isAggrSideBySide) {
      return true;
    }
    if (watch('total')) {
      return true;
    }
    if (watch('breakdownPeriods')) {
      return true;
    }
    if (watch('budgets.include') === false && watch('comparison.comparisonPeriods')! > 1) {
      return true;
    }
    if (watch('budgets.include') && watch('utility.isCompareEnabled')) {
      return true;
    }
    if (watch('budgets.include')) {
      return false;
    }

    if (watch('utility.isCompareEnabled') || watch('utility.isCompareYTDEnabled')) {
      return false;
    }

    return true;
  }, [
    watch('timeframe'),
    watch('total'),
    watch('budgets.include'),
    watch('breakdownPeriods'),
    watch('utility.isCompareEnabled'),
    watch('utility.isCompareYTDEnabled'),
    watch('comparison.comparisonPeriods'),
    isAggrSideBySide,
  ]);

  return (
    <KSCheckBox
      disabled={shouldDisabledDifference}
      label={'Show Difference/Budget Variance'}
      checked={Boolean(watch('difference'))}
      onChange={() => {
        reset({
          ...watch(),
          difference: !watch('difference'),
        });
      }}
    />
  );
}

export default ShowDifference;
