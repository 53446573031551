import SHA256 from 'crypto-js/sha256';
import encHex from 'crypto-js/enc-hex';

const cache = new WeakMap<object, string>();

export function generateDeterministicKey(obj: Record<string, unknown>): string {
  // If we already hashed this object reference, return the cached value
  const cached = cache.get(obj);
  if (cached) {
    return cached;
  }
  // 1. Sort keys to ensure consistent ordering
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj: Record<string, unknown> = {};

  sortedKeys.forEach(key => {
    sortedObj[key] = obj[key];
  });

  // 2. Convert the sorted object to a JSON string
  const jsonStr = JSON.stringify(sortedObj);

  // 3. Create a hash from the string
  const hash = SHA256(jsonStr);
  return hash.toString(encHex);
}
