import './RequestGroupHeader.scss';
import { prefixClass } from 'lib/utils';
import { IconChevron, IconChevronDown } from 'components/Icons';
import { useState } from 'react';

type RequestGroupHeaderProps = {
  title: string;
  numRequests: number;
  collapsed?: boolean;
  onCollapse: () => void;
};

const RequestGroupHeader = ({ title, numRequests, collapsed = false, onCollapse }: RequestGroupHeaderProps) => {
  const prefix = prefixClass('request-group-header');
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsCollapsed(i => !i);
    onCollapse();
  };

  return (
    <div className={prefix()}>
      <div className={prefix('collapse-control')} onClick={handleClick}>
        {isCollapsed ? <IconChevron /> : <IconChevronDown />}
      </div>
      <div className={prefix('label')} onClick={handleClick}>
        {title}
      </div>
      <div className={prefix('badge')}>{numRequests}</div>
    </div>
  );
};

export default RequestGroupHeader;
