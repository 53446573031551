import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// import { initialReportContext, ReportViewContext } from "main/factories/pages/report-view/ReportViewContext";
import { ReportView } from 'presentation/pages';
import { AppLayout } from 'presentation/components';
import useLocationRouting from 'hooks/useLocationRouting';
import { useReportsQuery } from 'hooks/reports/useReportsQuery';
import { HoneReportTimeframes, HoneReportTypes } from '../../../../constants';
import qs from 'querystring';
import { useQueryState } from 'hooks/useQueryState';
import ReportViewNoPl from 'presentation/pages/report-view/ReportViewNoPl';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useReportQueries from '../../../../hooks/useReportQueries';
import { generateDeterministicKey } from '../../../../lib/utils/generateDeterministicKey';

type ReportEntryParams = {
  reportType: string;
  reportId: string;
  locationId: string;
};

const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const ReportByIdViewFactory = () => {
  const reportQueries = useReportQueries();
  const { enableNewReportTable } = useFlags();
  const newTable = enableNewReportTable; // || true; //uncomment to enable all reports in new table

  const [allReportsSelected] = useQueryState<boolean>('allReports', false);
  //
  // const location = useLocation();
  const navigateTo = useNavigate();
  const { reportType, reportId, locationId } = useParams<ReportEntryParams>();

  const reportsMap = new Map<string, HoneReportSummary[]>();
  reportsMap.set('weekly', []);
  reportsMap.set('monthly', []);
  reportsMap.set('income-statement', []);
  reportsMap.set('pl', []);
  reportsMap.set('balance-sheet', []);
  reportsMap.set('cash-flow', []);
  reportsMap.set('ap-aging', []);

  const { data: allReports, status: reportQueryStatus } = useReportsQuery(
    reports => {
      if (reports) {
        reports.forEach(report => {
          switch (report.type) {
            case HoneReportTypes.PLComparison:
              if (report.timeframe === HoneReportTimeframes.Weekly) {
                reportsMap.set('pl', [...reportsMap.get('pl')!, report]);
                reportsMap.set('weekly', [...reportsMap.get('weekly')!, report]);
              } else if (report.timeframe === HoneReportTimeframes.Monthly) {
                reportsMap.set('pl', [...reportsMap.get('pl')!, report]);
                reportsMap.set('monthly', [...reportsMap.get('monthly')!, report]);
              }
              break;
            case HoneReportTypes.BalanceSheet:
              reportsMap.set('balance-sheet', [...reportsMap.get('balance-sheet')!, report]);
              break;
            case HoneReportTypes.CashFlow:
              reportsMap.set('cash-flow', [...reportsMap.get('cash-flow')!, report]);
              break;
            case HoneReportTypes.IncomeStatement:
              reportsMap.set('pl', [...reportsMap.get('pl')!, report]);
              reportsMap.set('income-statement', [...reportsMap.get('income-statement')!, report]);
              break;
            case HoneReportTypes.ApAging:
              reportsMap.set('ap-aging', [...reportsMap.get('ap-aging')!, report]);
              break;
          }
        });
      }

      return reports;
    },
    regex.test(reportType!) ? reportType : reportId,
    allReportsSelected
  );

  const [reportViewState, setReportViewState] = useState({});
  useLocationRouting(false);

  useEffect(() => {
    if (allReports) {
      if (reportType && regex.test(reportType)) {
        const reportId = reportType;
        const report = allReports.find(report => report.id === reportId);
        if (report) {
          let url;

          if (report.type === HoneReportTypes.PLComparison) {
            url = report.timeframe.toLowerCase();
          } else {
            url = report.type.toLowerCase().replace(' ', '-');
          }
          // Makes sure to redirect with all the query params
          const queryParams = qs.parse(location.search.replace('?', ''));
          const newQueries = { ...queryParams };
          if (!['Cash Flow', 'Balance Sheet', 'Ap Aging'].includes(report.type)) {
            newQueries.templateId = report.templateId;
          }
          navigateTo({
            pathname: '/app/location/' + locationId + '/report/' + url + '/' + report.id,
            search: `?${qs.stringify(newQueries)}`,
          });
        } else {
          navigateTo({
            pathname: '/app/location/' + locationId,
          });
        }
      }
    }
  }, [allReports, reportType]);

  const isNotPLComparison = ['cash-flow', 'balance-sheet', 'ap-aging'].includes(reportType!);
  return (
    <AppLayout>
      {!regex.test(reportType!) && !isNotPLComparison ? (
        <ReportView
          key={generateDeterministicKey(reportQueries)}
          status={reportQueryStatus}
          reports={reportsMap}
          newTable={newTable}
        />
      ) : (
        <ReportViewNoPl status={reportQueryStatus} reports={reportsMap} newTable={newTable} />
      )}
    </AppLayout>
  );
};
