import React, { createContext, useState, useContext, useMemo } from 'react';

type tooltip = {
  updating: boolean;
  tooltip: string;
};

export type filters = {
  actuals: boolean;
  money: boolean;
  percentage: boolean;
  smoothing: boolean;
  budget: boolean;
  variance: boolean;
  transactions: boolean;
  sparkLine: boolean;
  collapsed: boolean;
  anomalies: boolean;
};

const NTContext = createContext<{
  sparkLine?: 'bar' | 'line' | null;
  setSparkLine: (sparkLine: 'bar' | 'line' | null) => void;

  budgetAvailable?: boolean;
  setBudgetAvailable: (budgetAvailable: boolean) => void;

  columns: number;
  setColumns: (columns: number | null) => void;

  // table filters
  filters: filters;
  setFilters: (filters: filters) => void;

  reportId?: string;
  setReportId: (reportId: string) => void;
}>({
  sparkLine: null,
  setSparkLine: () => {},
  budgetAvailable: false,
  setBudgetAvailable: () => {},
  filters: {
    actuals: false,
    money: true,
    percentage: false,
    smoothing: false,
    budget: false,
    variance: false,
    transactions: false,
    sparkLine: false,
    collapsed: false,
    anomalies: false,
  },
  setFilters: () => {},
  columns: 0,
  setColumns: () => {},
  reportId: undefined,
  setReportId: () => {},
});

export const NTProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [sparkLine, setSparkLine] = useState<'bar' | 'line' | null>('bar');
  const [budgetAvailable, setBudgetAvailable] = useState<boolean>(false);
  const [columns, setColumns] = useState<number>(0);
  const [filters, setFilters] = useState({
    actuals: false,
    money: true,
    percentage: false,
    smoothing: false,
    budget: false,
    variance: false,
    transactions: false,
    sparkLine: false,
    collapsed: false,
    anomalies: false,
  });
  const [reportId, setReportId] = useState<string | undefined>(undefined);

  const contextValue = useMemo(
    () => ({
      sparkLine,
      setSparkLine,
      filters,
      setFilters,
      budgetAvailable,
      setBudgetAvailable,
      columns,
      setColumns: (value: number | null) => setColumns(value ?? 0),
      reportId,
      setReportId,
    }),
    [sparkLine, filters, budgetAvailable, columns, reportId]
  );
  return <NTContext.Provider value={contextValue}>{children}</NTContext.Provider>;
};
export const useNTContext = () => useContext(NTContext);
