import { processFlaggedCells } from './flaggedTrx';
import { findDataElementByTitle, logger } from './utils';
import { createTotalColumn, createChartsValues } from './utilityData';
import { Column, TransformedData } from './types';
import { UIFlaggedTransaction } from 'types';
import { Location as KsLocation } from '@hone-automation/common';
import { periodIndex } from './periods';
import { ReportConfiguration } from '@hone-automation/common/lib/Reports';
import { Option } from 'types';
import { createTransformedData } from './transformedData';
import { createAccountsColumn } from './accountsColumn';

// This is going to be a streamlined version of the NT parser
// It will be used to parse the NT report and create the intermediate data structure that will be used in all exports.

type ParseReportNTProps = {
  report: NestedHoneReport;
  reportId?: string;
  useComputedColumns?: boolean;
  periodReporting?: boolean;
  reportCFG?: ReportConfiguration;
  flaggedTransactions?: UIFlaggedTransaction[];
  currentLocation?: KsLocation;
  budget?: boolean;
  trace?: boolean;
};

export const parseReportNT = ({
  report,
  reportId,
  useComputedColumns,
  periodReporting = false,
  reportCFG,
  flaggedTransactions,
  currentLocation,
  budget = false,
  trace = true,
}: ParseReportNTProps): TransformedData | null => {
  if (trace) {
    logger.msg('reportCFG', reportCFG);
    logger.msg('report', report);
    logger.msg('flaggedTransactions', flaggedTransactions);
    // eslint-disable-next-line no-console
    console.time('parseReportNT');
  }

  if (!report || !currentLocation) {
    logger.error('(silent error) NT:parseReportNT: Missing report or currentLocation');
    return null;
  }

  if (!report.dates.length) {
    logger.error('(silent error) NT:parseReportNT: Original Report does not have dates', report);
    return null;
  }

  // Get the period titles
  const periodTitles: Option[] | null = periodReporting
    ? periodIndex({
        limit: report?.dates.length + 20,
        currentLocation,
        reportCFG,
      })
    : null;

  // Create the transformed data object
  const transformedData: TransformedData | null = createTransformedData({
    report,
    reportId,
    useComputedColumns,
    currentLocation,
    periodReporting: periodReporting && report.timeframe !== 'Weekly',
    periodTitles,
    reportCFG,
    budget,
  });

  if (!transformedData) {
    logger.error('(silent error) NT:: Failed to parse report', report);
    return null;
  }
  transformedData.flaggedCells = processFlaggedCells(report, flaggedTransactions);
  // Create the accounts column and fill in the data property of every column after we mapped budget properly

  const accounts: Column = createAccountsColumn(report, transformedData);
  // Adding the baseValues where the percentages come from to the amounts, so we can create total and difference percentages accurately
  applyBaseValues(transformedData);

  // If we pass the transactions we will process the flagged cells
  if (flaggedTransactions) transformedData.flaggedCells = processFlaggedCells(report, flaggedTransactions);

  // Adding Utility Data
  transformedData.utilityData.totalColumn = createTotalColumn(transformedData);
  transformedData.utilityData.differenceColumn = createTotalColumn(transformedData, true);

  // We add the accounts column to the beginning of the columns array, only after we have processed the rest of data transformations
  transformedData.columns.unshift(accounts);
  transformedData.utilityData.chartData = createChartsValues(transformedData);
  transformedData.renderableColumns++;

  if (trace) {
    logger.msg('transformedData', transformedData);
    // eslint-disable-next-line no-console
    console.timeEnd('parseReportNT');
  }
  return transformedData;
};

const applyBaseValues = (transformedData: TransformedData): any => {
  // Adding the baseValues where the percentages come from to the amounts, so we can create total and difference percentages accurately
  transformedData.columns
    .filter((column: Column) => !column.compoundColumn)
    .forEach((column: any, columnIndex: number) => {
      column.data.forEach((amount: any, rowId: number) => {
        const baseValue = findDataElementByTitle(column.data, amount.baseRef);
        if (baseValue) {
          amount.baseAmount = baseValue.amount;
          amount.cPercent = amount.amount / amount.baseAmount;
        } else {
          //console.log('(silent error) NT:: Failed to find baseValue for ',amount.baseRef);
        }
      });
    });
};
