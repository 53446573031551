import { AskRequest, AskRequestComment } from '@hone-automation/common/lib/AskRequests';
import { Transaction } from '@hone-automation/common/lib/Transactions';

export type SplitedRequests = {
  me: AskRequest[];
  team: AskRequest[];
};

export type FilterProps = {
  status: 'Open' | 'In Progress' | 'Closed';
  search?: string;
  sort?: 'oldest' | 'newest';
  groupBy?: 'date' | 'location';
};

const TEXT_FIELDS_TO_SEARCH = {
  cell: ['identifier', 'locationId', 'comment'],
  transaction: ['id', 'externalId', 'name', 'account', 'memo', 'externalTransactionType', 'externalAccountId'],
  comment: ['comment'],
};
const NUMERIC_FIELDS_TO_SEARCH = {
  cell: ['flaggedAmount'],
  transaction: ['amount'],
};
const SEARCH_OPERATORS = {
  GREATER_THAN: '>',
  LESS_THAN: '<',
} as const;

const searchByText = (requests: AskRequest[], search: string): AskRequest[] => {
  const matches: AskRequest[] = [];
  requests.forEach(request => {
    const foundTargets = [];
    //cell
    TEXT_FIELDS_TO_SEARCH.cell.forEach(field => {
      const requestField = request[field as keyof AskRequest] as string;
      if (requestField && requestField.toLowerCase().includes(search.toLowerCase())) {
        foundTargets.push(requestField);
      }
    });
    //transaction
    if (request.transaction) {
      TEXT_FIELDS_TO_SEARCH.transaction.forEach(field => {
        const transactionField = request.transaction![field as keyof Transaction] as string;
        if (transactionField && transactionField.toLowerCase().includes(search.toLowerCase())) {
          foundTargets.push('transaction.' + transactionField);
        }
      });
    }
    //comment
    if (request.comments && request.comments.length > 0) {
      request.comments.forEach((comment, index) => {
        TEXT_FIELDS_TO_SEARCH.comment.forEach(field => {
          const commentField = comment[field as keyof AskRequestComment] as string;
          if (commentField && commentField.toLowerCase().includes(search.toLowerCase())) {
            foundTargets.push('comment.' + index + '.' + field);
          }
        });
      });
    }
    if (foundTargets.length > 0) {
      matches.push(request);
    }
  });
  return matches;
};

const searchByAmount = (requests: AskRequest[], search: string): AskRequest[] => {
  const matches: AskRequest[] = [];

  const searchValue = Number(search.substring(1));
  const sign = search.substring(0, 1);
  if (isNaN(searchValue)) {
    return [];
  }
  requests.forEach(request => {
    const foundTargets = [];
    //cell
    NUMERIC_FIELDS_TO_SEARCH.cell.forEach(field => {
      const requestField = request[field as keyof AskRequest] as number;
      if (requestField) {
        if (sign === SEARCH_OPERATORS.GREATER_THAN && requestField > searchValue) {
          foundTargets.push(requestField);
        } else if (sign === SEARCH_OPERATORS.LESS_THAN && requestField < searchValue) {
          foundTargets.push(requestField);
        }
      }
    });
    //transaction
    if (request.transaction) {
      NUMERIC_FIELDS_TO_SEARCH.transaction.forEach(field => {
        const transactionField = request.transaction![field as keyof Transaction] as number;
        if (transactionField) {
          if (sign === SEARCH_OPERATORS.GREATER_THAN && transactionField > searchValue) {
            foundTargets.push(transactionField);
          } else if (sign === SEARCH_OPERATORS.LESS_THAN && transactionField < searchValue) {
            foundTargets.push(transactionField);
          }
        }
      });
    }
    if (foundTargets.length > 0) {
      matches.push(request);
    }
  });
  return matches;
};

const filterRequestsByStatus = (requests: AskRequest[], status: FilterProps['status']): AskRequest[] => {
  if (!requests || requests.length === 0) {
    return [];
  }
  return requests.filter(request => request.status === status);
};

const filterRequestsBySearch = (requests: AskRequest[], search: string): AskRequest[] => {
  if (!requests || requests.length === 0) {
    return [];
  }
  if (!search || search === '') {
    return requests;
  }
  if (search.indexOf(SEARCH_OPERATORS.GREATER_THAN) === 0 || search.indexOf(SEARCH_OPERATORS.LESS_THAN) === 0) {
    return searchByAmount(requests, search);
  } else {
    return searchByText(requests, search);
  }
};

const filterMeAndTeamRequests = (requests: AskRequest[], meId: string): SplitedRequests | null => {
  if (!requests || requests.length === 0) {
    return null;
  }
  const filteredRequests: SplitedRequests = { me: [], team: [] };
  requests.forEach((request: AskRequest) => {
    if (request.comments && request.comments.length > 0) {
      const lastComment = request.comments[request.comments.length - 1];
      if (lastComment.userLocation?.email === meId || lastComment.userLocation?.userId === meId) {
        filteredRequests.me.push(request);
      } else {
        filteredRequests.team.push(request);
      }
    }
  });
  return filteredRequests;
};

export const filterAndSplitRequests = (
  requests: AskRequest[],
  meId: string,
  filter: FilterProps
): SplitedRequests | null => {
  if (!requests || !requests.length || !meId) return null;
  const { status, search } = filter;
  let filteredRequests = filterRequestsByStatus(requests, status);
  filteredRequests = filterRequestsBySearch(filteredRequests, search || '');
  return filterMeAndTeamRequests(filteredRequests, meId);
};
