import './NewTable.scss';
import { useRef, useEffect, useMemo, useState } from 'react';
import { useNTContext, NTProvider } from './NTContext';
import NTProfiler from './NTProfiler';
import { NTScrollProvider, useNTScroll } from './NTScrollContext';
import { NTUtilsProvider, useNTUtilsContext } from './NTUtilsContext';
import { NTSelectionProvider } from './NTSelectionContext';
import { NTTableProvider, useNTTableContext } from './NTTableContext';
import { NewTableContent } from './NewTableContent';
import { NewTableProps } from './types';

const NewTable = ({
  report,
  reportId,
  fixFirstColumn = true,
  fixLastColumn = false,
  loading = false,
  redraw = false,
  reportType = 'report',
}: NewTableProps) => {
  const MemoizedNewTableContent = useMemo(
    () => (
      <NewTableContent
        report={report}
        reportId={reportId}
        fixFirstColumn={fixFirstColumn}
        fixLastColumn={fixLastColumn}
        loading={loading}
        redraw={redraw}
        reportType={reportType}
      />
    ),
    [report?.createdAt, fixFirstColumn, fixLastColumn, loading, redraw, reportType]
  );
  return (
    <NTProvider>
      <NTProfiler>
        <NTScrollProvider>
          <NTUtilsProvider>
            <NTSelectionProvider>
              <NTTableProvider>{MemoizedNewTableContent}</NTTableProvider>
            </NTSelectionProvider>
          </NTUtilsProvider>
        </NTScrollProvider>
      </NTProfiler>
    </NTProvider>
  );
};

export default NewTable;
