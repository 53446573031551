import { useQuery } from '@tanstack/react-query';
import { AskRequest } from '@hone-automation/common';
import { makeRemoteGetLinks } from 'main/factories/usecases/remote-get-links';

// Define a type for the locationId
type LocationId = string;

// Custom hook to fetch links based on transaction details
const useFetchLinks = (transaction: AskRequest, locationId?: LocationId) => {
  const fromId = transaction.id;
  const fromType = transaction.type === 'Flagged Cell' || !!transaction.period ? 'Ask Request' : 'Flagged Transaction';
  const linksEnabled = transaction.type !== 'Customer Request';

  return useQuery({
    queryKey: ['links', { locationId, fromId, fromType }],
    queryFn: async ({ queryKey }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const [, { locationId, fromId, fromType }] = queryKey;

      return await makeRemoteGetLinks().get({ locationId, fromId, fromType, resolve: true, limit: 1 });
    },
    enabled: !!locationId && linksEnabled,
  });
};

export default useFetchLinks;
