import { detectAnomaly } from './detectAnomalies';
import { Amounts, TransformedData, VizHelpers, Anomaly, FlaggedCellProps } from './types';
import { series } from './utils';

type ParseDataRowsProps = {
  section: any;
  transformedData: TransformedData;
  filteredId: string;
  path: string[];
  sectionIndex: number;
  title: string;
  refTitle: string;
  vizHelpers: VizHelpers;
};

export const parseDataRows = (dataRowsObject: ParseDataRowsProps) => {
  const { section, transformedData, filteredId, path, sectionIndex, title, vizHelpers, refTitle } = dataRowsObject;
  const amounts: Amounts[] = [];

  // We look for anomalies in the data, both in amounts and transactions
  const anomaly = detectAnomaly(series(section.data, 'amount'));
  const trxAnomaly = detectAnomaly(series(section.data, 'dataRows'));
  section.data.forEach((data: any, index: number) => {
    const accountId = `${filteredId}_@_${sectionIndex}`;
    const trxFlagged = transformedData.flaggedCells?.filter((trx: FlaggedCellProps) => {
      // If the cell has transactions we check the flagged elements vs transaction values (type = undefined)
      const hasMatchingTransactions = data.dataRows?.some(
        (dR: any) =>
          trx.transaction?.trxType === dR.type &&
          trx.period === dR.txnDate &&
          trx.amount === dR.amount &&
          trx.transaction?.memo === dR.description
      );
      // Otherwise we check the flagged elements vs the cell itself (type = Flagged Cell)
      const isCellFlagged =
        trx.account === section.title &&
        transformedData.columns[index] &&
        transformedData.columns[index].date?.start === trx.period;

      return hasMatchingTransactions || isCellFlagged;
    });
    //if (trxFlagged && trxFlagged.length>0) console.log('trxFlagged', section.title, trxFlagged);
    const amount = getDataAmountsColumn({
      data,
      date: (transformedData.columns[index] && transformedData.columns[index].date?.start) || '',
      reportId: transformedData.columns[index]?.reportId,
      title,
      refTitle,
      path,
      baseRef: section.titlePerc !== '' ? section.titlePerc : undefined,
      index,
      accountId,
      vizHelpers: vizHelpers,
      anomaly,
      trxAnomaly,
      trxFlagged,
    });
    amounts.push(amount);
  });

  return { anomaly, amounts };
};

const getDataAmountsColumn = ({
  data,
  accountId,
  index,
  reportId,
  ...rest
}: {
  data: any;
  reportId?: string;
  accountId: string;
  index: number;
  date: string;
  title: string;
  refTitle: string;
  path: string[] | undefined;
  baseRef: string;
  vizHelpers: VizHelpers;
  anomaly: Anomaly;
  trxAnomaly: Anomaly;
  trxFlagged?: FlaggedCellProps[];
}): Amounts => {
  return {
    ...rest,
    ...data,
    reportId,
    amountAVG: data.amtAvg,
    percent: data.perc,
    percentAVG: data.percAvg,
    cellID: `${accountId}_-x-_${index + 1}`,
    baseAmount: data.percent ? data.amount / data.perc : data.amount,
    transactions: data.dataRows.length || 0,
    id: accountId,
    refTitle: data.refTitle,
    index,
  };
};
