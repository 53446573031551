import { prefixClass } from 'lib/utils';
import './RBFilter.scss';
import { FilterProps } from '../../utils/filterAndSearchUtils';
import classNames from 'classnames';
import CustomDropDown from '../../common/CustomDropDown';

type RBFilterProps = {
  filter: FilterProps;
  onFilterChange: (filter: FilterProps) => void;
};

const RBFilter = ({ filter, onFilterChange }: RBFilterProps) => {
  const prefix = prefixClass('rb-filter');
  return (
    <div className={prefix()}>
      {/*To be enabled later when we have grouping from the backend*/}
      {/* <CustomDropDown
        label="group by"
        data={[
          { label: 'Creation Date', value: 'date' },
          { label: 'Location', value: 'location' },
        ]}
        selected={'date'}
        // @ts-ignore
        onChanged={selection => onFilterChange({ ...filter, groupBy: selection || filter.groupBy })}
      /> */}
      <CustomDropDown
        label="sort"
        data={[
          { label: 'Oldest First', value: 'oldest' },
          { label: 'Newest First', value: 'newest' },
        ]}
        selected={'oldest'}
        // @ts-ignore
        onChanged={selection => onFilterChange({ ...filter, sort: selection || filter.sort })}
      />
    </div>
  );
};
export default RBFilter;
