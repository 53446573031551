import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import qs, { ParsedQs } from 'qs';

/**
 * A custom React hook for reading and updating a specific query parameter in the URL.
 * @param {string} query - The name of the query parameter to read or update.
 * @param {string | qs.ParsedQs | string[] | qs.ParsedQs[] | boolean | undefined} [defaultValue] - The default value for the query parameter if it's not present in the URL.
 * @returns {[string | qs.ParsedQs | string[] | qs.ParsedQs[] | boolean | undefined, (value: string) => void]} - An array containing the current value of the specified query parameter and a function to update it.
 */
export function useQueryState<T = string | qs.ParsedQs | string[] | qs.ParsedQs[] | boolean | undefined>(
  query: string,
  defaultValue: string | qs.ParsedQs | string[] | qs.ParsedQs[] | boolean | undefined = undefined
): [T, (value: string | undefined) => void] {
  const location = useLocation();
  const navigateTo = useNavigate();
  /**
   * A memoized callback function for updating the specified query parameter in the URL.
   * @param {string} value - The new value for the query parameter.
   */
  const setQuery = useCallback(
    (value: any) => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      const queryString = qs.stringify({ ...existingQueries, [query]: value }, { skipNulls: true });

      navigateTo(`${location.pathname}?${queryString}`);
    },
    [history, location, query]
  );

  let queryValue: string | boolean | string[] | ParsedQs | ParsedQs[] | (string | ParsedQs)[] | undefined = qs.parse(
    location.search,
    {
      ignoreQueryPrefix: true,
    }
  )[query];

  if (defaultValue !== undefined && defaultValue !== null) {
    queryValue = queryValue === undefined ? defaultValue : queryValue;
  }

  return [queryValue as unknown as T, setQuery];
}
