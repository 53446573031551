import { AskRequest } from '@hone-automation/common/lib/AskRequests';
import './RequestsBrowser.scss';
import { prefixClass } from 'lib/utils';
import AssignmentGroup from '../AssignmentGroup';
import { filterAndSplitRequests, FilterProps } from '../utils/filterAndSearchUtils';
import { useEffect, useMemo, useState } from 'react';
import RBSearch from './RBSearch';
import RBFilter from './RBFilter';

type RequestBrowserProps = {
  requests: AskRequest[];
  filter: FilterProps;
  meId: string;
  onClick: (id: string) => void;
};

const RequestsBrowser = ({ requests, filter, meId, onClick }: RequestBrowserProps) => {
  if (!requests) {
    return null;
  }
  if (!Array.isArray(requests)) {
    throw new Error('RequestsBrowser: requests prop must be an array');
  }

  const [searchTerm, setSearchTerm] = useState(filter.search || '');
  const [updatedFilters, setUpdatedFilters] = useState(filter);
  const prefix = prefixClass('requests-browser');

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };
  const handleClear = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    setUpdatedFilters({ ...updatedFilters, search: searchTerm });
  }, [searchTerm]);

  useEffect(() => {
    setUpdatedFilters({ ...updatedFilters, status: filter.status });
  }, [filter.status]);

  const updateFilters = (newFilter: FilterProps) => {
    setUpdatedFilters(newFilter);
  };

  const splitedRequests = useMemo(
    () => filterAndSplitRequests(requests, meId, updatedFilters),
    [requests, meId, updatedFilters.search, updatedFilters.status, updatedFilters.sort, updatedFilters.groupBy]
  );
  return (
    <div className={prefix()}>
      <RBSearch searchValue={searchTerm} onSearch={handleSearch} onClear={handleClear} />
      <RBFilter filter={updatedFilters} onFilterChange={updateFilters} />
      <div className={prefix('results')}>
        <AssignmentGroup
          requests={splitedRequests?.me || []}
          me={true}
          reversed={updatedFilters.sort === 'newest'}
          onClick={onClick}
        />
        <AssignmentGroup
          requests={splitedRequests?.team || []}
          me={false}
          reversed={updatedFilters.sort === 'newest'}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default RequestsBrowser;
