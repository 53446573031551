import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Option } from '../../../../types';
import { formatShortDate } from '../../../../lib/reportUtils';
import { ReportTimeframe } from '@hone-automation/common';
import KSDropdown from '../../KSDropdown';
import { IconRange } from '../../../../components/Icons';
import ReportPLDropdownReports, { ReportPLDropdownReportsProps } from '../ReportPLDropdownReports';

interface ReportPLSelectorsProps extends ReportPLDropdownReportsProps {
  timeframe: ReportTimeframe;
  startReportOptionsAvailable: { value: string; label: string | undefined }[];
  endReportOptionsAvailable: { value: string; label: string | undefined }[];
}

function ReportPlSelectors({
  timeframe,
  optionsAvailable,
  startReportOptionsAvailable,
  endReportOptionsAvailable,
  ...rest
}: ReportPLSelectorsProps) {
  const { watch, reset } = useFormContext();

  const isCustomDateRange = watch('utility.isCustomDateRange');

  const period = useMemo(() => {
    switch (timeframe) {
      case 'Week':
        return 'Week';
      case 'Month':
        return 'Month';
      default:
        return 'Year';
    }
  }, [timeframe]);

  const formatLabel = ['Week', 'Month'].includes(timeframe);

  const handleStartWeekChange = (periodOption: Option) => {
    const startDateString = periodOption.value;
    const endDateString = optionsAvailable
      .find(option => option.value.split(' - ')[0] === startDateString)
      ?.value.split(' - ')[1];

    reset({
      ...watch(),
      startDate: startDateString,
      endDate: endDateString ? endDateString : '',
    });
  };

  const handleEndWeekChange = (periodOption: Option) => {
    const endDateString = periodOption.value;
    reset({
      ...watch(),
      endDate: endDateString,
    });
  };

  if (isCustomDateRange) {
    return (
      <>
        {startReportOptionsAvailable && startReportOptionsAvailable.length > 0 && (
          <KSDropdown
            options={startReportOptionsAvailable as Option[]}
            value={{
              value: `${watch('startDate')}`,
              label: `${formatLabel ? formatShortDate(watch('startDate')) : watch('startDate')}`,
            }}
            label="Start Date"
            onSelect={handleStartWeekChange}
            period={period}
            enableMinWidth={true}
          />
        )}
        <div className="modal-time-frame-content-icon-container">
          <IconRange />
        </div>
        {endReportOptionsAvailable && endReportOptionsAvailable.length > 0 && (
          <KSDropdown
            options={endReportOptionsAvailable as Option[]}
            value={{
              value: `${watch('endDate')}`,
              label: `${formatLabel ? formatShortDate(watch('endDate')) : watch('endDate')}`,
            }}
            label="End date"
            onSelect={handleEndWeekChange}
            period={period}
            disabled={!watch('startDate')}
            enableMinWidth={true}
          />
        )}
      </>
    );
  }

  return <ReportPLDropdownReports optionsAvailable={optionsAvailable} {...rest} />;
}

export default ReportPlSelectors;
