import { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './App.css';
import Loading from 'components/Loading';
import HoneForecastsProvider from 'components/HoneForecasts';
import HoneLocationUsersProvider from 'components/HoneLocationUsers';
import AppRoutes from 'main/routes/router';
import HoneReportTemplatesProvider from 'components/HoneReportTemplates';
import { init } from '@fullstory/browser';
import './styles/main.scss';
import { useLocationsStore } from 'hooks/useLocationsStore';
import { QueryClientProvider } from '@tanstack/react-query';
import UpdateVersionModal from 'components/UpdateVersionModal';

import { HoverProvider } from 'hooks/useHighlightReportSelector';
import { useAuthContext } from 'context/useAuthContext';

import { queryClient } from 'lib/queryClient';
import RecentLocationsProvider from './hooks/useRecentLocations';

const isDevelopment = process.env.NODE_ENV === 'development';
const isTest = process.env.NODE_ENV === 'test';

let useEmulator = isDevelopment && window.localStorage.getItem('hone:useEmulator') === 'true';

if (!isTest) {
  init({ orgId: `${process.env.VITE_FULLSTORY_ORG}` });
}

if (isDevelopment) {
  // enable/disable emulator at boot from query param
  const params = new URLSearchParams(window.location.search);
  const emulatorParam = params.get('useEmulator');

  if (emulatorParam) {
    if (emulatorParam === 'false') {
      useEmulator = false;
    }
    if (emulatorParam === 'true') {
      useEmulator = true;
    }

    window.localStorage.setItem('hone:useEmulator', useEmulator.toString());
  }
}

export function AppWrapper() {
  return (
    <Suspense fallback={<Loading />}>
      <Router basename={process.env.PUBLIC_URL}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Router>
    </Suspense>
  );
}

export function App() {
  const { session, userId } = useAuthContext();
  const location = useLocation();

  const pathSegments = location.pathname.split('/');
  const currentLocationId = pathSegments[3]; // Adjust the index based on the path structure

  const { fetchAllLocations, fetchLocationById } = useLocationsStore();

  useEffect(() => {
    if (userId && session) {
      fetchAllLocations(userId);
    }
  }, [session, userId]);

  useEffect(() => {
    if (currentLocationId) {
      fetchLocationById(currentLocationId);
    }
  }, [currentLocationId]);

  return (
    <QueryClientProvider client={queryClient}>
      <UpdateVersionModal />
      <div className="App" id="App">
        <HoneLocationUsersProvider>
          <HoneForecastsProvider>
            <HoneReportTemplatesProvider>
              <RecentLocationsProvider>
                <HoverProvider>
                  <AppRoutes />
                </HoverProvider>
              </RecentLocationsProvider>
            </HoneReportTemplatesProvider>
          </HoneForecastsProvider>
        </HoneLocationUsersProvider>

        <Toaster />
      </div>
    </QueryClientProvider>
  );
}
