import { Suspense, useEffect, useState, useMemo, useCallback, SyntheticEvent, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { debounce } from 'lodash';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { deleteReport, getAuditReport, useReportsStore } from 'hooks/useReportsStore';

import { isEmpty, uniqBy } from 'lodash';
import Loading from 'components/Loading';
import ReportDataTable from './components/ReportDataTable';
import { HoneReportTypes, MENU_ICONS } from '../../../constants';
import { trackError } from 'lib/analytics';
import {
  abilityCan,
  BALANCE_SHEET_PRESETS,
  currencyFormatter2Decimals,
  dismissToast,
  FIVE_SECONDS,
  percentageFormatter,
  reportTitleToUrl,
  showToast,
  sortReportTitles,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from 'lib/utils';

import ReportGraph from './components/ReportGraph/ReportGraph';

import toast from 'react-hot-toast';
import { HoneReportTimeframes } from 'domain/models';
import { formatDate } from 'lib/utils';
import { performReportUpdate, useActiveReportStore } from 'hooks/useActiveReportStore';
import { shallow } from 'zustand/shallow';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocationsStore } from 'hooks/useLocationsStore';

import { useQueryState } from 'hooks/useQueryState';
import { useReportGraphStore } from 'hooks/useReportGraphStore';

import { QueryStatus } from '@tanstack/react-query';
import qs from 'querystring';
import { useHighlightReportSelector } from 'hooks/useHighlightReportSelector';

import classNames from 'classnames';
import ModalCustomDateRange from './ModalCustomDateRange';
import {
  commonUtil,
  ComparisonRequest,
  ComparisonType,
  extractReportIds,
  GenerateRangesRequest,
  HoneAbilityActions,
  HoneAbilitySubjects,
  ReportColumnTypes,
  ReportConfiguration,
  ReportRequest,
  ReportTimeframe,
  ReportTimeframes,
  StartEndDates,
} from '@hone-automation/common';
import { useHoneLocationUsers } from '../../../components/HoneLocationUsers';
import ReportPLDatesModal from 'presentation/components/ReportPLDatesModal';

import { useUserLocationsQuery } from '../../../hooks/useUserLocationsQuery';

import KSHeader from '../../components/KSHeader';
import { KSCompareLocationsModal } from '../../components/KSCompareLocationsModal';
import { generateRanges } from '@hone-automation/common/lib/reportUtil';

import STBTools from 'presentation/components/SectionToolbar/STBTools';

import { SlidingPane } from 'components/SlidingPane';
import ValidationReportResults from 'components/ValidationReportResults';
import { differenceInSeconds, parseJSON } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';

import { getInitialReportDateRanges, getPrintedHeaderDate } from 'lib/reportUtils';

import { Report } from 'lib/honeTableUtils';
import useActiveActions from 'hooks/useActiveActions';
import NewTable from 'presentation/components/NewTable';
import { useTemplatesRangesQuery } from '../../../hooks/reports/useTemplatesRangesQuery';

import ReportPLDates from '../../components/ReportPLDatesModal/ReportPLDates';
import { useGetReportConfiguration } from '../../../hooks';
import { getHeaderFormattedText } from 'presentation/utils';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div role="alert">
      <p>This report is being updated, please try again in a few minutes.</p>
      <pre style={{ display: process.env.NODE_ENV === 'development' ? 'block' : 'none' }}>{error.message}</pre>
    </div>
  );
}

interface Props {
  reports: Map<string, HoneReportSummary[]>;
  columnHeaderTitle?: string;
  customHeaders?: string[];
  status: QueryStatus;
  newTable?: boolean;
}

function ReportView({ reports, columnHeaderTitle = '', customHeaders, status, newTable }: Props): JSX.Element {
  const { data: availableTemplates } = useTemplatesRangesQuery();
  const { currentLocationAbilities } = useHoneLocationUsers();

  const { isHovered } = useHighlightReportSelector();

  const [allReportsSelected, setAllReportsSelected] = useQueryState<boolean>('allReports', false);
  const { enableNewReportTable, enablePlModalV2 } = useFlags();

  const { currentLocation } = useLocationsStore(
    state => ({ currentLocationId: state.currentLocationId, currentLocation: state.currentLocation }),
    shallow
  );

  const isBookkeeper = abilityCan(
    currentLocationAbilities,
    HoneAbilityActions.read,
    HoneAbilitySubjects.Bookkeeper_Controls
  );

  const [auditMode, setAuditMode] = useQueryState('audit');
  const [consolidated] = useQueryState<string>('consolidated');
  const [validation] = useQueryState('validate');
  const [total] = useQueryState('total');
  const [difference] = useQueryState('difference');
  const { activeActions } = useActiveActions();
  const showPercentageValues = activeActions.includes('lines');
  const showMoneyValues = activeActions.includes('bars');
  const [timeframe] = useQueryState<string>('timeframe');

  const { currentLocationId } = useLocationsStore(state => ({ currentLocationId: state.currentLocationId }), shallow);
  const { data: reportConfiguration } = useGetReportConfiguration(currentLocationId);

  const { data: userLocations } = useUserLocationsQuery();

  const currentLocationUser = (userLocations as HoneLocationUser[])?.find(loc => loc.locationId === currentLocationId);

  const location = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { locationId, reportId, reportType } = useParams<{
    locationId: string;
    reportId: string;
    reportType: string;
  }>();

  const navigateTo = useNavigate();
  const isPLReport = ['income-statement', 'monthly', 'weekly'].includes(reportType!);

  const [chartCollapsed, setChartCollapsed] = useQueryState('chartCollapsed', 'false');
  const validationReportData = useActiveReportStore(state => state.currentReport?.validation);

  const [showValidationPane, setShowValidationPane] = useState<boolean>(false);

  const [validateReportError] = useState<string | null>(null);
  const trace = false;
  const [loading, setLoading] = useState<boolean>(true);
  const [redraw, setRedraw] = useState<boolean>(true);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [paramList, setParamList] = useState<any>(Object.fromEntries(urlParams.entries()));

  useEffect(() => {
    if (validation) {
      setShowValidationPane(true);
    }
  }, [validation]);

  useEffect(() => {
    if (isPLReport) {
      setChartCollapsed('true');
    }
  }, [reportType, isPLReport]);

  const [dateRange, setDateRange] = useQueryState<string>('dateRange');

  const reportsError = useReportsStore(state => state.error);
  const { setOpenDateRangeModal, openDateRangeModal, reportsConfiguration } = useReportsStore(
    state => ({
      setOpenDateRangeModal: state.setOpenDateRangeModal,
      openDateRangeModal: state.openDateRangeModal,
      reportsConfiguration: state.reportsConfiguration,
    }),
    shallow
  );

  const {
    yearPlotted,
    reportStatus,
    allCategories,
    getGroupReport,
    setYearPlotted,
    setRefreshingReport,
    rangeNoData,
    setStatus,
    currentReport,
    enableMultiLocation,
    setEnableMultiLocation,
    setReportPayload,
  } = useActiveReportStore(
    state => ({
      yearPlotted: state.yearPlotted,
      setStatus: state.setStatus,
      rangeNoData: state.rangeNoData,
      reportStatus: state.status,
      allCategories: state.allCategories,
      enableMultiLocation: state.enableMultiLocation,
      setRefreshingReport: state.setRefreshingReport,
      getGroupReport: state.getGroupReport,
      setYearPlotted: state.setYearPlotted,
      setEnableMultiLocation: state.setEnableMultiLocation,
      currentReport: state.currentReport,
      setReportPayload: state.setReportPayload,
    }),
    shallow
  );

  const selectedReport = currentReport as unknown as HoneReportSummary;
  const isYtd = selectedReport?.timeframe === HoneReportTimeframes.YTDMonthly;

  const [statusUpdates] = useState<Record<string, string>>({});

  const allReports = reports.get(isPLReport ? 'pl' : reportType!) || [];
  const titles = uniqBy(allReports, 'title');

  const uniqueTitles = sortReportTitles(titles);

  const [titleFilter] = useQueryState<string>('type', reportTitleToUrl(uniqueTitles[0]?.title || ''));
  const [templateId] = useQueryState<string>('templateId');

  useEffect(() => {
    if (availableTemplates && availableTemplates?.length === 0) {
      toast.error('There are not any templates available at this location');
      navigateTo(`/app/location/${locationId}/dashboard`);
      return;
    }
    if (templateId) return;
    const currentTemplateId = availableTemplates?.[0].id;
    if (!templateId && currentTemplateId) {
      urlParams.set('templateId', currentTemplateId!);
      urlParams.set('chartCollapsed', 'true');
      urlParams.set('type', 'P&L Comparison');
      urlParams.set('difference', 'false');
      urlParams.set('reverse', 'false');
      urlParams.set('budgetInclude', 'false');
      urlParams.set('customDateRange', 'false');
      urlParams.set('breakdownPeriods', 'false');
      urlParams.set('timeframe', 'Week');
      urlParams.set('comparisonType', 'Prior Period');
      urlParams.set('comparisonPeriods', '4');
      urlParams.set('compareEnabled', 'true');

      navigateTo(`/app/location/${locationId}/report/${reportType}?${urlParams.toString()}`);
    }
  }, [locationId, templateId, availableTemplates]);

  const filteredTitleFilter = templateId
    ? reportTitleToUrl(uniqueTitles.find(props => props.templateId === templateId)?.title || '')
    : titleFilter.split('&')[0];

  let filteredReports = allReports;
  if (isPLReport && titleFilter) {
    filteredReports = filteredReports.filter(({ title, timeframe }) => {
      if ((timeframe.includes('YTD') && reportType === 'income-statement') || timeframe.toLowerCase() === reportType) {
        return reportTitleToUrl(title) === filteredTitleFilter;
      }
      return false;
    });
  }

  useEffect(() => {
    rangeNoData && navigateTo(`/app/location/${currentLocationId}/report/${reportType}`, { replace: true });
  }, [rangeNoData]);

  useEffect(() => {
    if (!isYtd) return;
    // REVIEW: old report from 2020 on dev for Allegria does not have startDateText making the UI crash
    if (!selectedReport?.startDateText) return;
    const yearToPlot = Number(selectedReport.startDateText.split('-')[0]);
    setYearPlotted(yearToPlot);
  }, [selectedReport]);

  const totalParsed = urlParams.get('total');
  const differenceParsed = urlParams.get('difference');
  const reverseParsed = urlParams.get('reverse');
  const breakdownPeriodsParsed = urlParams.get('breakdownPeriods');
  const comparisonType = urlParams.get('comparisonType');
  const comparisonPeriods = urlParams.get('comparisonPeriods');
  const compareLocations = urlParams.get('compareLocations');
  const budgetInclude = urlParams.get('budgetInclude');
  const validateParsed = urlParams.get('validate');

  const generateReportPayload = ({
    currentLocation,
    dateRange,
    comparisonPeriods,
    compareLocations,
    availableTemplates,
  }: any) => {
    if (!currentLocation) return;

    const type: 'P&L Comparison' | 'Balance Sheet' = 'P&L Comparison';
    const [startDate, endDate] = urlParams.get('dateRange')?.split(',') || [];
    const limit = undefined;

    const comparison: ComparisonRequest = {
      comparisonType: comparisonType as ComparisonType,
      comparisonPeriods: Number(comparisonPeriods),
    };

    const now = commonUtil.toShortString(new Date());
    const rangeEnd = availableTemplates?.length ? availableTemplates?.[0].range.end : now;

    const request = Object.assign(
      new GenerateRangesRequest(ReportTimeframes.Week, currentLocation?.weekStart, reportsConfiguration?.data),
      {
        limit: 2,
        end: rangeEnd > now ? now : rangeEnd,
      }
    );
    const newDateRanges: StartEndDates[] = generateRanges(request);

    const locations = compareLocations
      ? compareLocations?.split(',').map((location: string) => location)
      : [String(locationId)];

    const dateParsed = getInitialReportDateRanges(newDateRanges);

    const reportPayload: ReportRequest = {
      type,
      locations,
      templateId: templateId,
      startDate: dateRange ? startDate : dateParsed?.start,
      endDate: dateRange ? endDate : dateParsed?.end,
      total: totalParsed === 'true',
      difference: differenceParsed === 'true',
      limit,
    };

    if (comparisonType) {
      reportPayload.comparison = comparison;
    }

    setReportPayload(reportPayload);

    return reportPayload;
  };

  const isAggrSideBySide =
    compareLocations && (!consolidated || consolidated === 'false') ? compareLocations?.split(',').length > 1 : false;

  const handleValidateReport = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const validate = searchParams.get('validate');
    if (validate) {
      setShowValidationPane(true);
      return;
    }
    searchParams.set('validate', 'true');
    const newSearch = searchParams.toString();

    navigateTo({
      search: newSearch,
    });
  };

  const toggleAuditMode = () => {
    const nextState = auditMode === 'true' ? 'false' : 'true';
    setAuditMode(nextState);
    return nextState;
  };

  const handleAuditReport = async (toggle = true) => {
    let next: 'true' | 'false' = 'false';
    if (toggle) {
      next = toggleAuditMode();
    }
    if (!toggle || next === 'true') {
      await getAuditReport(selectedReport?.id as string);
    } else {
      useReportsStore.setState({ auditReport: null });
    }
  };

  useEffect(() => {
    if (auditMode === 'true') {
      handleAuditReport(false);
    }
  }, []);

  const handleRefreshReport = async (e: SyntheticEvent<HTMLButtonElement>) => {
    try {
      const target = e.currentTarget;
      // allow only every two minutes
      const selectedReportId = selectedReport?.id;
      const updatedAt = currentReport?.updatedAt ? currentReport?.updatedAt : selectedReport?.updatedAt;
      const difference = updatedAt ? differenceInSeconds(new Date(), parseJSON(updatedAt)) : 0;

      const refreshTimeAllowed = 60 * 5;
      if (difference < refreshTimeAllowed) {
        showToast(`Report can be refreshed again in ${refreshTimeAllowed - difference} seconds`, TOAST_ERROR);
        return;
      }

      if (target) {
        target.disabled = true;
      }

      setRefreshingReport(true);
      const periodStartEndDates = currentReport?.dates;
      const extractedReportIds = extractReportIds(periodStartEndDates!);

      const reportIds = extractedReportIds;
      await performReportUpdate(selectedReport!, reportIds);

      if (target) {
        target.disabled = false;
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error('Error refreshing report');
    } finally {
      setRefreshingReport(false);
    }
  };

  const handleDeleteReport = async () => {
    const selectedReportId = selectedReport?.id;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3 className="text-center">Are you sure you want to delete this report?</h3>
            <button className="button ReportControls-button mr-2" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button ReportControls-button-alert"
              onClick={() => {
                onClose();
                const toastId = showToast(
                  'Deleting report, will take a moment to complete',
                  TOAST_DEFAULT,
                  FIVE_SECONDS
                );
                deleteReport(selectedReportId as string)
                  .then(() => {
                    dismissToast(toastId);
                    showToast('Report deleted', TOAST_SUCCESS, FIVE_SECONDS);
                    setTimeout(() => {
                      navigateTo(`/app/location/${locationId}`, { replace: true });
                    }, 2000);
                  })
                  .catch((error: any) => {
                    trackError({ error: error as Error });
                  });
              }}
            >
              Delete Report
            </button>
          </div>
        );
      },
    });
  };

  const reportDates = useMemo(() => {
    if (!currentReport) {
      return;
    }
    if (!dateRange) {
      return currentReport && currentReport.dates;
    }
    if (total || difference) {
      return (currentReport as any)?.dates;
    }
    const tempReportDates = [...currentReport!.dates];
    tempReportDates.pop();
    return tempReportDates;
  }, [currentReport, new Date()]);

  const reportDatesHeaders = useMemo(() => {
    if (!reportDates || !currentReport) return;
    const reportDatesHeaderFormatted = reportDates.map((date: any) => {
      const hasSubheader =
        (date.type && date.type === ReportColumnTypes.Data) || date.type === ReportColumnTypes.Budget;

      const subheaderLabel = hasSubheader && date.type === ReportColumnTypes.Data ? 'ACTUAL' : 'BUDGET';

      const isAggrSideBySideAndEnabled = isAggrSideBySide && enableMultiLocation;

      const headerFormatted = getHeaderFormattedText(
        date,
        currentReport,
        yearPlotted,
        isAggrSideBySide && isAggrSideBySideAndEnabled
      );

      const getPrintedDate = getPrintedHeaderDate(headerFormatted, null, false);

      return (getPrintedDate as string).concat(hasSubheader ? ` ${subheaderLabel}` : '');
    });
    return reportDatesHeaderFormatted;
  }, [reportDates, currentReport]);

  const getReportChildren = (reports: NestedHoneReportSection[], flatArray: Report[] = []): Report[] => {
    for (const report of reports) {
      const maskedTitle = Array(report.level).fill(' * ').join('') + report.title;
      const maskedPercentageTitle = Array(report.level).fill(' * ').join('') + '% OF ' + report.title;

      flatArray.push({
        ...report,
        ...report.data?.map(data => (showMoneyValues ? currencyFormatter2Decimals(data?.amount) : '')),
        title: maskedTitle,
      });

      if (report.title !== '' && showPercentageValues) {
        flatArray.push({
          ...report,
          ...report.data?.map(data => percentageFormatter(data?.perc || 0)),
          title: maskedPercentageTitle,
        });
      }

      if (report.children) {
        getReportChildren(report.children, flatArray);
      }
    }
    return flatArray;
  };

  useEffect(() => {
    if (isAggrSideBySide) {
      setEnableMultiLocation(true);
      return;
    }
    setEnableMultiLocation(false);
  }, [isAggrSideBySide]);

  useEffect(() => {
    if (!availableTemplates) return;
    const reportPayload = generateReportPayload({
      currentLocation,
      dateRange,
      comparisonPeriods,
      compareLocations,
      availableTemplates,
    });

    if (!reportPayload || !templateId) return;

    const fetchReport = async () => {
      const response = getGroupReport(reportPayload);

      if (validateParsed) {
        await toast.promise(
          // @ts-ignore
          response,
          {
            loading: 'Validating report, will take a few seconds...',
            success: 'Report successfully validated',
            error: 'Error validating report',
          },
          {
            style: { maxWidth: 'max(50vw, 350px)' },
          }
        );
      }
    };

    // Debounce the fetchReport to avoid multiple rapid requests
    const debouncedFetch = debounce(fetchReport, 300); // 300ms delay

    debouncedFetch();

    // Cleanup debounced function on unmount
    return () => debouncedFetch.cancel();
  }, [
    compareLocations,
    dateRange,
    totalParsed,
    differenceParsed,
    reverseParsed,
    breakdownPeriodsParsed,
    comparisonType,
    comparisonPeriods,
    budgetInclude,
    validateParsed,
    enableMultiLocation,
    consolidated,
    availableTemplates,
  ]);

  const years = useMemo(
    () =>
      allReports.map(report => ({
        period: new Date(report.endDate).getFullYear(),
        reportId: report.id,
      })),
    [allReports]
  );

  const handleReportChange = (newReportId: string) => {
    if (newReportId === 'custom_range') {
      setOpenDateRangeModal(true);
      return;
    }

    setStatus('loading');
    setDateRange(undefined);

    const yearPlotted = years.find(year => year.reportId === newReportId);
    yearPlotted?.period && setYearPlotted(yearPlotted?.period);

    const { dateRange, total, difference, ...queryParams } = qs.parse(location.search.replace('?', ''));
    const newQueries = { ...queryParams };

    if (templateId) {
      newQueries.templateId = templateId;
    }
    if (validateParsed) {
      delete newQueries.validate;
    }
    useReportsStore.setState({ selectedReport: undefined });
    useActiveReportStore.setState({ currentReport: undefined });
    navigateTo({
      pathname: `/app/location/${currentLocationId}/report/${reportType}/${newReportId}`,
      search: qs.stringify(newQueries),
    });
  };

  // Disable smoothing for Balance Sheet and Cash Flow
  useEffect(() => {
    if (selectedReport && selectedReport?.type === HoneReportTypes.IncomeStatement)
      useReportGraphStore.setState({ extraReportId: undefined });
    if (
      selectedReport &&
      (selectedReport.type === HoneReportTypes.BalanceSheet || selectedReport.type === HoneReportTypes.CashFlow)
    ) {
      useActiveReportStore.setState({ smoothingEnabled: false });
    }
  }, [selectedReport]);

  const hasCharts =
    !isEmpty(allCategories) && selectedReport && ['P&L Comparison', 'Income Statement'].includes(selectedReport?.type);

  const showCharts = chartCollapsed === 'false' && auditMode !== 'true' && hasCharts && reportType !== 'ap-aging';

  const summaryReport = useReportGraphStore(state => state.summaryReport);

  const graphWidth = useMemo(() => {
    if (isYtd) {
      return 1200;
    }

    return 60 + 175 * ((summaryReport?.reportLabels || []).length ?? 3);
  }, [isYtd, selectedReport?.id, summaryReport?.reportLabels]);

  const getSelectedReportId = useCallback(() => {
    if (reportId) return reportId.includes('?') ? reportId.split('?')[0] : reportId;
    if (dateRange) return 'custom_range';

    if (status === 'pending') return 'loading';

    const index = filteredReports.findIndex(report => report.id === selectedReport?.id);
    if (index > -1) {
      return filteredReports[index].id;
    }
    return filteredReports[0]?.id;
  }, [selectedReport]);

  if (reportsError) {
    return (
      <div className="report-view add-padding">
        <div className="report-view--header">
          <div />
          <div className="report-view--header__location"></div>
        </div>
        <div className="report-view-container h-100 center">{reportsError}</div>
      </div>
    );
  }

  // Small utility function that returns the difference betwen 2 objects
  // if objects are equal it returns an empty object {}
  const getObjectDiff = (
    obj1: Record<string, any>,
    obj2: Record<string, any>,
    onlyCommon: boolean = true
  ): Record<string, any> => {
    const diff: Record<string, any> = {};
    if (!obj1 && !obj2) return diff;
    if (!obj1) return obj2;
    if (!obj2) return obj1;
    Object.keys(obj1).forEach(key => {
      if (onlyCommon) {
        // Only return differences between properties that exists in both objects
        if (obj1[key] && obj2[key] && obj1[key] !== obj2[key]) {
          diff[key] = obj2[key];
        }
      } else {
        if (obj1[key] !== obj2[key]) {
          diff[key] = obj2[key];
        }
      }
    });
    return diff;
  };

  // Getting the previous states
  const prevOpenDateRangeModalRef = useRef(openDateRangeModal);
  const prevReportStatusRef = useRef(reportStatus);
  const prevSelectedReportRef = useRef(selectedReport);

  useEffect(() => {
    const wasOpen = prevOpenDateRangeModalRef.current;
    const prevStatus = prevReportStatusRef.current;

    // Let's eval the difference between the previous (if any) URL params and the current ones
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const newParams = Object.fromEntries(urlParams.entries());
    const diff: any = getObjectDiff(paramList, newParams);

    // If they have changed we will need to refresh the interface
    const mustRefresh = firstLoad || Object.keys(diff).length > 0;

    if (trace) {
      console.warn('-----');
      console.warn('Status:', prevStatus, reportStatus);
      console.warn('First Load:', firstLoad);
      console.warn('Loading:', loading);
      console.warn('Drawing:', redraw);
    }

    // If the modal was open and now is closed, we have made some changes (or not)
    const modalWasOpenAndNowIsClosed = wasOpen && !openDateRangeModal;
    if (modalWasOpenAndNowIsClosed) {
      setLoading(mustRefresh);
    }

    // If we have changes in the report status, we may need to redraw the UI
    const statusHasChanged = prevStatus !== reportStatus;
    if (statusHasChanged) {
      // If we were loading from a browser refresh or a menu change (i.e, cash -> P&L), we must disable here the firstLoad flag
      setFirstLoad(false);
      setLoading(mustRefresh);
      // When the loading process has ended, status goes back to idle
      if (reportStatus === 'idle') {
        // So we update the paramList to keep the changes and switch off the loading state
        setLoading(false);
        setParamList(newParams);
      }
    }

    prevOpenDateRangeModalRef.current = openDateRangeModal;
    prevReportStatusRef.current = reportStatus;
    prevSelectedReportRef.current = selectedReport;

    if (firstLoad) setLoading(true);
    setRedraw(mustRefresh);
  }, [reportStatus, selectedReport, openDateRangeModal, currentReport]);

  const MemoizedNewTable = useMemo(() => {
    if (trace) {
      console.warn('redrawing:', redraw);
      console.warn('loading:', loading);
    }
    return <NewTable report={currentReport} reportId={getSelectedReportId()} loading={loading} redraw={redraw} />;
  }, [loading, redraw, selectedReport]);

  // New component that contains the Table and SearchAndFilters
  const MemoizedDrawReportViewContainer = useMemo(() => {
    if (!selectedReport) {
      console.warn('(Silent Error) Exiting because there is no report data');
      return <></>;
    }

    if (loading && !newTable) {
      return (
        <div className="h-100">
          <Loading />
        </div>
      );
    }

    return (
      <div className="report-view-container">
        <>
          {!newTable && !enableNewReportTable ? (
            <>
              {showCharts && (
                <ReportGraph
                  graphWidth={graphWidth}
                  isYTD={isYtd}
                  selectedReport={selectedReport}
                  reports={filteredReports}
                  extraReportDataSummary={null}
                  timeframe={selectedReport.timeframe}
                  onReportChange={handleReportChange}
                />
              )}
              <Suspense
                fallback={
                  <div className="h-100 center">
                    Loading PL...
                    <Loading />
                  </div>
                }
              >
                <ReportDataTable
                  report={selectedReport}
                  columnHeaderTitle={columnHeaderTitle}
                  customHeaders={customHeaders || []}
                  hasCharts={!!showCharts}
                  selectedReportType={selectedReport.type}
                />
              </Suspense>
            </>
          ) : (
            <>{MemoizedNewTable}</>
          )}
        </>
      </div>
    );
  }, [loading, redraw, showCharts, selectedReport]);

  const showAllButton =
    selectedReport?.type === HoneReportTypes.PLComparison && selectedReport?.timeframe === HoneReportTimeframes.Weekly;

  const canReadBookkeeperControls = abilityCan(
    currentLocationAbilities,
    HoneAbilityActions.read,
    HoneAbilitySubjects.Bookkeeper_Controls
  );

  const Icon: any = selectedReport && selectedReport.type in MENU_ICONS ? MENU_ICONS[selectedReport.type] : null;

  const toggleValidate = () => {
    setShowValidationPane(false);
  };

  return (
    <div className="report-view">
      <KSHeader />
      <div className="report-view--header add-padding">
        <div className="report-view--header__item">
          <span className="header-icon header-icon-bottom ">{Icon && <Icon />}</span>
          {reportType !== 'ap-aging' ? (
            isPLReport &&
            currentLocation && (
              <>
                {availableTemplates && enablePlModalV2 ? (
                  <ReportPLDates
                    currentLocationId={currentLocation.id}
                    templateId={templateId}
                    availableTemplates={availableTemplates}
                    timeframe={timeframe as ReportTimeframe}
                    weekStart={currentLocation.weekStart}
                    reportConfigurationData={(reportConfiguration as ReportConfiguration)?.data}
                  />
                ) : availableTemplates ? (
                  <ReportPLDatesModal />
                ) : null}
                <KSCompareLocationsModal
                  currentLocationUser={currentLocationUser!}
                  allLocations={userLocations as HoneLocationUser[]}
                  handleApplyChanges={() => {
                    setEnableMultiLocation(true);
                  }}
                />
              </>
            )
          ) : (
            <div
              className={classNames('reports-selector-container', {
                hovered: isHovered,
              })}
            >
              <select value={getSelectedReportId()} onChange={e => handleReportChange(e.currentTarget.value)}>
                {filteredReports.length > 0 ? (
                  filteredReports.map(({ id, startDateText, endDateText }) => {
                    return (
                      <option value={id} key={id}>
                        {id === 'custom_range' &&
                          `Custom Range ${dateRange ? '[' + dateRange?.split('&')[0] + ']' : ''} `}
                        {Object.keys(BALANCE_SHEET_PRESETS).includes(id)
                          ? BALANCE_SHEET_PRESETS[id as keyof typeof BALANCE_SHEET_PRESETS].label
                          : !['custom_range'].includes(id) &&
                            `${formatDate(new Date(startDateText + 'T00:00:00'))} - ${formatDate(
                              new Date(endDateText + 'T00:00:00')
                            )}`}
                      </option>
                    );
                  })
                ) : (
                  <option value="loading">Loading...</option>
                )}
              </select>
              {showAllButton && (
                <button
                  disabled={allReportsSelected}
                  className="btn btn-secondary btn-secondary-small"
                  onClick={() => setAllReportsSelected('true')}
                >
                  All
                </button>
              )}
              <ModalCustomDateRange uniqueTitles={uniqueTitles} />
            </div>
          )}
        </div>
        <div className="report-view--header__item">
          <div>
            {currentReport && (
              <STBTools
                reportHeaders={reportDatesHeaders}
                adminMode={isBookkeeper}
                status={
                  ((currentReport?.id && statusUpdates[currentReport.id]) || currentReport?.status) as HoneReportStatus
                }
                onRefresh={(e: SyntheticEvent<HTMLButtonElement, Event>) => handleRefreshReport(e)}
                onDownload={() => {}}
                onValidate={handleValidateReport}
                onAudit={handleAuditReport}
                onDelete={handleDeleteReport}
                report={currentReport}
              />
            )}
          </div>
        </div>
      </div>
      {MemoizedDrawReportViewContainer}
      <SlidingPane
        onRequestClose={toggleValidate}
        title={`Validation results`}
        width={'75%'}
        isOpen={showValidationPane}
      >
        {validateReportError && <span>{validateReportError}</span>}
        {validationReportData && <ValidationReportResults reportData={validationReportData} />}
      </SlidingPane>
    </div>
  );
}

export default ReportView;
