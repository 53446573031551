import { AppLayout } from 'presentation/components';
import React from 'react';
import QueuePage from 'presentation/pages/queue';

function QueueViewFactory() {
  return (
    <AppLayout noFooter={true}>
      <QueuePage />
    </AppLayout>
  );
}

export default QueueViewFactory;
