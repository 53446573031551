import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHoneLocations } from '../../../../components/HoneLocations';
import { useAuthContext } from '../../../../context/useAuthContext';
import { showToast, TOAST_SUCCESS } from '../../../../lib/utils';
import KSDropdown from '../../../components/KSDropdown';
import { Option } from 'types';
import { useLocationsStore } from 'hooks/useLocationsStore';

type Form = {
  name: string;
  org: {
    value: string;
    label: string;
  };
};

function CreateLocation() {
  const { userId } = useAuthContext();
  const { register, handleSubmit, reset, watch } = useForm<Form>({
    defaultValues: {
      name: '',
      org: { value: '', label: '' },
    },
  });

  const { allLocations, fetchAllLocations } = useLocationsStore();

  const { saveLocation } = useHoneLocations();

  const onSubmit = (data: Form) => {
    // @ts-ignore
    saveLocation({ ...data, org: data.org.value, status: 'active' }, userId)
      .then(res => {
        reset({
          name: '',
          org: { value: '', label: '' },
        });
        fetchAllLocations(userId!);
        showToast(`Location ${data.name} created succesfully`, TOAST_SUCCESS);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const handleOrganizationChange = (option: Option) => {
    reset({ ...watch(), org: option });
  };

  const optionsOrganizations = useMemo(() => {
    const uniqueOrgs = new Set();

    return allLocations
      .map(location => ({ value: location.org, label: location.org }))
      .filter(option => {
        if (uniqueOrgs.has(option.value)) {
          return false;
        }
        uniqueOrgs.add(option.value);
        return true;
      });
  }, [allLocations]);

  return (
    <div className="mb-4" id="tab-settings">
      <h4>Create new location</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="BKForm-table">
          <tbody>
            <tr>
              <td>
                <label className="mr-3">Location name:</label>
              </td>
              <td>
                <input type="text" id="name" {...register('name')} placeholder="Location name" className="mr-3" />
              </td>
            </tr>
            <tr>
              <td>
                <label className="mr-3">Organization name:</label>
              </td>
              <td>
                <KSDropdown
                  options={optionsOrganizations as Option[]}
                  value={{
                    value: `${watch('org')?.value}`,
                    label: `${watch('org')?.label}`,
                  }}
                  autocompleteEnabled
                  onSelect={handleOrganizationChange}
                  onInputChange={handleOrganizationChange}
                />
              </td>
            </tr>
            <tr>
              <td />
              <td align="right">
                <button className="button BKForm-btn-save" type="submit">
                  <span>Save</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default CreateLocation;
