import './RIHeader.scss';
import { prefixClass } from 'lib/utils';
import { IconCalendar, IconAttention, IconsInsightsQuestion } from 'components/Icons';
import classNames from 'classnames';
import { Whisper, Tooltip } from 'rsuite';
import { evalDate, getDaysAgo } from '../../utils/dateUtils';
import { RequestStatus } from '../type';

export type requestType = 'request' | 'task' | 'event';

export type RIHeaderProps = {
  userName: string;
  locationName?: string;
  locationId?: string;
  date: string;
  requestType?: requestType;
  isMultiLocation?: boolean;
};

const RIHeader = (props: RIHeaderProps) => {
  const { userName, locationName, locationId, date, requestType, isMultiLocation = false } = props;

  // If userName is not provided, we default it to be a KS accountant
  const displayUserName = userName || 'KS accountant';

  // If locationName is not provided, use a default value. If it is a
  const displayLocationName = isMultiLocation && locationName && locationName !== '' ? locationName : null;

  // Local requestType component
  const RequestType = () => {
    if (!requestType) return null;
    return (
      <div className={classNames(prefix('request-type'), { [`rt-${requestType}`]: true })}>
        <div className={prefix('request-type-icon')}>
          {requestType === 'request' ? (
            <IconsInsightsQuestion />
          ) : requestType === 'task' ? (
            <IconAttention />
          ) : (
            <IconCalendar />
          )}
        </div>
      </div>
    );
  };
  const prefix = prefixClass('ri-header');

  // If the users hovers the date, we display the number of days since the request was created
  return (
    <div data-testid="request-item-header" className={prefix()}>
      <div className={prefix('info')}>
        <div className={prefix('info-user')}>{displayUserName}</div>
        <Whisper delayOpen={600} placement="top" trigger="hover" speaker={<Tooltip>{getDaysAgo(date)}</Tooltip>}>
          <div className={prefix('info-date')}>{evalDate(date)}</div>
        </Whisper>
        {requestType && (
          <div className={prefix('info-request-type')}>
            <RequestType />
          </div>
        )}
      </div>
      {displayLocationName && <div className={prefix('location')}>{locationName}</div>}
    </div>
  );
};

export default RIHeader;
