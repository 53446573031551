import './LabelSwitch.scss';
import { prefixClass } from 'lib/utils';
import classNames from 'classnames';
import LabelSwitchItem from './LabelSwitchItem';
import { useState } from 'react';

type LabelSwitchProps = {
  labels: string[];
  selected: string;
  onSelect: (value: string) => void;
};
const LabelSwitch = ({ labels, selected, onSelect }: LabelSwitchProps) => {
  const prefix = prefixClass('label-switch');

  const [selectedState, setSelectedState] = useState(selected);

  const handleSelect = (value: string) => {
    setSelectedState(value);
    onSelect(value);
  };

  return (
    <div className={prefix()}>
      {labels.map((label, index) => (
        <LabelSwitchItem
          key={index}
          label={label}
          selected={label === selectedState}
          onClick={() => handleSelect(label)}
        />
      ))}
    </div>
  );
};
export default LabelSwitch;
