import { IconCalendar } from 'components/Icons';

import React, { useEffect, useMemo, useState } from 'react';
import KSCheckBox from '../KSCheckBox/KSCheckBox';
import KSListItem from '../KSListItem';

import { Option } from 'types';
import Modal from 'react-modal';
import { useForm, FormProvider } from 'react-hook-form';

import { emptyForFutureDate, reportTimeframeOptions } from './utils';
import {
  ComparisonType,
  GenerateRangesRequest,
  ReportConfigurationData,
  ReportTimeframe,
  ReportTimeframes,
} from '@hone-automation/common';
import KSDropdown from '../KSDropdown';

import { convertTimeframeToReportType, formatShortDate, getTransformTimeFrame } from 'lib/reportUtils';

import './ReportPLDatesModal.scss';
import { generateRanges } from '@hone-automation/common/lib/reportUtil';
import ReportPLYTDCompareSelectors from './ReportPLYTDCompareSelectors';
import ShowBudget from './components/checkboxes/ShowBudget';
import ShowTotal from './components/checkboxes/ShowTotal';
import ShowDifference from './components/checkboxes/ShowDifference';
import ShowBreakdownRange from './components/checkboxes/ShowBreakdownRange';
import ShowReverseColumn from './components/checkboxes/ShowReverseColumn';
import ApplyButton from './components/ApplyButton';
import { parse as qsparse, stringify } from 'querystring';
import { QueryParamsPayload } from '../../../domain/models';
import { FIVE_SECONDS, generateNotAllowedUrlAggregateSideBySide, showToast } from '../../../lib/utils';
import { useReportsStore } from '../../../hooks/useReportsStore';
import { useNavigate } from 'react-router-dom';
import ReportPlSelectors from './components/ReportPLSelectors';
import useReportQueries from '../../../hooks/useReportQueries';

interface ReportPLDatesModalV2Props {
  currentLocationId: string;
  timeframe: ReportTimeframe;
  templateId: string;
  availableTemplates: TemplateRange[];
  isModalOpen: boolean;
  handleModalCloseRequest: () => void;
  reportConfigurationData?: ReportConfigurationData;
  weekStart: WeekDay;
  getRanges: (timeframe: ReportTimeframe, template?: TemplateRange) => Option[];
  initialStartDate: string;
  initialEndDate: string;
}

function ReportPLDatesModalV2({
  currentLocationId,
  timeframe,
  templateId,
  weekStart,
  availableTemplates,
  isModalOpen,
  handleModalCloseRequest,
  reportConfigurationData,
  getRanges,
  initialStartDate,
  initialEndDate,
}: ReportPLDatesModalV2Props) {
  const navigateTo = useNavigate();

  const {
    compareEnabled,
    customDateRange,
    compareYTDEnabled,
    comparisonType,
    comparisonPeriods,
    compareLocations,
    consolidated,
    total,
    budgetInclude,
    difference,
    reverse,
    breakdownPeriods,
    validate,
  } = useReportQueries();

  const isAggrSideBySide =
    compareLocations && (!consolidated || consolidated === 'false') ? compareLocations?.split(',').length > 1 : false;

  const defaultValues = {
    utility: {
      isCompareEnabled: compareEnabled === 'true',
      isCustomDateRange: customDateRange === 'true',
      isCompareYTDEnabled: compareYTDEnabled === 'true',
    },
    difference: difference && !isAggrSideBySide ? difference === 'true' : false,
    startDate: initialStartDate,
    endDate: initialEndDate,
    timeframe: timeframe || 'Week',
    total: total && !isAggrSideBySide ? total === 'true' : false,
    comparison: {
      comparisonType: comparisonType as ComparisonType,
      comparisonPeriods: comparisonPeriods ? parseInt(comparisonPeriods) : 1,
    },
    budgets: {
      include: budgetInclude ? budgetInclude === 'true' : false,
    },
    reverse: reverse && !isAggrSideBySide ? reverse === 'true' : false,

    breakdownPeriods: breakdownPeriods && !isAggrSideBySide ? breakdownPeriods === 'true' : false,
    consolidated: consolidated ? consolidated === 'true' : false,
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, watch, reset } = methods;

  useEffect(() => {
    return () => {
      reset({});
    };
  }, []);
  const onRequestClose = () => {
    reset(defaultValues);
    setLocalTemplateId(templateId);
    handleModalCloseRequest();
  };

  const [localTemplateId, setLocalTemplateId] = useState<string>(templateId);

  // Local Current Template starts with the one in the background but can be changed to any other option
  const selectedTemplate: TemplateRange | undefined = availableTemplates?.find(
    avTemplate => avTemplate.id === localTemplateId
  );

  const genDateRanges = (selectedTimeFrame: ReportTimeframe) => {
    const request = Object.assign(new GenerateRangesRequest(selectedTimeFrame, weekStart, reportConfigurationData), {
      start: selectedTemplate?.range.start,
      end: emptyForFutureDate(selectedTemplate?.range.end),
    });
    return generateRanges(request);
  };

  const filterOptions = (timeframe?: HoneReportTimeframe) => {
    if (timeframe === 'Weekly') {
      return reportTimeframeOptions.filter(ro => ro.value === 'Week');
    } else {
      return reportTimeframeOptions.filter(ro => ro.value !== 'Week');
    }
  };

  const reportTimeframeOptionsFiltered = useMemo(() => {
    const options = filterOptions(selectedTemplate?.timeframe);
    // Change the label of "Month" to "Period" for period reporting locations
    return reportConfigurationData?.periodReporting
      ? options.map(ro => (ro.value === ReportTimeframes.Month ? { value: ro.value, label: 'Period' } : ro))
      : options;
  }, [selectedTemplate?.timeframe]);

  const onSubmit = () => {
    const {
      startDate,
      endDate,
      timeframe,
      utility: { isCompareEnabled, isCompareYTDEnabled },
    } = watch();
    if ((startDate && endDate) || timeframe === 'Year to Date') {
      const currentTemplateId = localTemplateId;
      const currentReport = availableTemplates?.find(avTemplate => {
        return avTemplate.id === localTemplateId;
      });

      const _templateId = currentTemplateId;

      const queryParams = qsparse(location.search.replace('?', ''));
      const newQueries: any = {
        ...queryParams,
        dateRange: `${watch('startDate')},${watch('endDate')}`,
        templateId: _templateId,
        total: String(watch('total')),
        difference: String(watch('difference')),
        timeframe: watch('timeframe'),
        breakdownPeriods: String(watch('breakdownPeriods')),
        reverse: String(watch('reverse')),
        budgetInclude: String(watch('budgets.include')),
        type: currentReport?.type,
      };
      if (!isCustomDateRange && (isCompareEnabled || isCompareYTDEnabled)) {
        newQueries.comparisonType = watch('comparison.comparisonType');
        newQueries.comparisonPeriods = watch('comparison.comparisonPeriods');
        newQueries.compareEnabled = String(watch('utility.isCompareEnabled'));
      } else {
        delete newQueries.comparisonType;
        delete newQueries.comparisonPeriods;
        delete newQueries.compareEnabled;
      }

      //validation enabled delete
      if (validate) {
        delete newQueries.validate;
      }

      // delete utility values
      delete newQueries.utility;

      const newQueriesAggregateSideBySide: QueryParamsPayload | null = generateNotAllowedUrlAggregateSideBySide(
        compareLocations!,
        queryParams,
        consolidated
      );

      if (newQueriesAggregateSideBySide) {
        showToast(
          'Some settings have been turned off to enable side-by-side comparisons. Please re-configure as needed.',
          'warning',
          FIVE_SECONDS
        );
        if (currentLocationId && newQueriesAggregateSideBySide) {
          navigateTo({
            pathname: `/app/location/${currentLocationId}/report/weekly`,
            search: `?${stringify(newQueriesAggregateSideBySide as any)}`,
          });
          useReportsStore.setState({ selectedReport: undefined });
        }
        return;
      }

      const convertedReportType = convertTimeframeToReportType(currentReport?.timeframe);
      if (currentLocationId && newQueries) {
        navigateTo({
          pathname: `/app/location/${currentLocationId}/report/${convertedReportType}`,
          search: `?${stringify(newQueries)}`,
        });
        useReportsStore.setState({ selectedReport: undefined });
      }
      handleModalCloseRequest();
    }
  };
  const selectedTimeframe = watch('timeframe');
  const optionsForSelectedTimeframe = useMemo(
    () => getRanges(selectedTimeframe, selectedTemplate),
    [selectedTimeframe]
  );

  const isCustomDateRange = watch('utility.isCustomDateRange');

  const makeOption = (op: Option, index = 0) => {
    return {
      value: op.value.split(' - ')[index],
      label: formatShortDate(op.value.split(' - ')[index]),
    };
  };

  const startReportOptionsAvailables = useMemo(() => {
    return optionsForSelectedTimeframe?.map(val => makeOption(val));
  }, [selectedTimeframe, isCustomDateRange]);

  const getEndOptions = (
    startDate: string,
    startOptions: { label: undefined | string; value: string }[],
    allOptions: Option[]
  ) => {
    //filter the end date options based on the selected start date
    if (startDate === startOptions[0]?.value.split(' - ')[0]) {
      return new Array(1).fill(
        allOptions.map(option => {
          return makeOption(option, 1);
        })[0]
      );
    }

    return [...allOptions]
      ?.filter(option => {
        return new Date(option.value.split(' - ')[0]) >= new Date(String(watch('startDate')));
      })
      .map(option => {
        return makeOption(option, 1);
      });
  };

  const endReportOptionsAvailables = useMemo(() => {
    return getEndOptions(watch('startDate'), startReportOptionsAvailables, optionsForSelectedTimeframe);
  }, [selectedTimeframe, watch('startDate'), startReportOptionsAvailables, optionsForSelectedTimeframe]);

  const handleClickListItem = (template: TemplateRange) => {
    const clickedTemplateId = template.id;
    if (clickedTemplateId) {
      setLocalTemplateId(clickedTemplateId);
    }

    const timeframe = getTransformTimeFrame(template?.timeframe as HoneReportTimeframe) as ReportTimeframe;

    const nextOptionsAvailable = getRanges(timeframe, template);

    const startOptions = nextOptionsAvailable?.map(makeOption);

    const startDate = startOptions && startOptions.length > 0 ? startOptions[0].value : initialStartDate;
    const endOptions = getEndOptions(startDate, startOptions, nextOptionsAvailable);
    const endDate = endReportOptionsAvailables && endOptions.length > 0 ? endOptions[0].value : initialEndDate;

    reset({
      ...watch(),
      startDate,
      endDate,
      timeframe,
      total: false,
      difference: false,
      breakdownPeriods: false,
      reverse: false,
      budgets: {
        include: false,
      },
      comparison: {
        comparisonType: 'Prior Period',
        comparisonPeriods: 0,
      },
      utility: {
        isCompareEnabled: false,
        isCustomDateRange: false,
      },
    });
  };

  const handleChangeTimeframe = (option: Option) => {
    const newTimeframe = option.value as ReportTimeframe;
    const newDateRanges = newTimeframe !== 'Year to Date' && genDateRanges(option.value as ReportTimeframe);
    const startDate = newDateRanges ? newDateRanges[0].start : undefined;
    const endDate = newDateRanges ? newDateRanges[0].end : undefined;
    reset({
      ...watch(),
      timeframe: option.value as ReportTimeframe,
      utility: {
        isCompareEnabled: false,
      },
      budgets: {
        include: false,
      },
      difference: false,
      comparison: {
        comparisonType: option.value === 'Year' ? 'Year Over Year' : 'Prior Period',
        comparisonPeriods: 0,
      },
      startDate: startDate,
      endDate: endDate,
      total: false,
      reverse: false,
      breakdownPeriods: false,
    });
  };

  const handleToggleCustomDateRange = () => {
    const newCustomDateRange = !watch('utility.isCustomDateRange');
    if (!newCustomDateRange) {
      reset({
        ...watch(),
        utility: {
          ...watch('utility'),
          isCustomDateRange: false,
          isCompareEnabled: true,
        },
        total: false,
        difference: false,
        breakdownPeriods: false,
        reverse: false,
        budgets: {
          include: false,
        },
        comparison: {
          comparisonType: 'Prior Period',
          comparisonPeriods: 1,
        },
      });
    } else {
      reset({
        ...watch(),
        utility: {
          ...watch('utility'),
          isCustomDateRange: true,
          isCompareEnabled: false,
        },
        comparison: {
          comparisonType: 'Prior Period',
          comparisonPeriods: 0,
        },
        difference: false,
        reverse: false,
        budgets: {
          include: false,
        },
      });
    }
  };

  const handleComparisonChange = (comparisonValue: number | undefined) => {
    const nextIsCompareEnabled = !!comparisonValue && Boolean(comparisonValue > 0);
    reset({
      ...watch(),
      utility: {
        ...watch('utility'),
        isCompareEnabled: nextIsCompareEnabled,
      },
      comparison: {
        ...watch('comparison'),
        comparisonPeriods: comparisonValue,
      },
    });
  };

  return (
    <Modal id="report-pl-date-modal-container" isOpen={isModalOpen} onRequestClose={onRequestClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="report-pl-dates-modal">
            <div className="modal-header">
              <h3 className="modal-title">
                <IconCalendar /> Report Configuration V2
              </h3>
              <button type="button" className="close-button" onClick={onRequestClose}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-list">
                {availableTemplates &&
                  availableTemplates.map((avTemplate, idx) => {
                    const isSelected = localTemplateId ? localTemplateId === avTemplate.id : idx === 0;
                    return (
                      <KSListItem
                        key={avTemplate.id}
                        label={avTemplate.title}
                        isSelected={isSelected}
                        onClickListItem={() => {
                          handleClickListItem(avTemplate);
                        }}
                      />
                    );
                  })}
              </div>
              <div className="modal-content">
                <div className="modal-subsection">
                  <div className="modal-subsection-content">
                    <KSDropdown
                      options={reportTimeframeOptionsFiltered as Option[]}
                      value={
                        reportTimeframeOptionsFiltered.find(ro => ro.value === selectedTimeframe) ?? {
                          value: ReportTimeframes.Month,
                          label: ReportTimeframes.Month,
                        }
                      }
                      label="Timeframe"
                      theme="grey"
                      onSelect={handleChangeTimeframe}
                    />
                    {selectedTimeframe !== 'Year to Date' ? (
                      <KSCheckBox
                        checked={isCustomDateRange}
                        onChange={handleToggleCustomDateRange}
                        label="Custom Range Period"
                      />
                    ) : (
                      <ReportPLYTDCompareSelectors
                        reportConfigurationData={reportConfigurationData}
                        handleComparisonChange={handleComparisonChange}
                      />
                    )}
                  </div>
                  <div className="modal-time-frame-content">
                    {selectedTimeframe !== 'Year to Date' && (
                      <ReportPlSelectors
                        timeframe={timeframe}
                        currentTemplateType={selectedTemplate?.type}
                        optionsAvailable={optionsForSelectedTimeframe}
                        startReportOptionsAvailable={startReportOptionsAvailables}
                        endReportOptionsAvailable={endReportOptionsAvailables}
                        handleComparisonChange={handleComparisonChange}
                        reportConfigurationData={reportConfigurationData}
                      />
                    )}
                  </div>
                </div>

                <div className="modal-options-check">
                  <ShowBudget isAggrSideBySide={isAggrSideBySide} />
                  <ShowTotal isAggrSideBySide={isAggrSideBySide} />
                  <ShowDifference isAggrSideBySide={isAggrSideBySide} />
                  <ShowBreakdownRange isAggrSideBySide={isAggrSideBySide} />
                  <ShowReverseColumn isAggrSideBySide={isAggrSideBySide} consolidated={consolidated} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <ApplyButton />
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default ReportPLDatesModalV2;
