import classNames from 'classnames';
import { shortDate } from '../../utils/dateUtils';
import './StatusItem.scss';
import { prefixClass } from 'lib/utils';
import { Tooltip, Whisper } from 'rsuite';
import { RequestStatus } from '../../RequestItem/type';

export type StatusItemProps = {
  userName: string;
  status: RequestStatus;
  showDate?: boolean;
  date?: string;
};

const StatusItem = (props: StatusItemProps) => {
  const { status, date, userName, showDate = false } = props;

  const STATUS_VALUES = {
    created: 'Created',
    inProgress: 'In Progress',
    resolved: 'Resolved',
  };

  const showDateVal = shortDate(date);
  const statusToApply = STATUS_VALUES[status] || null;
  const userNameToApply = userName || 'KS accountant';
  const tooltipTitle = statusToApply + ' by ' + userNameToApply + (showDateVal ? ' on ' + showDateVal : '');
  const prefix = prefixClass('q-status-item');
  if (!statusToApply) return null;

  return (
    <Whisper placement="top" trigger="hover" speaker={<Tooltip>{tooltipTitle}</Tooltip>}>
      <div data-testid="status" className={classNames(prefix(), { [`status_${status.toLowerCase()}`]: true })}>
        <div data-testid="status-container" className={prefix('status')}>
          {statusToApply}
        </div>
        {showDate && (
          <div data-testid="date-container" className={prefix('date')}>
            {showDateVal}
          </div>
        )}
      </div>
    </Whisper>
  );
};

export default StatusItem;
