import { useMemo, useState } from 'react';
import ReportPlDatesButton from './ReportPLDatesButton';
import { GenerateRangesRequest, ReportConfigurationData, ReportTimeframe } from '@hone-automation/common';
import { StartEndDates } from '@hone-automation/common/lib/Reports';
import { generateRanges } from '@hone-automation/common/lib/reportUtil';
import { formatRangeValues, getInitialReportDateRanges } from '../../../lib/reportUtils';
import { getCustomPeriodLabel } from '../../../lib/utils';
import { Option } from '../../../types';
import ReportPLDatesModalV2 from './ReportPLDatesModalV2';
import { useQueryState } from '../../../hooks/useQueryState';
import { emptyForFutureDate } from './utils';

interface ReportPLDatesProps {
  currentLocationId: string;
  templateId: string;
  availableTemplates: TemplateRange[];
  timeframe: ReportTimeframe;
  weekStart: WeekDay;
  reportConfigurationData?: ReportConfigurationData;
}

interface RangesForSelectedTimeframe {
  dropdownOptions: Option[];
  dateRanges: StartEndDates[];
}

function ReportPlDates({
  currentLocationId,
  templateId,
  availableTemplates,
  timeframe,
  weekStart,
  reportConfigurationData,
}: ReportPLDatesProps) {
  const [comparisonPeriods] = useQueryState<string>('comparisonPeriods');
  const [dateRange] = useQueryState<string>('dateRange');

  const currentTemplate: TemplateRange | undefined = availableTemplates.find(
    avTemplate => avTemplate.id === templateId
  );

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setModalIsOpen(prevState => !prevState);
  };

  function getRangesForReportTimeframe(
    timeframe: ReportTimeframe,
    weekStart: WeekDay,
    reportConfigurationData?: ReportConfigurationData,
    template?: TemplateRange
  ): RangesForSelectedTimeframe {
    if (!template || timeframe === 'Year to Date') return { dropdownOptions: [], dateRanges: [] };
    const genDateRanges = (selectedTimeFrame: ReportTimeframe) => {
      const request = Object.assign(new GenerateRangesRequest(selectedTimeFrame, weekStart, reportConfigurationData), {
        start: template?.range.start,
        end: emptyForFutureDate(template?.range.end),
      });
      return generateRanges(request);
    };

    const newDateRanges: StartEndDates[] = genDateRanges(timeframe);

    return {
      dropdownOptions: newDateRanges.map(dateRange => ({
        value: `${dateRange.start} - ${dateRange.end}`,
        customLabel: `${dateRange.name}`,
        label: formatRangeValues(`${dateRange.start} - ${dateRange.end}`, timeframe) ?? '',
      })),
      dateRanges: newDateRanges,
    };
  }

  const rangesForReportTimeframe = useMemo(
    () => getRangesForReportTimeframe(timeframe, weekStart, reportConfigurationData, currentTemplate),
    [timeframe, weekStart, reportConfigurationData]
  );

  const initialStartDate = useMemo(() => {
    if (comparisonPeriods && !dateRange) {
      const initialReportDateRange = getInitialReportDateRanges(rangesForReportTimeframe.dateRanges);

      return initialReportDateRange?.start;
    }
    return dateRange ? dateRange.split(',')[0] : rangesForReportTimeframe.dropdownOptions[0]?.value.split(' - ')[0];
  }, [timeframe, dateRange]);

  const initialEndDate = useMemo(() => {
    if (comparisonPeriods && !dateRange) {
      const initialReportDateRange = getInitialReportDateRanges(rangesForReportTimeframe.dateRanges);

      return initialReportDateRange?.end;
    }
    return dateRange ? dateRange.split(',')[1] : rangesForReportTimeframe.dropdownOptions[0]?.value.split(' - ')[0];
  }, [timeframe, dateRange]);

  const dateRangeTitle = useMemo(() => {
    if (timeframe === 'Year to Date') return 'Year to Date';

    return (
      <div className="report-dates-control-range-dates">
        <span className="report-dates-control-range-dates-units">
          {getCustomPeriodLabel(rangesForReportTimeframe.dropdownOptions, `${initialStartDate} - ${initialEndDate}`)}
        </span>
        <span>{`${formatRangeValues(`${initialStartDate} - ${initialEndDate}`, timeframe)}`}</span>
      </div>
    );
  }, [timeframe, initialStartDate, initialEndDate, rangesForReportTimeframe]);

  const selectedTemplate = availableTemplates?.find(avTemplate => avTemplate.id === templateId);

  const periodTitle = useMemo(() => {
    if (!selectedTemplate) return availableTemplates?.[0]?.title;

    return `${selectedTemplate?.title}`;
  }, [selectedTemplate]);

  return (
    <>
      <ReportPlDatesButton toggleModal={toggleModal} dateRangeTitle={dateRangeTitle} periodTitle={periodTitle} />
      {modalIsOpen && (
        <ReportPLDatesModalV2
          key={`${currentLocationId}-${templateId}-${timeframe}-modal`}
          currentLocationId={currentLocationId}
          getRanges={(timeframe, template) =>
            timeframe !== 'Year to Date'
              ? getRangesForReportTimeframe(timeframe, weekStart, reportConfigurationData, template).dropdownOptions
              : []
          }
          timeframe={timeframe}
          templateId={templateId}
          weekStart={weekStart}
          reportConfigurationData={reportConfigurationData}
          availableTemplates={availableTemplates}
          isModalOpen={true}
          handleModalCloseRequest={toggleModal}
          initialStartDate={initialStartDate!}
          initialEndDate={initialEndDate!}
        />
      )}
    </>
  );
}

export default ReportPlDates;
