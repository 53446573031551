import React, { useMemo } from 'react';
import KSButton from '../KSButton';
import { filterCompareOptions, reportTimeframeOptionsComparePrior } from './utils';
import KSDropdown from '../KSDropdown/KSDropdown';
import {
  ComparisonType,
  ComparisonTypes,
  ReportConfigurationData,
  ReportTimeframe,
  ReportTimeframes,
} from '@hone-automation/common';
import { useFormContext } from 'react-hook-form';
import KSInputNumberAutoincrease from '../KSInputNumberAutoincrease';

interface ReportPLYTDCompareSelectorsProps {
  handleComparisonChange: (comparisonValue: number | undefined) => void;
  reportConfigurationData?: ReportConfigurationData;
}

function ReportPLYTDCompareSelectors(props: ReportPLYTDCompareSelectorsProps) {
  const { reset, watch } = useFormContext();

  const isCompareYTDEnabled = watch('utility.isCompareYTDEnabled');

  const reportTimeframeOptionsComparePriorFiltered = useMemo(() => {
    const localTimeframe = watch('timeframe') as ReportTimeframe;
    const options = filterCompareOptions(localTimeframe);
    // Change the label of "Months" to "Periods" for period reporting locations
    return options && props.reportConfigurationData?.periodReporting
      ? options.map(ro => (ro.label === 'Months' ? { value: ro.value, label: 'Periods' } : ro))
      : options;
  }, [watch('timeframe')]);

  const reportTimeframeCompareValueSelected = useMemo(() => {
    const found = reportTimeframeOptionsComparePriorFiltered?.find(
      rp => rp.value === watch('comparison.comparisonType')
    );
    if (found) {
      return found;
    }
    const value = watch('comparison.comparisonType');
    const isPriorPeriod = value === ComparisonTypes.Prior_Period;
    const label = isPriorPeriod ? `${watch('timeframe')}s` : `${ReportTimeframes.Year}s`;
    return { value, label };
  }, [watch('comparison.comparisonType'), watch('timeframe')]);

  return (
    <>
      <div className="modal-time-frame-content-compare-btn">
        {!isCompareYTDEnabled && (
          <KSButton
            label="Compare"
            color="secondary"
            size="medium"
            variant="contained"
            type="button"
            onClick={() => {
              reset({
                ...watch(),
                utility: {
                  isCompareYTDEnabled: true,
                },
                breakdownPeriods: false,
                isCompareYTDEnabled: true,
                comparison: {
                  comparisonType: 'Year Over Year',
                  comparisonPeriods: 1,
                },
              });
            }}
          />
        )}
      </div>
      {isCompareYTDEnabled && (
        <div className="modal-time-frame-content__compare-selector">
          <KSInputNumberAutoincrease
            onChangeInput={() => {
              // TODO implement on change input
            }}
            value={watch('comparison.comparisonPeriods')}
            onDecrease={() => {
              const comparisonPeriods = watch('comparison.comparisonPeriods');
              props.handleComparisonChange(comparisonPeriods! - 1);
            }}
            onIncrease={() => {
              const comparisonPeriods = watch('comparison.comparisonPeriods') || 1;
              props.handleComparisonChange(comparisonPeriods + 1);
            }}
            disabledDecrease={watch('comparison.comparisonPeriods') === 0}
            disabledIncrease={watch('comparison.comparisonPeriods') === 13}
            label="Compare to Prior"
          />
          {reportTimeframeOptionsComparePriorFiltered ? (
            <div className="modal-time-frame-content__compare-dropdown">
              {reportTimeframeOptionsComparePrior.find(rp => rp.value === watch('comparison.comparisonType'))?.label}
              <KSDropdown
                options={reportTimeframeOptionsComparePriorFiltered}
                value={reportTimeframeCompareValueSelected!}
                onSelect={option => {
                  reset({
                    ...watch(),
                    comparison: {
                      ...watch('comparison'),
                      comparisonType: option.value as ComparisonType,
                    },
                  });
                }}
                placeholder="Monthly"
                theme="grey"
              />
            </div>
          ) : (
            <span className="ks-input-number-unit">YEARS</span>
          )}
        </div>
      )}
    </>
  );
}

export default ReportPLYTDCompareSelectors;
