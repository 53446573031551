import { AskRequest } from '@hone-automation/common';
import { RequestItemProps, UserInfo, LastComment, RequestStatus } from '../type';
import { RIInfoProps } from '../RIInfo';
import { maxAllowedColors } from '../../common/AvatarContainer';

const mapAskRequestToRequestStatus = (status: string, comments?: any[]): RequestStatus => {
  switch (status) {
    case 'Open':
      // If there is more than one comment, there has been communications, so  let's assume the ticket is in Progress
      return comments && comments.length > 1 ? 'inProgress' : 'created';
    case 'In Progress':
      return 'inProgress';
    case 'Closed':
      return 'resolved';
    default:
      return 'created';
  }
};

const returnNoCommentsRequest = (data: AskRequest): RequestItemProps => {
  const { id, createdAt, updatedAt } = data;
  return {
    id: id,
    header: {
      userName: 'Unknown Account',
      date: String(createdAt),
      requestType: 'request',
    },
    location: {
      id: data.locationId || '',
      name: data.locationId ? 'Unresolved Location' : 'Unknown Location',
    },
    lastComment: null,
    userInfo: {
      userId: '4emD3RxpsUcnDgndL2vMbHryHJU2',
      userName: 'System',
      title: 'System',
      colorId: '#f4000033',
      locationId: data.locationId || '',
      locationName: data.locationId ? 'Unresolved Location' : 'Unknown Location',
      isHoneInternalUser: true,
      email: 'admin@hone.rest',
    },
    info: data.identifier
      ? {
          description: data.identifier,
          amount: data.flaggedAmount,
          status: { userName: 'System', status: mapAskRequestToRequestStatus(status), date: String(updatedAt) },
        }
      : null,
    warnning: true,
  };
};

export const parseAR = (data: AskRequest): RequestItemProps => {
  if (!data || typeof data.id === 'undefined') {
    throw new Error('Invalid AskRequest data');
  }

  const { id, locationId, transaction, comments, createdAt, flaggedAmount, identifier, status, updatedAt } = data;
  // Just in case we need it, Flaggedtransaction don't have locationId or identifier
  const FlaggedTransaction: boolean = !locationId && !identifier;

  // If there is no comments, we need to provide a default value
  if (!comments || comments?.length === 0) {
    return returnNoCommentsRequest(data);
  }

  // Location by default should be the location of the first comment (We may need to revise this assumption later)
  const firstInteraction = comments[0];

  // Get the last comment because it is the source of the information and the asscoiated user
  const lastInteraction = comments[comments.length - 1];
  const user = lastInteraction.userLocation;

  // Get the location info from the first comment.
  // Location name currently is only available in the first comment (We may need to revise this assumption later)
  // We might try to read it from the locations API
  const locationInfo = {
    id: firstInteraction.locationId || user.locationId,
    name: user.locationName || 'Unknown Location',
  };

  const isKSAccountant = user.role.toLowerCase().indexOf('bookkeeper') > -1;

  const userInfo: UserInfo = {
    userId: user.userId,
    // We still don't get userName from the backend. This is a temporary fix.
    // @ts-ignore
    userName: isKSAccountant ? 'KS Accountant' : user.userName || user.email || 'Unknown User',
    title: isKSAccountant ? 'Accountant' : user.role,
    colorId: '0', //String(Math.floor(Math.random() * maxAllowedColors)), ----> This is a temporary fix until we provide a color asigment to each user
    locationId: locationInfo.id,
    locationName: user.locationName,
    isHoneInternalUser: user.isHoneInternalUser,
    email: user.email,
  };

  // If there is not a tranasction then it is a flagged cell, so we fill the info with the identifier
  const trxInfo: RIInfoProps = {
    description: identifier || '',
    amount: flaggedAmount || 0,
    status: {
      userName: userInfo.userName,
      status: mapAskRequestToRequestStatus(status),
      date: String(lastInteraction.createdAt),
    },
  };

  // If there is a transaction then we fill the info with the transaction
  if (transaction) {
    trxInfo.description = transaction?.memo || '';
    trxInfo.amount = transaction?.amount || 0;
    trxInfo.vendorName = transaction?.account || '';
    trxInfo.vendorId = transaction?.externalAccountId || '';
  }

  // We get the parameters that we need from the last comment
  const lastComment = lastInteraction as LastComment;

  return {
    id: id,
    header: {
      userName: userInfo.userName,
      date: String(lastInteraction.createdAt),
      requestType: 'request',
    },
    location: locationInfo,
    lastComment: lastComment,
    userInfo: userInfo,
    info: trxInfo,
  };
};
