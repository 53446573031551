import React from 'react';
import { useQueryState } from './useQueryState';

function useReportQueries() {
  const [compareEnabled, setCompareEnabled] = useQueryState<string>('compareEnabled');
  const [customDateRange] = useQueryState<string>('customDateRange');
  const [compareYTDEnabled] = useQueryState<string>('compareYTDEnabled');
  const [comparisonType] = useQueryState<string>('comparisonType');
  const [comparisonPeriods] = useQueryState<string>('comparisonPeriods');
  const [compareLocations] = useQueryState<string>('compareLocations');
  const [consolidated] = useQueryState<string>('consolidated');
  const [total] = useQueryState<string>('total');
  const [budgetInclude] = useQueryState<string>('budgetInclude');
  const [difference] = useQueryState<string>('difference');
  const [reverse] = useQueryState<string>('reverse');
  const [breakdownPeriods] = useQueryState<string>('breakdownPeriods');
  const [validate] = useQueryState<string>('validate');

  return {
    compareEnabled,
    customDateRange,
    compareYTDEnabled,
    comparisonType,
    comparisonPeriods,
    compareLocations,
    consolidated,
    total,
    budgetInclude,
    difference,
    reverse,
    breakdownPeriods,
    validate,
  };
}

export default useReportQueries;
